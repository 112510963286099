import {useState} from "react";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import {Drawer, Grid, List, ListItem, ListItemText, ThemeProvider} from "@mui/material";
import {StyledNavLink} from "./StyledNavLink";
import * as React from "react";
import {useLocation} from "react-router-dom";
import {useTheme} from "@mui/system";
import {urlLevain, urlWeightDough, urlRatioDough} from "../../App";
import NumbersIcon from "@mui/icons-material/Numbers";
import PercentIcon from "@mui/icons-material/Percent";
import SaveIcon from "@mui/icons-material/Save";
import {useAuth} from "react-oidc-context";
import {LoggedInUserIcon} from "../icon/LoggedInUserIcon";
import {GuestUserIcon} from "../icon/GuestUserIcon";
import CalculateIcon from "@mui/icons-material/Calculate";


function DrawerMenu(props) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const currentLocation = useLocation()
  const theme = useTheme();
  const auth = useAuth();

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Grid container direction={"row"} alignItems={"center"} justifyContent={"space-between"} columns={{xs: 4, sm: 4, md: 6}} >
          <Grid item>
            <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
              <MenuIcon fontSize={'large'}/>
            </IconButton>
          </Grid>
          <Grid item paddingLeft={1}>
            {auth.isAuthenticated ? (<LoggedInUserIcon/>) : (<GuestUserIcon/>)}
          </Grid>
        </Grid>

        <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
            <List>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <StyledNavLink className={`${currentLocation.pathname === '/' ? 'active' : undefined}`} to={'/'}>Home</StyledNavLink>
                </ListItemText>
              </ListItem>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <StyledNavLink className={`${currentLocation.pathname === '/help' ? 'active' : undefined}`} to={'/help'}>Help</StyledNavLink>
                </ListItemText>
              </ListItem>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <StyledNavLink className={`${currentLocation.pathname.startsWith(urlRatioDough) ? 'active' : undefined}`} to={`${urlRatioDough}`}><PercentIcon/> Bakers</StyledNavLink>
                </ListItemText>
              </ListItem>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <StyledNavLink className={`${currentLocation.pathname.startsWith(urlWeightDough) ? 'active' : undefined}`} to={`${urlWeightDough}`}><NumbersIcon/> Weight</StyledNavLink>
                </ListItemText>
              </ListItem>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <StyledNavLink className={`${currentLocation.pathname.startsWith(urlLevain) ? 'active' : undefined}`} to={`${urlLevain}`}><CalculateIcon/> Levain</StyledNavLink>
                </ListItemText>
              </ListItem>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <StyledNavLink className={`${currentLocation.pathname === '/saved' ? 'active' : undefined}`} to={'/saved'}><SaveIcon/> Saved</StyledNavLink>
                </ListItemText>
              </ListItem>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <StyledNavLink className={`${currentLocation.pathname === '/user' ? 'active' : undefined}`} to={'/user'}>
                    {auth.isAuthenticated ? (<LoggedInUserIcon/>) : (<GuestUserIcon/>)}
                  </StyledNavLink>
                </ListItemText>
              </ListItem>
            </List>
        </Drawer>
      </ThemeProvider>
    </div>
  )
}

export {DrawerMenu}