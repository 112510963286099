import {Grid} from "@mui/material";
import {useRef} from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PrintIcon from "@mui/icons-material/Print";
import * as React from "react";
import {PRINT_LEVAIN_SESS_KEY, sessionPut} from "../utils/StorageUtils";
import {LevainRecipe} from "./LevainRecipe";
import {PRINT_LEVAIN_URI} from "../utils/RoutingUtils";

function LevainRecipeWrapper(props) {
  const {items, visible, closeRequest} = props
  const printComponentRef = useRef(null)

  if (!visible) {
    return null
  }

  function onPrintClick() {
    // Due to mobile browser print iframe issue, https://github.com/gregnb/react-to-print/issues/187 ...
    // The approach to address that is to redirect to an alternate, recipe-only page in a new tab
    // - This gets us a relatively clean print without the surrounding UI
    // - This depends on the Router bypass hack in App.js

    sessionPut(PRINT_LEVAIN_SESS_KEY, items)
    window.open(PRINT_LEVAIN_URI)
  }

  // re-constitute this if we want to have desktop browser use in-line printing without opening a new tab
  // function renderPrintButton() {
  //   if (isMobile) {
  //     return (
  //       <IconButton onClick={onPrintClick} sx={{float: "right"}} color="primary" aria-label="Print"><PrintIcon fontSize={'large'} /></IconButton>
  //     )
  //   } else {
  //     return (
  //       <ReactToPrint trigger={() => <IconButton sx={{float: "right"}} color="primary" aria-label="Print"><PrintIcon fontSize={'large'} /></IconButton>}
  //                     content={() => printComponentRef.current}/>
  //     )
  //   }
  // }

  return (
    <div>
      <Grid item container direction={'row'} justifyContent={'flex-end'}>
        <Grid item>
          <IconButton sx={{float: "right"}} onClick={closeRequest} color="primary" aria-label="Close">
            <CloseIcon fontSize={'large'} />
          </IconButton>
          <IconButton onClick={onPrintClick} sx={{float: "right"}} color="primary" aria-label="Print"><PrintIcon fontSize={'large'} /></IconButton>
        </Grid>
      </Grid>
      <Grid item container direction={'column'}>
        <LevainRecipe items={items} variant={'elevation'} elevation={0} ref={printComponentRef}/>
      </Grid>
    </div>
  )
}


export { LevainRecipeWrapper }