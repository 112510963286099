
export const processPrep = 'pr';
export const processAutolyse = 'al';
export const processFinalMix = 'mx'; //notes only
export const processBulk1 = 'b1';
export const processBulk2 = 'b2';
export const processBulk3 = 'b3';
export const processShape = 'sh'; //notes only
export const processProof1 = 'p1';
export const processProof2 = 'p2';
export const processProof3 = 'p3';
export const processBake = 'bk'; //notes only

export const processBulkSnWarm = 'Warm'
export const processBulkSnRetard = 'Retard'
export const processBulkSnCold = 'Cold'
export const processProofSnWarm = 'Warm'
export const processProofSnRetard = 'Retard'
export const processProofSnCold = 'Cold'

export function getDefaultSn(processName) {
  switch (processName) {
    case processBulk1:
      return processBulkSnWarm;
    case processBulk2:
      return processBulkSnRetard;
    case processBulk3:
      return processBulkSnCold;
    case processProof1:
      return processProofSnCold
    case processProof2:
      return processProofSnRetard;
    case processProof3:
      return processProofSnWarm;
    default:
      return undefined;
  }
}

export const processBulkNameOptions = [processBulkSnWarm, processBulkSnRetard, processBulkSnCold];
export const processProofNameOptions = [processProofSnCold, processProofSnRetard, processProofSnWarm];

export class DoughProcess {
  constructor(subName, time, temp, notes) {
    this.sn = subName;
    this.tm = time;
    this.tp = temp;
    this.nt = notes;
  }
}
