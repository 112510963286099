
const flourDictionary = {
  'All Purpose': 'All Purpose',
  'AP': 'All Purpose',
  'Bread Flour': 'Bread Flour',
  'Bread': 'Bread Flour',
  'BF': 'Bread Flour',
  'Whole Wheat': 'Whole Wheat',
  'WW': 'Whole Wheat',
  'Wh Wh': 'Whole Wheat',
}

export function getNormalizedFlourName(name) {
  if (flourDictionary[name]) {
    return flourDictionary[name]
  }
  return name
}