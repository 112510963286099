export function arrayEquals(a, b) {
  return Array.isArray(a) &&
          Array.isArray(b) &&
          a.length === b.length &&
          a.every((val, index) => val === b[index]);
}

export function deepCopy(arrSrc) {
  const arrDest = arrSrc.slice()
  for(let ix = 0; ix < arrDest.length; ix++) {
    arrDest[ix] = {...arrSrc[ix]}
  }
  return arrDest
}

export function deepCopyExcludingField(arrSrc, fieldName) {
  const arrDest = arrSrc.slice()
  for(let ix = 0; ix < arrDest.length; ix++) {
    arrDest[ix] = {...arrSrc[ix]}
    delete arrDest[ix][fieldName]
  }
  return arrDest
}

export function deepCopySyncDecimalStringField(arrSrc, fieldName) {
  const arrDest = arrSrc.slice()
  for(let ix = 0; ix < arrDest.length; ix++) {
    arrDest[ix] = {...arrSrc[ix]}
    arrDest[ix][`${fieldName}Str`] = `${arrDest[ix][fieldName]}`
  }
  return arrDest
}

export function getFieldValuesAsArray(arrSrc, fieldName) {
  return arrSrc.map((o, ix) => {
    return  arrSrc[ix][fieldName]
  })
}

export function setArrayFieldValues(arrSrc, fieldName, value) {
  if (arrSrc === undefined) {
    return
  }
  arrSrc.forEach(function (item) {
    item[fieldName] = value
  })
}

export function getSumForField(arrSrc, fieldName) {
  return arrSrc.reduce((acc, obj) => {return acc + obj[fieldName]}, 0)
}

export function findIndexForFieldValue(arrSrc, key, value) {
  return arrSrc.findIndex((obj) => obj[key] === value)
}

export function findObjectForFieldValue(arrSrc, key, value) {
  return arrSrc.find((obj) => obj[key] === value)
}

export function arrayHasIntersectionIgnoreCase(arrSrcLowerCase, arrCandidate) {
  if (arrSrcLowerCase?.length > 0 && !arrCandidate?.length) {
    return false;
  }
  if (arrSrcLowerCase?.length > 0) {
    for(const item of arrCandidate) {
      if (arrSrcLowerCase.includes(item.toLowerCase())) {
        return true;
      }
    }
  }
  return false;
}
