import * as React from 'react';
import {
  Accordion, AccordionDetails, AccordionSummary,
  FormControlLabel,
  Grid, MenuItem,
  Switch, TextField,
  Typography
} from "@mui/material";
import {useState} from "react";
import {ContextualHelpDialog} from "../help/ContextualHelpDialog";
import {FormTextField} from "./FormTextField";
import {
  DoughProcess,
  getDefaultSn,
  processAutolyse,
  processBake,
  processBulk1,
  processBulk2,
  processBulkNameOptions,
  processFinalMix,
  processPrep,
  processProof1,
  processProof2,
  processProofNameOptions,
  processShape,
} from "./DoughProcess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function ProcessForm(props) {
  const {formData, setFormData} = props
  const [openContextualHelpDialog, setOpenContextualHelpDialog] = useState(false)
  const [contextualHelpContent, setContextualHelpContent] = useState({title: undefined, content: undefined})

  function onCloseHelp() {
    setOpenContextualHelpDialog(false)
    setContextualHelpContent({title: undefined, content: undefined})
  }

  function onGeneralFieldChange(evt) {
    setFormData({
      ...formData,
      [evt.target.name]: evt.target.value
    })
  }

  function onProcessValueChange(evt, process, subfield) {
    const newVal = evt.target.value
    const prc = process
    const sub = subfield

    const newProcess = {...formData.process}
    newProcess[prc][sub] = newVal
    setFormData({...formData, process: newProcess})
  }

  function onProcessSwitchChange(evt) {
    const processName = evt.target.name;
    const newProcess = {...formData.process}
    if (evt.target.checked) {
      newProcess[processName] = new DoughProcess(getDefaultSn(processName), undefined, undefined, undefined)
    } else {
      delete newProcess[processName]
    }
    setFormData({...formData, process: newProcess})
  }

  function renderPrep() {
    return (
            <Grid item container direction={'column'} alignItems={'flex-start'} marginBottom={1}>
              <Grid item paddingBottom={1.5}>
                <FormControlLabel control={<Switch name={processPrep} checked={Object.hasOwn(formData.process, processPrep)}
                                                   onChange={onProcessSwitchChange}
                                                   disabled={false}/>}
                                  label={<Typography variant={'subtitle2'}>Prep Notes</Typography>}/>
              </Grid>
              <FormTextField multiline rows={5}
                             id={'prep notes'}
                             size={'small'} fullWidth
                             variant={'outlined'}
                             placeholder={'Prep notes'}
                             name={`${processPrep}.nt`}
                             disabled={!formData.process[processPrep]}
                             value={formData.process[processPrep]?.nt || ''}
                             onChange={(evt) => onProcessValueChange(evt, processPrep, 'nt')}/>
            </Grid>
    )
  }
  
  function renderAutolyse() {
    return (
        <Grid item container direction={'column'} alignItems={'flex-start'} marginBottom={1}>
          <Grid item paddingBottom={1.5}>
              <FormControlLabel control={<Switch name={processAutolyse} checked={Object.hasOwn(formData.process, processAutolyse)}
                                                 onChange={onProcessSwitchChange}
                                                 disabled={false}/>}
                                label={<Typography variant={'subtitle2'}>Autolyse</Typography>}/>
              <FormTextField size={'small'}
                             variant={'outlined'}
                             sx={{fontSize: 12, width: '35%'}}
                             id="autolyse time"
                             placeholder={'Time'}
                             name={`${processAutolyse}.tm`}
                             value={formData.process[processAutolyse]?.tm || ''}
                             disabled={!formData.process[processAutolyse]}
                             onChange={(evt) => onProcessValueChange(evt, processAutolyse, 'tm')}/>
          </Grid>
          <FormTextField  multiline rows={5}
                     size={'small'}
                     id={'autolyse mixing notes'} fullWidth
                     placeholder={'Autolyse/Mix notes'}
                     variant={'outlined'}
                     disabled={!formData.process[processAutolyse]}
                     name={`${processAutolyse}.nt`}
                     value={formData.process[processAutolyse]?.nt || ''}
                     onChange={(evt) => onProcessValueChange(evt, processAutolyse, 'nt')}/>
        </Grid>
      )
  }

  function renderMixing() {
    return (
            <Grid item container direction={'column'} alignItems={'flex-start'} marginBottom={1}>
              <Grid item paddingBottom={1.5}>
                <FormControlLabel control={<Switch name={processFinalMix} checked={Object.hasOwn(formData.process, processFinalMix)}
                                                   onChange={onProcessSwitchChange}
                                                   disabled={false}/>}
                                  label={<Typography variant={'subtitle2'}>Final Mix</Typography>}/>
                <FormTextField size={'small'}
                               variant={'outlined'}
                               sx={{fontSize: 12, width: '35%'}}
                               id="bulk2 time"
                               placeholder={'Total Time'}
                               name={`${processFinalMix}.tm`}
                               value={formData.process[processFinalMix]?.tm || ''}
                               disabled={!formData.process[processFinalMix]}
                               onChange={(evt) => onProcessValueChange(evt, processFinalMix, 'tm')}/>
              </Grid>
              <FormTextField multiline rows={5}
                         id={'final mix'}
                         size={'small'} fullWidth
                         variant={'outlined'}
                         placeholder={'Final Mix notes'}
                         name={`${processFinalMix}.nt`}
                         disabled={!formData.process[processFinalMix]}
                         value={formData.process[processFinalMix]?.nt || ''}
                         onChange={(evt) => onProcessValueChange(evt, processFinalMix, 'nt')}/>
            </Grid>
    )
  }

  function renderBulkFerm() {
    return (
            <div>
            <Grid item container direction={'row'} alignItems={'flex-start'} marginBottom={1}>
              <Grid item paddingBottom={1.5}>
                <FormControlLabel control={<Switch name={processBulk1} checked={Object.hasOwn(formData.process, processBulk1)}
                                                   onChange={onProcessSwitchChange}
                                                   disabled={false}/>}
                                  label={<Typography variant={'subtitle2'}>Phase 1</Typography>}/>
              </Grid>
              <Grid item paddingBottom={1.5}>
                <FormTextField select size={'small'}  sx={{width: '10ch'}}
                               variant={'outlined'}
                               id={"bulk1 sn"}
                               label={"name"}
                               disabled={!formData.process[processBulk1]}
                               name={`${processBulk1}.sn`}
                               value={formData.process[processBulk1]?.sn || getDefaultSn(processBulk1)}
                               onChange={(evt) => onProcessValueChange(evt, processBulk1, 'sn')}>
                  {
                    processBulkNameOptions.map((v) => {
                      return <MenuItem key={v} value={v}>{v}</MenuItem>
                    })
                  }
                </FormTextField>
                <FormTextField size={'small'}
                               variant={'outlined'}
                               sx={{fontSize: 12, marginLeft: 1, width: '33%'}}
                               id="bulk1 time"
                               placeholder={'Time'}
                               name={`${processBulk1}.tm`}
                               value={formData.process[processBulk1]?.tm || ''}
                               disabled={!formData.process[processBulk1]}
                               onChange={(evt) => onProcessValueChange(evt, processBulk1, 'tm')}/>
                <FormTextField size={'small'}
                               variant={'outlined'}
                               sx={{fontSize: 12, width: '24%', marginLeft: 1}}
                               id="bulk1 temp"
                               placeholder={'Temp'}
                               name={`${processBulk1}.tp`}
                               value={formData.process[processBulk1]?.tp || ''}
                               disabled={!formData.process[processBulk1]}
                               onChange={(evt) => onProcessValueChange(evt, processBulk1, 'tp')}/>
              </Grid>
            </Grid>
            <Grid item container direction={'row'} alignItems={'flex-start'} marginBottom={1}>
              <FormTextField  sx={{marginBottom: 1.5}} multiline rows={5}
                              size={'small'} fullWidth
                              id={'bulk1 folding'}
                              placeholder={'Folding notes'}
                              variant={'outlined'}
                              disabled={!formData.process[processBulk1]}
                              name={`${processBulk1}.nt`}
                              value={formData.process[processBulk1]?.nt || ''}
                              onChange={(evt) => onProcessValueChange(evt, processBulk1, 'nt')}/>
            </Grid>

            <Grid item container direction={'row'} alignItems={'flex-start'} marginBottom={1}>
              <Grid item paddingBottom={1.5}>
                <FormControlLabel control={<Switch name={processBulk2} checked={Object.hasOwn(formData.process, processBulk2)}
                                                   onChange={onProcessSwitchChange}
                                                   disabled={false}/>}
                                  label={<Typography variant={'subtitle2'}>Phase 2</Typography>}/>
              </Grid>
              <Grid item paddingBottom={1.5}>
                <FormTextField select size={'small'}  sx={{width: '10ch'}}
                               variant={'outlined'}
                               id={"bulk2 sn"}
                               label={"name"}
                               disabled={!formData.process[processBulk2]}
                               name={`${processBulk2}.sn`}
                               value={formData.process[processBulk2]?.sn || getDefaultSn(processBulk2)}
                               onChange={(evt) => onProcessValueChange(evt, processBulk2, 'sn')}>
                  {
                    processBulkNameOptions.map((v) => {
                      return <MenuItem key={v} value={v}>{v}</MenuItem>
                    })
                  }
                </FormTextField>
                <FormTextField size={'small'}
                               variant={'outlined'}
                               sx={{fontSize: 12, marginLeft: 1, width: '33%'}}
                               id="bulk2 time"
                               placeholder={'Time'}
                               name={`${processBulk2}.tm`}
                               value={formData.process[processBulk2]?.tm || ''}
                               disabled={!formData.process[processBulk2]}
                               onChange={(evt) => onProcessValueChange(evt, processBulk2, 'tm')}/>
                <FormTextField size={'small'}
                               variant={'outlined'}
                               sx={{fontSize: 12, width: '24%', marginLeft: 1}}
                               id="bulk2 temp"
                               placeholder={'Temp'}
                               name={`${processBulk2}.tp`}
                               value={formData.process[processBulk2]?.tp || ''}
                               disabled={!formData.process[processBulk2]}
                               onChange={(evt) => onProcessValueChange(evt, processBulk2, 'tp')}/>
              </Grid>
            </Grid>
            </div>
    )
  }

  function renderShape() {
    return (
            <Grid item container direction={'column'} alignItems={'flex-start'} marginBottom={1}>
              <Grid item paddingBottom={1.5}>
                <FormControlLabel control={<Switch name={processShape} checked={Object.hasOwn(formData.process, processShape)}
                                                   onChange={onProcessSwitchChange}
                                                   disabled={false}/>}
                                  label={<Typography variant={'subtitle2'}>Divide/Shape Notes</Typography>}/>
              </Grid>
              <FormTextField multiline rows={5}
                         id={'shape'}
                         size={'small'} fullWidth
                         variant={'outlined'}
                         placeholder={'Divide/Shape notes'}
                         disabled={!formData.process[processShape]}
                         name={`${processShape}.nt`}
                         value={formData.process[processShape]?.nt || ''}
                         onChange={(evt) => onProcessValueChange(evt, processShape, 'nt')}/>
            </Grid>
    )
  }

  function renderProofing() {
    return (
            <div>
              <Grid item container direction={'row'} alignItems={'flex-start'} marginBottom={1}>
                <Grid item paddingBottom={1.5}>
                  <FormControlLabel control={<Switch name={processProof1} checked={Object.hasOwn(formData.process, processProof1)}
                                                     onChange={onProcessSwitchChange}
                                                     disabled={false}/>}
                                    label={<Typography variant={'subtitle2'}>Phase 1</Typography>}/>
                </Grid>
                <Grid item paddingBottom={1.5}>
                  <FormTextField select size={'small'}  sx={{width: '10ch'}}
                                 variant={'outlined'}
                                 id={"proof1 sn"}
                                 label={"name"}
                                 disabled={!formData.process[processProof1]}
                                 name={`${processProof1}.sn`}
                                 value={formData.process[processProof1]?.sn || getDefaultSn(processProof1)}
                                 onChange={(evt) => onProcessValueChange(evt, processProof1, 'sn')}>
                    {
                      processProofNameOptions.map((v) => {
                        return <MenuItem key={v} value={v}>{v}</MenuItem>
                      })
                    }
                  </FormTextField>
                  <FormTextField size={'small'}
                                 variant={'outlined'}
                                 sx={{fontSize: 12, marginLeft: 1, width: '33%'}}
                                 id="proof1 time"
                                 placeholder={'Time'}
                                 name={`${processProof1}.tm`}
                                 value={formData.process[processProof1]?.tm || ''}
                                 disabled={!formData.process[processProof1]}
                                 onChange={(evt) => onProcessValueChange(evt, processProof1, 'tm')}/>
                  <FormTextField size={'small'}
                                 variant={'outlined'}
                                 sx={{fontSize: 12, width: '24%', marginLeft: 1}}
                                 id="proof1 temp"
                                 placeholder={'Temp'}
                                 name={`${processProof1}.tp`}
                                 value={formData.process[processProof1]?.tp || ''}
                                 disabled={!formData.process[processProof1]}
                                 onChange={(evt) => onProcessValueChange(evt, processProof1, 'tp')}/>
                </Grid>
              </Grid>

              <Grid item container direction={'row'} alignItems={'flex-start'} marginBottom={1}>
                <Grid item paddingBottom={1.5}>
                  <FormControlLabel control={<Switch name={processProof2} checked={Object.hasOwn(formData.process, processProof2)}
                                                     onChange={onProcessSwitchChange}
                                                     disabled={false}/>}
                                    label={<Typography variant={'subtitle2'}>Phase 2</Typography>}/>
                </Grid>
                <Grid item paddingBottom={1.5}>
                  <FormTextField select size={'small'}  sx={{width: '10ch'}}
                                 variant={'outlined'}
                                 id={"proof2 sn"}
                                 label={"name"}
                                 disabled={!formData.process[processProof2]}
                                 name={`${processProof2}.sn`}
                                 value={formData.process[processProof2]?.sn || getDefaultSn(processProof2)}
                                 onChange={(evt) => onProcessValueChange(evt, processProof2, 'sn')}>
                    {
                      processProofNameOptions.map((v) => {
                        return <MenuItem key={v} value={v}>{v}</MenuItem>
                      })
                    }
                  </FormTextField>
                  <FormTextField size={'small'}
                                 variant={'outlined'}
                                 sx={{fontSize: 12, marginLeft: 1, width: '33%'}}
                                 id="proof2 time"
                                 placeholder={'Time'}
                                 name={`${processProof2}.tm`}
                                 value={formData.process[processProof2]?.tm || ''}
                                 disabled={!formData.process[processProof2]}
                                 onChange={(evt) => onProcessValueChange(evt, processProof2, 'tm')}/>
                  <FormTextField size={'small'}
                                 variant={'outlined'}
                                 sx={{fontSize: 12, width: '24%', marginLeft: 1}}
                                 id="proof2 temp"
                                 placeholder={'Temp'}
                                 name={`${processProof2}.tp`}
                                 value={formData.process[processProof2]?.tp || ''}
                                 disabled={!formData.process[processProof2]}
                                 onChange={(evt) => onProcessValueChange(evt, processProof2, 'tp')}/>
                </Grid>
              </Grid>
            </div>
    )
  }

  function renderBake() {
    return (
            <div>
            <Grid item container direction={'row'} alignItems={'flex-start'} marginBottom={1}>
              <Grid item paddingBottom={1.5}>
                <FormControlLabel control={<Switch name={processBake} checked={Object.hasOwn(formData.process, processBake)}
                                                   onChange={onProcessSwitchChange}
                                                   disabled={false}/>}
                                  label={<Typography variant={'subtitle2'}>Bake</Typography>}/>
                <FormTextField size={'small'}
                               variant={'outlined'}
                               sx={{fontSize: 12, width: '35%'}}
                               id="bulk2 time"
                               placeholder={'Total Time'}
                               name={`${processBake}.tm`}
                               value={formData.process[processBake]?.tm || ''}
                               disabled={!formData.process[processBake]}
                               onChange={(evt) => onProcessValueChange(evt, processBake, 'tm')}/>
                <FormTextField size={'small'}
                               variant={'outlined'}
                               sx={{fontSize: 12, width: '27%', marginLeft: 1}}
                               id="bulk2 temp"
                               placeholder={'Temp'}
                               name={`${processBake}.tp`}
                               value={formData.process[processBake]?.tp || ''}
                               disabled={!formData.process[processBake]}
                               onChange={(evt) => onProcessValueChange(evt, processBake, 'tp')}/>
              </Grid>
            </Grid>
            <Grid item container direction={'row'} alignItems={'flex-start'} marginBottom={1}>
              <FormTextField fullWidth multiline rows={5}
                             id={'bake'}
                             size={'small'}
                             variant={'outlined'}
                             placeholder={'Bake notes'}
                             disabled={!formData.process[processBake]}
                             name={`${processBake}.nt`}
                             value={formData.process[processBake]?.nt || ''}
                             onChange={(evt) => onProcessValueChange(evt, processBake, 'nt')}/>
            </Grid>
            </div>
    )
  }

  return (
          <Grid item container direction={'column'}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                <Typography>Prep</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {renderPrep()}
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                <Typography>Autolyse/Mix</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {renderAutolyse()}
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                <Typography>Final Mix</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {renderMixing()}
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                <Typography>Bulk Ferment</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {renderBulkFerm()}
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                <Typography>Divide/Shape</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {renderShape()}
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                <Typography>Proof</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {renderProofing()}
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                <Typography>Bake</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {renderBake()}
              </AccordionDetails>
            </Accordion>
            <TextField fullWidth multiline rows={10}
                       sx={{marginTop: 1.5}}
                       size={'small'}
                       id={'outlined-textarea'}
                       variant={'outlined'}
                       placeholder={'Other notes'}
                       name={'notes'}
                       value={formData.notes}
                       onChange={onGeneralFieldChange}/>
            <ContextualHelpDialog isOpen={openContextualHelpDialog} close={onCloseHelp}
                                  title={contextualHelpContent.title} content={contextualHelpContent.content}/>
          </Grid>
  )
}

export { ProcessForm }