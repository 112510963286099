
export function toCapitalizedWords(src) {

  const words = src.split('/[ ,]+/')
  const convertedWords = []
  for (let i = 0; i < words.length; i++) {
    convertedWords.push(words[i][0].toUpperCase() + words[i].substring(1))
  }
  return convertedWords.join(' ')
}

export function valueOrUndefined(value) {
  return value?.trim().length > 0 ? value.trim() : undefined
}