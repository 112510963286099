import * as React from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import {useAuth} from "react-oidc-context";
import Button from "@mui/material/Button";
import {extractUsername, formatTokenExpiration} from "../utils/AuthUtils";
import {useTheme} from "@mui/system";

// renew access token every 5 minutes

function UserProfile(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const auth = useAuth();

  if (auth.isLoading) {
    return (
    <Grid container direction={'column'} padding={2} paddingLeft={{xs: '5%', sm: '10%', md: '15%', lg: '20%'}}
          paddingRight={{xs: '5%', sm: '10%', md: '15%', lg: '20%'}} justifyContent={'center'}
          alignItems={'center'} rowSpacing={1}>
      <Grid item xs={4} sm={8} md={12}>
        <Typography sx={{fontSize: 16}}>Checking sign in status, please wait...</Typography>
      </Grid>
    </Grid>
    )
  }

  if (auth.error) {
    auth.signoutSilent().then(() => {console.info(`signoutSilent completed after error detected`)})

    return (
    <Grid container direction={'column'} padding={2} paddingLeft={{xs: '5%', sm: '10%', md: '15%', lg: '20%'}}
          paddingRight={{xs: '5%', sm: '10%', md: '15%', lg: '20%'}} justifyContent={'center'}
          alignItems={'center'} rowSpacing={1}>
      <Grid item xs={4} sm={8} md={12}>
        <Typography sx={{fontSize: 16}}>{`Error during authentication: ${auth.error.message}`}</Typography>
      </Grid>
    </Grid>
    )
  }

  // const isAuthenticated = true;
  const isAuthenticated = auth.isAuthenticated;

  const username = extractUsername(auth);

  if (isAuthenticated) {
    return (
        <Grid container direction={'column'} padding={2} paddingLeft={{xs: '5%', sm: '10%', md: '15%', lg: '20%'}}
              paddingRight={{xs: '5%', sm: '10%', md: '15%', lg: '20%'}} justifyContent={'center'}
              alignItems={'center'} rowSpacing={1}>

          {!isMobile ? (
              <Grid item xs={4} sm={8} md={12}>
                <Typography variant={'h7'}>{`Welcome, ${username}`}</Typography>
              </Grid>
          ) : null}
          <Grid item xs={4} sm={8} md={12}>
            <Typography sx={{fontSize: 16}}>{`While signed in, new formulas will be uploaded to your cloud account and accessible from all signed in devices.`}</Typography>
          </Grid>

          <Grid item xs={4} sm={8} md={12}>
            <Typography sx={{fontSize: 14}}>{`Login token expires in: ${formatTokenExpiration(auth.user.expires_in)}`}</Typography>
          </Grid>

          <Grid container direction={'row'} paddingTop={2} spacing={2} justifyContent={'center'}>
            <Grid item>
              <Button variant={'contained'} onClick={() => auth.removeUser()}>Sign out</Button>
            </Grid>
          </Grid>
        </Grid>
    );
  }

  return (
    <Grid container direction={'column'} padding={2} paddingLeft={{xs: '5%', sm: '10%', md: '15%', lg: '20%'}}
          paddingRight={{xs: '5%', sm: '10%', md: '15%', lg: '20%'}} justifyContent={'center'}
          alignItems={'center'} rowSpacing={1}>

      <Grid item xs={4} sm={8} md={12}>
        <Typography variant={'h7'}>{`Welcome, ${username}`}</Typography>
      </Grid>
      <Grid item xs={4} sm={8} md={12}>
          <Typography sx={{fontSize: 16}}>{`Your formulas will be saved to local browser storage on this device.`}</Typography>
      </Grid>
      <Grid item xs={4} sm={8} md={12}>
        <Typography sx={{fontSize: 16}}>{`To save formulas to your cloud account and access them from other devices, please sign in.`}</Typography>
      </Grid>
      <Grid item xs={4} sm={8} md={12}>
        <Typography sx={{fontSize: 14, fontStyle: 'italic'}}>{`Don't have an account?  The Sign In button will assist with that.`}</Typography>
      </Grid>

      <Grid container direction={'row'} paddingTop={2} spacing={2} justifyContent={'center'}>
        <Grid item>
          <Button variant={'contained'} onClick={() => auth.signinRedirect()}>Sign in</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export {UserProfile}