import * as React from 'react';
import {useState} from "react";
import {FormTextField} from "./FormTextField";
import {Box, Grid, Tooltip} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {deepCopy, findIndexForFieldValue, getFieldValuesAsArray} from "../utils/ArrayUtils";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import * as Units from "./Units";
import {unitPercent} from "./Units";
import {toFixedDecimal} from "../utils/NumberUtils";
import {DecimalStringFormField} from "./DecimalStringFormField";
import {FormFab} from "./FormFab";

/**
 * This component manages a set of related ingredients with units in percentages relative to the group itself.
 * Percentage amounts can be specified as whole numbers or decimal numbers with .01 scale
 * In contrast, the IngredientCollectionForm component handles ingredients with ratio percentages relative to the recipe flour.
 * The component ensures a <balanceGoal>% sum total of all its group members by automatically self-balancing when focus leaves the
 * percentage unit form field.
 *
 * In order to make the self-balancing work, the component uses a DecimalStringFormField component which manages both a String
 * and floating point version of the decimal value. This approach permits character-at-a-time change events from
 * control/user input, but also accepts new values from the parent when self-balancing the values, which is an
 * antipattern in ReactJs (manaaged vs unmanaged).
 * The String and floating point value aren't always 100% in sync visually, e.g. during typing.
 * This approach seems to give the best user experience for field entry typing while also permitting re-balance updates
 * from the parent via onBlur. ...and avoids the key-down/timer event handling approach.
 * @param props with ingredientData as an array of ingredient objects
 * @returns {JSX.Element}
 * @constructor
 */
function IngredientGroupForm(props) {
  const {ingredientData, setIngredientData, balanceGoal, idField, nameField, unitField, groupName, minGroupSize, disabled, readOnlyIngredient} = props
  const [editedIngredientId, setEditedIngredientId] = useState(undefined)

  // sibling String field must be named "{unitField}Str"
  const unitFieldStr = `${unitField}Str`

  const compositeIngredientData = [...ingredientData]
  if (readOnlyIngredient !== undefined) {
    // add read only item first in list
    compositeIngredientData.unshift(readOnlyIngredient)
  }

  if (needsBalancing()) {
    const workingIngredientData = [...compositeIngredientData]
    balanceIngredientPercentages(workingIngredientData, readOnlyIngredient !== undefined ? readOnlyIngredient[idField] : undefined)
  }

  function getUpdatableIngredients(ingredients) {
    return ingredients.filter(obj => !obj['ro']);
  }

  function getReadOnlyIngredients(ingredients) {
    return ingredients.filter(obj => obj['ro']);
  }

  function needsBalancing() {
    if (compositeIngredientData?.length === 0 || editedIngredientId !== undefined) {
      return false
    }

    const sum = compositeIngredientData.map((obj) => obj[unitField]).reduce((pv, cv) => toFixedDecimal(pv + cv))
    return sum !== balanceGoal
  }


  function updateIngredientData(ingredients) {
    setIngredientData(getUpdatableIngredients(ingredients));
  }

  function onIngredientNameChange(evt) {
    const ingredientId = evt.target.dataset.ingredientid
    const ix = findIndexForFieldValue(compositeIngredientData, idField, ingredientId)
    if (ix < 0) {
      console.log(`!! onIngredientNameChange: cannot find ingredient for id ${ingredientId}`)
      return
    }
    const updatedIngredientData = compositeIngredientData.slice()
    updatedIngredientData[ix] = {...compositeIngredientData[ix], [nameField]: evt.target.value}
    updateIngredientData(updatedIngredientData)
  }

  function onIngredientUnitFieldChange(fieldName, floatValue, strValue, id) {
    const ix = findIndexForFieldValue(compositeIngredientData, idField, id)
    if (ix < 0) {
      console.log(`!! onIngredientPctFieldChange: cannot find ingredient for id ${id}`)
      return
    }

    if (floatValue > balanceGoal) {
      floatValue = balanceGoal
      strValue = `${balanceGoal}`
    }

    const updatedIngredientData = compositeIngredientData.slice()
    updatedIngredientData[ix] = {...compositeIngredientData[ix], [unitField]: floatValue, [unitFieldStr]: strValue}
    updateIngredientData(updatedIngredientData)
    setEditedIngredientId(id)
  }

  function onBlurIngredientUnitChange(evt) {
    if (editedIngredientId === undefined) {
      return
    }
    const workingIngredientData = deepCopy(compositeIngredientData)
    const ix = findIndexForFieldValue(workingIngredientData, idField, editedIngredientId)
    if (ix >= 0) {
      // ensure floating point and string values are properly formed after onBlur
      repairValueStates(workingIngredientData[ix])
    }

    balanceIngredientPercentages(workingIngredientData, editedIngredientId)
    updateIngredientData(workingIngredientData)

    setEditedIngredientId(undefined)
  }

  function repairValueStates(ingredientObj) {
    if (isNaN(ingredientObj[unitField]) || !ingredientObj[unitField]) {
      ingredientObj[unitField] = 0
    }
    let adjustedUnitFieldStr = ingredientObj[unitFieldStr]
    if (adjustedUnitFieldStr.endsWith('.')) {
      adjustedUnitFieldStr = adjustedUnitFieldStr.substring(0, adjustedUnitFieldStr.length - 1)
    }
    if (adjustedUnitFieldStr === '') {
      adjustedUnitFieldStr = '0'
    }
    ingredientObj[unitFieldStr] = adjustedUnitFieldStr
  }

  function balanceIngredientPercentages(workingIngredientData, editedIngredientId) {
    let ingredientId = editedIngredientId
    const updatableIngredients = getUpdatableIngredients(workingIngredientData)
    if (updatableIngredients.length === 1) {
      let readOnlySum = 0
      const readOnlyIngredients = getReadOnlyIngredients(workingIngredientData)
      if (readOnlyIngredients.length > 0) {
        readOnlySum = readOnlyIngredients.map(obj => obj[unitField]).reduce((pv, cv) => toFixedDecimal(pv + cv))
      }
      const remainder = toFixedDecimal(balanceGoal - readOnlySum)
      updatableIngredients[0][unitField] = remainder
      updatableIngredients[0][unitFieldStr] = `${remainder}`
      return
    }

    if (ingredientId !== undefined && findIndexForFieldValue(workingIngredientData, idField, ingredientId) < 0) {
      // console.log(`balanceIngredientPercentages: warning, cannot find ingredient for edited ingredientId ${ingredientId}`)
      ingredientId = undefined
    }

    // console.log(`balance: all ids: ${JSON.stringify(workingIngredientData.map(obj => obj[idField]))}`)
    const currentSum = getFieldValuesAsArray(workingIngredientData, unitField).reduce((pv, cv) => toFixedDecimal(pv + cv))
    const currentBalanceDiff = toFixedDecimal(balanceGoal - currentSum)
    if (currentBalanceDiff === 0) {
      return  // balanced already!
    }

    // get updatable ingredients (excluding just-edited key and read only items), sorted by largest % first
    const sortedUpdatableIngredients = workingIngredientData
      .filter((obj) => !obj['ro'] && (!ingredientId || (obj[idField] !== ingredientId)))
      .sort((a, b) => b[unitField] - a[unitField])

    // console.log(`balance: reduce triggered by edited id=${ingredientId}, current sum: ${currentSum}, balance diff: ${currentBalanceDiff}...`)
    // console.log(`balance: updatableKeys: ${JSON.stringify(sortedUpdatableIngredients.map(obj => obj[idField]))}`)

    // Reduce: pv = accumulator value, cv = each ingredient object: iterate until pv goes to zero
    sortedUpdatableIngredients.reduce((pv, cv) => {
      if (pv === 0 ) {
        return pv
      }
      // console.log(`reducing cv[id]=${cv[idField]} value=${cv[unitField]} by ${pv}...`)
      let currVal = cv[unitField] ?? 0
      let newPv = 0
      if (currVal + pv > balanceGoal) {
        newPv = toFixedDecimal((currVal + pv) - balanceGoal)
        cv[unitField] = balanceGoal
      } else if (currVal + pv < 0) {
        newPv = toFixedDecimal(currVal + pv)
        cv[unitField] = 0
      } else {
        cv[unitField] = toFixedDecimal(currVal + pv)
      }
      // maintain the String sibling field
      cv[unitFieldStr] = `${cv[unitField]}`
      return newPv
    }, currentBalanceDiff)
  }

  function addCustomIngredient() {
    const newIx = compositeIngredientData.length + 1
    const initValue = newIx === 1 ? balanceGoal : 0
    const uniqueId = `c.${Math.floor(Math.random() * 1000) + 1}`
    const newIngredientArr = compositeIngredientData.slice()
    newIngredientArr.push({[nameField]: `${groupName}${newIx}`, [unitField]: initValue, [unitFieldStr]: `${initValue}`, [idField]: uniqueId, group: true})
    updateIngredientData(newIngredientArr)
  }

  function deleteIngredient(ingredientId) {
    let workingIngredientData = deepCopy(compositeIngredientData)
    workingIngredientData = workingIngredientData.filter((fd) => fd[idField] !== ingredientId)
    const lastIngredientIx = workingIngredientData.length - 1

    if (lastIngredientIx >=0) {
      balanceIngredientPercentages(workingIngredientData, workingIngredientData[lastIngredientIx][idField])
    }
    updateIngredientData(workingIngredientData)
  }

  const numUpdatableIngredients = getUpdatableIngredients(compositeIngredientData).length
  return (
          <div>
            <Grid item container direction={'column'} rowSpacing={1}>
              {compositeIngredientData.length === 0 && minGroupSize === 0 ? (
                      <Grid item xs={0.65} sm={1.25} md={1.25}>
                        <Box sx={{paddingTop: '0.6rem', paddingLeft: '0.5rem'}}>
                          <Tooltip title={`Add a ${groupName}`} placement={'right'} arrow>
                            <FormFab size="small" color="secondary" aria-label="add">
                              <AddIcon onClick={addCustomIngredient}/>
                            </FormFab>
                          </Tooltip>
                        </Box>
                      </Grid>
                ) : null
              }
              {compositeIngredientData.map((ingredient, idx) => {
                const isReadOnly = ingredient['ro']
                return(
                <Grid key={ingredient[idField]} item container columnSpacing={1} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'} columns={{xs: 4, sm: 8, md: 12}}>
                  <Grid item xs={2.1} sm={3.25} md={5} >
                    <FormTextField size={'small'} disabled={disabled || isReadOnly}
                              id="outlined-required"
                              inputProps={{'data-ingredientid': ingredient[idField]}}
                              name={nameField}
                              label={groupName}
                              value={ingredient[nameField]}
                              onChange={onIngredientNameChange}
                              variant="filled"/>
                  </Grid>
                  <Grid item xs={1.0} sm={1.5} md={3}>
                    <DecimalStringFormField valueStr={ingredient[unitFieldStr]} size={'small'} disabled={disabled || isReadOnly || (minGroupSize > 0 && numUpdatableIngredients === 1)}
                                      name={unitField} label={Units.getUnitLabel(unitPercent)}
                                      onChange={onIngredientUnitFieldChange}
                                      onBlur={onBlurIngredientUnitChange}
                                      id={ingredient[idField]} />
                  </Grid>
                  {!isReadOnly ?
                    <Grid item xs={0.4} sm={1} md={1}>
                      <IconButton sx={{paddingTop: '1.1rem', marginLeft: -1}} onClick={() => deleteIngredient(ingredient[idField])} placement="right" edge="end" disabled={disabled || isReadOnly || (minGroupSize > 0 && numUpdatableIngredients === 1)} >
                        <DeleteIcon/>
                      </IconButton>
                    </Grid>
                    : null}
                  {idx === (compositeIngredientData.length - 1) ?
                    <Grid item xs={0.5} sm={1.25} md={1.25}>
                      <Box sx={{paddingTop: '0.6rem', paddingLeft: '0.05rem'}}>
                        <Tooltip title={`Add a ${groupName}`} placement={'right'} arrow>
                          <span>
                          <FormFab disabled={disabled} size="small" color="secondary" aria-label="add">
                            <AddIcon onClick={addCustomIngredient}/>
                          </FormFab>
                          </span>
                        </Tooltip>
                      </Box>
                    </Grid> : null}
                </Grid>)
              })
              }
            </Grid>
          </div>
  )
}

export { IngredientGroupForm }