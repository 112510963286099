import {getYeastTimingIndex} from "./Yeast";
import {formatZeroFillDecimal} from "../utils/NumberUtils";

const temp65F = {
  "key": "65", "value": 65, "label": "65°F / 18.3°C"
}
const temp70F = {
  "key": "70", "value": 70, "label": "70°F / 21.1°C"
}
const temp75F = {
  "key": "75", "value": 75, "label": "75°F / 23.9°C"
}
const temp80F = {
  "key": "80", "value": 80, "label": "80°F / 26.7°C"
}

const fermTemps = [temp65F, temp70F, temp75F, temp80F]

const allFermVolumes= [5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100]

const doughVolumes=  [20,25,30,35,40,45,50]
const levainVolumes= [100,90,80,75,50]
const defaultBulkFermPct = 25
const defaultLevainFermPct = 100

// 20 x 20 grid, volume% by seed% producing fractional hours value...
// Columns: 5% Seed,10% Seed,15% Seed,20% Seed,25% Seed,30% Seed,35% Seed,40% Seed,45% Seed,50% Seed,55% Seed,60% Seed,65% Seed,70% Seed,75% Seed,80% Seed,85% Seed,90% Seed,95% Seed,100% Seed
// Rows:    5% Vol, 10% Vol, 15% Vol, 20% Vol, 25% Vol, 30% Vol, 35% Vol, 40% Vol, 45% Vol, 50% Vol, 55% Vol, 60% Vol, 65% Vol, 70% Vol, 75% Vol, 80% Vol, 85% Vol, 90% Vol, 95% Vol, 100% Vol
const levainFermentTimings = {
  [temp65F.key]:
          [
            [5.67816504,4.45045368,3.22274232,1.995,2.09094591,2.18686086,1.870341525,1.55382219,1.237302855,0.92,0.886254138,0.851724756,0.817195374,0.782665992,0.74813661,0.713607228,0.679077846,0.644548464,0.610019082,0.5754897],
            [6.15773979,5.21777328,4.27780677,3.33784026,3.0692784,2.80071654,2.676027105,2.55133767,2.426648235,2.302,2.175351066,2.048743332,1.922135598,1.795527864,1.66892013,1.542312396,1.415704662,1.289096928,1.162489194,1.03588146],
            [7.34708517,6.21528876,5.08349235,3.95169594,3.66395109,3.37620624,3.193967835,3.01172943,2.829491025,2.64725262,2.51297169,2.37869076,2.24440983,2.1101289,1.97584797,1.84156704,1.70728611,1.57300518,1.469417034,1.30444332],
            [7.92257487,6.75241248,5.58225009,4.4120877,4.02843,3.6447681,3.520078665,3.39538923,3.270699795,3.1460,2.977200048,2.808389736,2.639579424,2.4708,2.3019588,2.133148488,1.964338176,1.7955,1.672756728,1.411868064],
            [8.11440477,7.05934032,6.00427587,4.94921142,4.43127069,3.9133,3.788640525,3.66395109,3.539261655,3.41457222,3.249598506,3.0846,2.919651078,2.754677364,2.58970365,2.424729936,2.259756222,2.094782508,1.929808794,1.7648],
            [8.49806457,7.40463414,6.31120371,5.21777328,4.592407806,4.18189182,4.08597687,3.99006192,3.89414697,3.79823202,3.598728924,3.399225828,3.199722732,3.000219636,2.80071654,2.601213444,2.401710348,2.202207252,2.002704156,1.80320106],
            [8.78581,7.673196,6.56058,5.44796916,4.91084544,4.37372172,4.306581255,4.23944079,4.172300325,4.10515986,3.89414697,3.68313408,3.47212119,3.2611083,3.05009541,2.83908252,2.62806963,2.41705674,2.20604385,1.99503096],
            [8.99682231,7.94175786,6.88669341,5.83162896,5.23695627,4.64228358,4.54636863,4.450,4.35453873,4.25862378,4.051447488,3.844271196,3.637094904,3.429918612,3.22274232,3.015566028,2.808389736,2.601213444,2.394037152,2.18686086],
            [9.26538417,8.2103,7.120725888,6.046478448,5.46715215,4.8341,4.811093892,4.619263992,4.550205228,4.481146464,4.3123,4.097486664,3.8826,3.667787688,3.4529,3.238088712,3.0232,2.808389736,2.5935,2.37869076],
            [9.70659,8.59397952,7.48137,6.36875268,5.67816504,4.9876,4.920436935,4.85329647,4.786156005,4.71901554,4.492656258,4.266296976,4.039937694,3.813578412,3.58721913,3.3609,3.134500566,2.908141284,2.681782002,2.45542272],
            [9.95597181,8.78580942,7.61564703,6.44548464,5.81244597,5.1794073,5.112266835,5.04512637,4.977985905,4.91084544,4.684486158,4.458126876,4.231767594,4.005408312,3.77904903,3.552689748,3.326330466,3.100,2.873611902,2.64725262],
            [10.20535068,9.0160053,7.82665992,6.63731454,5.98509288,5.33287122,5.275322,5.21777328,5.160224,5.10267534,4.876316058,4.6500,4.423597494,4.197238212,3.97087893,3.744519648,3.518160366,3.291801084,3.065441802,2.8391],
            [10.58901048,9.32293314,8.0568558,6.79077846,6.15773979,5.52470112,5.467152,5.40960,5.352054,5.29450524,5.060472762,4.826440284,4.592407806,4.358375328,4.12434285,3.890310372,3.656277894,3.422245416,3.188212938,2.95418046],
            [10.66574244,9.4380,8.21031972,6.9826,6.3303867,5.67816504,5.630207565,5.58225009,5.534292615,5.48633514,5.24079,4.995250596,4.74971,4.504166052,4.25862,4.013081508,3.76754,3.521996964,3.27645,3.03091242],
            [11.04940224,9.74495892,8.4405156,7.1361,6.44548464,5.862321744,5.822037465,5.77407999,5.726122515,5.678,5.432622768,5.187080496,4.941538224,4.695995952,4.45045368,4.204911408,3.959369136,3.714,3.468284592,3.22274232],
            [11.20286616,9.89842284,8.59397952,7.2895,6.73322949,6.17692278,6.146229996,6.054151644,5.962073292,5.86999494,5.612942874,5.355890808,5.098838742,4.841786676,4.58473461,4.328,4.070630478,3.813578412,3.556526346,3.29947428],
            [11.75917287,10.32044862,8.88172437,7.4430,6.9058764,6.36875268,6.320795205,6.27283773,6.224880255,6.17692278,5.90836092,5.63979906,5.3712372,5.10267534,4.83411348,4.56555162,4.29698976,4.0284279,3.75986604,3.49130418],
            [12.00855,10.58901048,9.16947,7.74992796,7.1361,6.5222166,6.48385062,6.44548464,6.40711866,6.3688,6.10019,5.83162896,5.56307,5.2945,5.02594,4.75738152,4.48882,4.2203,3.95170,3.68313408],
            [12.21956463,10.85757234,9.51476304,8.102894976,7.48137,6.8291,6.762003975,6.69486351,6.627723045,6.56058258,6.280510926,6.0004,5.720367618,5.440295964,5.16022431,4.880152656,4.600081002,4.320009348,4.039937694,3.7599], 
            [12.71832237,11.27959812,9.84087387,8.40214962,7.71156198,7.02097434,6.94424,6.86751042,6.79078,6.7140465,6.437811444,6.161576388,5.885341332,5.609106276,5.33287122,5.056636164,4.780401108,4.504166052,4.266296976,3.959369136]
          ],
  [temp70F.key]:
          [
            [3.6875, 3.0, 2.3125, 1.625, 1.625, 1.625, 1.40625, 1.1875, 0.96875, 0.75, 0.725, 0.7, 0.675, 0.65, 0.625, 0.6, 0.575, 0.55, 0.525, 0.5],
            [4.1875, 3.625, 3.0625, 2.5, 2.28125, 2.0625, 1.953125, 1.84375, 1.734375, 1.625, 1.54375, 1.4625, 1.38125, 1.3, 1.21875, 1.1375, 1.05625, 0.975, 0.89375, 0.8125],
            [4.9375, 4.25, 3.5625, 2.875, 2.65625, 2.4375, 2.296875, 2.15625, 2.015625, 1.875, 1.7875, 1.7, 1.6125, 1.525, 1.4375, 1.35, 1.2625, 1.175, 1.1, 1.0],
            [5.34375, 4.625, 3.90625, 3.1875, 2.90625, 2.625, 2.515625, 2.40625, 2.296875, 2.1875, 2.08125, 1.975, 1.86875, 1.7625, 1.65625, 1.55, 1.44375, 1.3375, 1.23125, 1.125],
            [5.5625, 4.875, 4.1875, 3.5, 3.15625, 2.8125, 2.703125, 2.59375, 2.484375, 2.375, 2.26875, 2.1625, 2.05625, 1.95, 1.84375, 1.7375, 1.63125, 1.525, 1.41875, 1.3125],
            [5.84375, 5.125, 4.40625, 3.6875, 3.3, 3.0, 2.90625, 2.8125, 2.71875, 2.625, 2.5, 2.375, 2.25, 2.125, 2, 1.875, 1.75, 1.625, 1.5, 1.375],
            [6.03125, 5.3125, 4.59375, 3.875, 3.5, 3.125, 3.046875, 2.96875, 2.890625, 2.8125, 2.68125, 2.55, 2.41875, 2.2875, 2.15625, 2.025, 1.89375, 1.7625, 1.63125, 1.5],
            [6.1875, 5.5, 4.8125, 4.125, 3.71875, 3.3125, 3.21875, 3.125, 3.03125, 2.9375, 2.80625, 2.675, 2.54375, 2.4125, 2.28125, 2.15, 2.01875, 1.8875, 1.75625, 1.625],
            [6.375, 5.6875, 5, 4.3125, 3.875, 3.4375, 3.359375, 3.28125, 3.203125, 3.125, 2.9875, 2.85, 2.7125, 2.575, 2.4375, 2.3, 2.1625, 2.025, 1.8875, 1.75],
            [6.65625, 5.9375, 5.21875, 4.5, 4.03125, 3.5625, 3.484375, 3.40625, 3.328125, 3.25, 3.10625, 2.9625, 2.81875, 2.675, 2.53125, 2.3875, 2.24375, 2.1, 1.95625, 1.8125],
            [6.8125, 6.0625, 5.3125, 4.5625, 4.125, 3.6875, 3.609375, 3.53125, 3.453125, 3.375, 3.23125, 3.0875, 2.94375, 2.8, 2.65625, 2.5125, 2.36875, 2.225, 2.08125, 1.9375],
            [7.03125, 6.25, 5.46875, 4.6875, 4.25, 3.8125, 3.734375, 3.65625, 3.578125, 3.5, 3.35625, 3.2125, 3.06875, 2.925, 2.78125, 2.6375, 2.49375, 2.35, 2.20625, 2.0625],
            [7.25, 6.4375, 5.625, 4.8125, 4.375, 3.9375, 3.859375, 3.78125, 3.703125, 3.625, 3.475, 3.325, 3.175, 3.025, 2.875, 2.725, 2.575, 2.425, 2.275, 2.125],
            [7.375, 6.5625, 5.75, 4.9375, 4.5, 4.0625, 3.984375, 3.90625, 3.828125, 3.75, 3.59375, 3.4375, 3.28125, 3.125, 2.96875, 2.8125, 2.65625, 2.5, 2.34375, 2.1875],
            [7.59375, 6.75, 5.90625, 5.0625, 4.625, 4.1875, 4.109375, 4.03125, 3.953125, 3.875, 3.71875, 3.5625, 3.40625, 3.25, 3.09375, 2.9375, 2.78125, 2.625, 2.46875, 2.3125],
            [7.71875, 6.875, 6.03125, 5.1875, 4.78125, 4.375, 4.3, 4.2, 4.1, 4.0, 3.8375, 3.675, 3.5125, 3.35, 3.1875, 3.025, 2.8625, 2.7, 2.5375, 2.375],
            [8.03125, 7.125, 6.21875, 5.3125, 4.90625, 4.5, 4.421875, 4.34375, 4.265625, 4.1875, 4.01875, 3.85, 3.68125, 3.5125, 3.34375, 3.175, 3.00625, 2.8375, 2.66875, 2.5],
            [8.21875, 7.3125, 6.40625, 5.5, 5.0625, 4.625, 4.546875, 4.46875, 4.390625, 4.3125, 4.14375, 3.975, 3.80625, 3.6375, 3.46875, 3.3, 3.13125, 2.9625, 2.79375, 2.625],
            [8.375, 7.5, 6.625, 5.75, 5.28125, 4.8125, 4.71875, 4.625, 4.53125, 4.4375, 4.2625, 4.0875, 3.9125, 3.7375, 3.5625, 3.3875, 3.2125, 3.0375, 2.8625, 2.6875],
            [8.65625, 7.75, 6.84375, 5.9375, 5.4375, 4.9375, 4.84375, 4.75, 4.65625, 4.5625, 4.3875, 4.2125, 4.0375, 3.8625, 3.6875, 3.5125, 3.3375, 3.1625, 2.9875, 2.8125]
          ],
  [temp75F.key]:
          [
            [3.06,2.645,2.22771136,1.810,1.722996,1.63598,1.4358296,1.235684,1.0355377,0.84,0.811026,0.78666,0.762295,0.7379,0.713564,0.68920,0.664833,0.640,0.616101,0.59174],
            [3.741859,3.34156704,2.941275,2.54098,2.2973,2.05367,1.9231414,1.792611,1.6620815,1.532,1.465416,1.39928,1.333146,1.2670,1.200876,1.13474,1.068605,1.0,0.936335,0.87020],
            [4.333595,3.8289,3.324163,2.81945,2.593195,2.367,2.2190094,2.071075,1.9231414,1.77521,1.702111,1.6290,1.555917,1.48282,1.409724,1.3,1.263530,1.19043,1.117336,1.0442],
            [4.716483,4.18,3.637435,3.09791,2.81945,2.54098,2.4104533,2.279923,2.1493934,2.0189,1.9353,1.8518,1.7682,1.6847,1.6012,1.5176,1.4341,1.3506,1.2,1.2],
            [5.029755,4.45542272,3.881091,3.30676,3.010891,2.7150,2.5844933,2.453963,2.3234333,2.19290,2.105883,2.0189,1.931843,1.84482,1.757803,1.7,1.583764,1.49674,1.409724,1.3227],
            [5.308218,4.69908,4.089939,3.481,3.184931,2.88906,2.767235,2.645,2.523579,2.40175,2.30429,2.20683,2.10936,2.01190,1.91444,1.81698,1.71951,1.62205,1.52459,1.42713],
            [5.46485,4.873,4.28138,3.6896,3.34156704,2.9935,2.8803612,2.767235,2.6541092,2.54098,2.440040,2.3,2.238154,2.13721,2.036267,1.9353,1.834381,1.73344,1.632495,1.532],
            [5.621490,5.04716,4.472827,3.9,3.533011,3.16753,3.045699,2.924,2.802043,2.68022,2.5758,2.47137,2.367,2.26252,2.1581,2.05367,1.95,1.84482,1.7404,1.63598],
            [5.795530,5.2212,4.7,4.1,3.672243,3.2720,3.1,3.1,3.0,2.9,2.7429,2.6315,2.5201,2.4087,2.2973,2.1859,2.0746,1.9632,1.8518,1.7404],
            [6.02178,5.430,4.83831,4.2466,3.8289,3.4112,3.2980571,3.184931,3.0718051,2.95868,2.843813,2.73,2.614080,2.49921,2.384347,2.2695,2.154615,2.03975,1.924882,1.810],
            [6.143610,5.53447,4.925331,4.316,3.915899,3.51561,3.4024810,3.289355,3.1762291,3.06,2.948237,2.83337,2.718504,2.6036,2.488771,2.37390,2.259039,2.144,2.029306,1.91444],
            [6.40,5.74332,5.0820,4.42061,4.038,3.65484,3.533011,3.4112,3.289355,3.16753,3.052661,2.9378,2.822928,2.70806,2.593195,2.478,2.363463,2.24860,2.133730,2.0189],
            [6.544,5.88255,5.2212,4.55985,4.159555,3.759,3.637435,3.51561,3.393779,3.2720,3.150123,3.02830,2.906467,2.8,2.662811,2.54098,2.419155,2.2973,2.175499,2.05367],
            [6.7528,6.0566,5.3604,4.6643,4.28138,3.9,3.7679649,3.637435,3.5069050,3.37638,3.25107,3.12576,3.00045,2.87514,2.74983,2.62452,2.49921,2.37390,2.24860,2.12329],
            [6.8920,6.1958,5.4997,4.8035,4.5,4.0,3.8723889,3.741859,3.6113290,3.481,3.3555,3.2,3.1049,2.980,2.8543,2.73,2.6036,2.478,2.3530,2.22771136],
            [7.0312,6.3351,5.6389,4.9427,4.542443,4.14215,4.00292,3.86369,3.72445,3.58522,3.456433,3.3276,3.198854,3.07006,2.941275,2.812,2.683696,2.55491,2.426117,2.2973],
            [7.222658,6.50909,5.795530,5.0820,4.6643,4.2466,4.1160448,3.985515,3.8549849,3.72445,3.592,3.45991,3.3276,3.19537,3.06,2.93083,2.7986,2.66629,2.534,2.40175],
            [7.41410,6.68313408,5.95217,5.2212,4.8035,4.3858,4.2466,4.1073,3.9681,3.8289,3.69661,3.6,3.43207,3.2998,3.16753,3.035,2.90299,2.7707,2.63845,2.51],
            [7.570738,6.85717,6.1,5.5,4.97754,4.5250,4.3771047,4.229171,4.0812368,3.93330,3.797552,3.6618,3.526049,3.39030,3.254547,3.1,2.983045,2.84729,2.711542,2.5758],
            [7.744778,7.0312,6.317650,5.60409,5.11677,4.62946,4.49023,4.35100,4.21177,4.07253,3.93330,3.79407,3.65484,3.51561,3.37638,3.23714,3.09791,2.95868,2.8,2.7]
          ],
  [temp80F.key]:
          [
            [1.8125, 1.75, 1.6875, 1.625, 1.46875, 1.3125, 1.171875, 1.03125, 0.890625, 0.75, 0.73125, 0.7125, 0.69375, 0.675, 0.65625, 0.6375, 0.61875, 0.6, 0.58125, 0.5625],
            [2.53125, 2.375, 2.21875, 2.0625, 1.84375, 1.625, 1.5, 1.375, 1.25, 1.125, 1.0875, 1.05, 1.0125, 0.975, 0.9375, 0.9, 0.8625, 0.825, 0.7875, 0.75],
            [2.84375, 2.625, 2.40625, 2.1875, 2, 1.8125, 1.6875, 1.5625, 1.4375, 1.3125, 1.26875, 1.225, 1.18125, 1.1375, 1.09375, 1.05, 1.00625, 0.9625, 0.91875, 0.875],
            [3.125, 2.875, 2.625, 2.375, 2.15625, 1.9375, 1.8125, 1.6875, 1.5625, 1.4375, 1.39375, 1.35, 1.30625, 1.2625, 1.21875, 1.175, 1.13125, 1.0875, 1.0, 1.0],
            [3.46875, 3.125, 2.78125, 2.4375, 2.25, 2.0625, 1.9375, 1.8125, 1.6875, 1.5625, 1.5125, 1.4625, 1.4125, 1.3625, 1.3125, 1.2625, 1.2125, 1.1625, 1.1125, 1.0625],
            [3.6875, 3.3125, 2.9375, 2.5625, 2.375, 2.1875, 2.0625, 1.9375, 1.8125, 1.6875, 1.6375, 1.5875, 1.5375, 1.4875, 1.4375, 1.3875, 1.3375, 1.2875, 1.2375, 1.1875],
            [3.78125, 3.4375, 3.09375, 2.75, 2.5, 2.25, 2.125, 2, 1.875, 1.75, 1.7, 1.65, 1.6, 1.55, 1.5, 1.45, 1.4, 1.35, 1.3, 1.25],
            [3.90625, 3.5625, 3.21875, 2.875, 2.625, 2.375, 2.25, 2.125, 2, 1.875, 1.81875, 1.7625, 1.70625, 1.65, 1.59375, 1.5375, 1.48125, 1.425, 1.36875, 1.3125],
            [4.03125, 3.6875, 3.3, 3.0, 2.71875, 2.4375, 2.3, 2.2, 2.1, 2.0, 1.9375, 1.875, 1.8125, 1.75, 1.6875, 1.625, 1.5625, 1.5, 1.4375, 1.375],
            [4.15625, 3.8125, 3.46875, 3.125, 2.84375, 2.5625, 2.4375, 2.3125, 2.1875, 2.0625, 2, 1.9375, 1.875, 1.8125, 1.75, 1.6875, 1.625, 1.5625, 1.5, 1.4375],
            [4.21875, 3.875, 3.53125, 3.1875, 2.90625, 2.625, 2.5, 2.375, 2.25, 2.125, 2.0625, 2, 1.9375, 1.875, 1.8125, 1.75, 1.6875, 1.625, 1.5625, 1.5],
            [4.46875, 4.0625, 3.65625, 3.25, 3, 2.75, 2.609375, 2.46875, 2.328125, 2.1875, 2.125, 2.0625, 2, 1.9375, 1.875, 1.8125, 1.75, 1.6875, 1.625, 1.5625],
            [4.5, 4.125, 3.75, 3.375, 3.09375, 2.8125, 2.671875, 2.53125, 2.390625, 2.25, 2.18125, 2.1125, 2.04375, 1.975, 1.90625, 1.8375, 1.76875, 1.7, 1.63125, 1.5625],
            [4.75, 4.3125, 3.875, 3.4375, 3.1875, 2.9375, 2.78125, 2.625, 2.46875, 2.3125, 2.24375, 2.175, 2.10625, 2.0375, 1.96875, 1.9, 1.83125, 1.7625, 1.69375, 1.625],
            [4.78125, 4.375, 3.96875, 3.5625, 3.3, 3.0, 2.84375, 2.6875, 2.53125, 2.375, 2.30625, 2.2375, 2.16875, 2.1, 2.03125, 1.9625, 1.89375, 1.825, 1.75625, 1.6875],
            [4.90625, 4.5, 4.09375, 3.6875, 3.375, 3.0625, 2.90625, 2.75, 2.59375, 2.4375, 2.36875, 2.3, 2.23125, 2.1625, 2.09375, 2.025, 1.95625, 1.8875, 1.81875, 1.75],
            [4.9375, 4.5625, 4.1875, 3.8125, 3.46875, 3.125, 2.96875, 2.8125, 2.65625, 2.5, 2.43125, 2.3625, 2.29375, 2.225, 2.15625, 2.0875, 2.01875, 1.95, 1.88125, 1.8125],
            [5.09375, 4.6875, 4.28125, 3.875, 3.5625, 3.25, 3.078125, 2.90625, 2.734375, 2.5625, 2.49375, 2.425, 2.35625, 2.2875, 2.21875, 2.15, 2.08125, 2.0125, 1.94375, 1.875],
            [5.21875, 4.8125, 4.4, 4.0, 3.65625, 3.3125, 3.140625, 2.96875, 2.796875, 2.625, 2.55625, 2.4875, 2.41875, 2.35, 2.28125, 2.2125, 2.14375, 2.075, 2.00625, 1.9375],
            [5.25, 4.875, 4.5, 4.125, 3.75, 3.375, 3.21875, 3.0625, 2.90625, 2.75, 2.675, 2.6, 2.525, 2.45, 2.375, 2.3, 2.225, 2.15, 2.1, 2.0]
          ]
}


// Columns: 1/32T Seed, 1/16T Seed, 1/8T Seed, 1/4T Seed, 1/2T Seed, 3/4T Seed, 1T Seed
// Rows:    5% Vol, 10% Vol, 15% Vol, 20% Vol, 25% Vol, 30% Vol, 35% Vol, 40% Vol, 45% Vol, 50% Vol, 55% Vol, 60% Vol, 65% Vol, 70% Vol, 75% Vol, 80% Vol, 85% Vol, 90% Vol, 95% Vol, 100% Vol
const yeastFermentTimings = {
  [temp65F.key]:
          [
            [7.45696875,3.540585,2.61579375,1.42215,0.44076375,0.28507125,0.137335],
            [9.395780625,5.900975,3.8510296875,1.848795,0.587685,0.4988746875,0.27467],
            [10.6634653125,6.638596875,4.2869953125,2.2043325,0.73460625,0.6414103125,0.3433375],
            [11.3345925,7.0074078125,4.6503,2.417655,0.808066875,0.712678125,0.412005],
            [11.782010625,7.523743125,4.94094375,2.55987,0.954988125,0.85521375,0.4229918],
            [12.22942875,7.6712675,5.086265625,2.702085,1.02844875,0.9264815625,0.4806725],
            [12.676846875,7.96631625,5.3042484375,2.7731925,1.101909375,0.997749375,0.494406],
            [13.124265,8.4826515625,5.52223125,2.9154075,1.17537,1.0690171875,0.5108862],
            [13.571683125,8.703938125,5.812875,3.0576225,1.248830625,1.140285,0.5273664],
            [13.869961875,8.998986875,5.958196875,3.1998375,1.32229125,1.2115528125,0.54934],
            [14.31738,9.294035625,6.1761796875,3.41316,1.395751875,1.2543135,0.576807],
            [14.61565875,9.884133125,6.3941625,3.555375,1.410444,1.282820625,0.5932872],
            [14.9885071875,10.0316575,6.4668234375,3.7686975,1.4692125,1.3540884375,0.6180075],
            [15.4359253125,10.621755,6.6121453125,3.98202,1.542673125,1.368342,0.686675],
            [15.898257375,11.064328125,6.830128125,4.26645,1.61613375,1.42535625,0.714142],
            [16.1816221875,11.50690125,7.120771875,4.55088,1.689594375,1.4966240625,0.769076],
            [16.6290403125,12.09699875,7.3387546875,4.83531,1.763055,1.596399,0.82401],
            [17.151028125,12.539571875,7.702059375,5.11974,1.836515625,1.567891875,0.851477],
            [17.59844625,13.2034315625,7.992703125,5.40417,1.90997625,1.6391596875,0.8926775],
            [18.19500375,13.719766875,8.42866875,5.6886,1.983436875,1.7104275,0.961345],
          ],
  [temp70F.key]:
          [
            [6.25, 3.0, 2.25, 1.25, 0.375, 0.25, 0.125],
            [7.875, 5, 3.3125, 1.625, 0.5, 0.4375, 0.25],
            [8.9375, 5.625, 3.6875, 1.9375, 0.625, 0.5625, 0.3125],
            [9.5, 5.9375, 4.0, 2.125, 0.6875, 0.625, 0.375],
            [9.875, 6.375, 4.25, 2.25, 0.8125, 0.75, 0.385],
            [10.25, 6.5, 4.375, 2.375, 0.875, 0.8125, 0.4375],
            [10.625, 6.75, 4.5625, 2.4375, 0.9375, 0.875, 0.45],
            [11.0, 7.1875, 4.75, 2.5625, 1.0, 0.9375, 0.465],
            [11.375, 7.375, 5.0, 2.6875, 1.0625, 1.0, 0.48],
            [11.625, 7.625, 5.125, 2.8125, 1.125, 1.0625, 0.5],
            [12.0, 7.875, 5.3125, 3.0, 1.1875, 1.10, 0.525],
            [12.25, 8.375, 5.5, 3.125, 1.20, 1.125, 0.54],
            [12.5625, 8.5, 5.5625, 3.3125, 1.25, 1.1875, 0.5625],
            [12.9375, 9.0, 5.6875, 3.5, 1.3125, 1.2, 0.625],
            [13.325, 9.375, 5.875, 3.75, 1.375, 1.25, 0.65],
            [13.5625, 9.75, 6.125, 4.0, 1.4375, 1.3125, 0.7],
            [13.9375, 10.25, 6.3125, 4.25, 1.5, 1.4, 0.75],
            [14.375, 10.625, 6.625, 4.5, 1.5625, 1.375, 0.775],
            [14.75, 11.1875, 6.875, 4.75, 1.625, 1.4375, 0.8125],
            [15.25, 11.625, 7.25, 5.0, 1.6875, 1.5, 0.875]
          ],
  [temp75F.key]:
          [
            [5.04303125, 2.459415, 1.88420625, 1.07785, 0.30923625, 0.21492875, 0.112665],
            [6.354219375, 4.099025, 2.7739703125, 1.401205, 0.412315, 0.3761253125, 0.22533],
            [7.2115346875, 4.611403125, 3.0880046875, 1.6706675, 0.51539375, 0.4835896875, 0.2816625],
            [7.6654075, 4.8675921875, 3.3497, 1.832345, 0.566933125, 0.537321875, 0.337995],
            [7.967989375, 5.226256875, 3.55905625, 1.94013, 0.670011875, 0.64478625, 0.3470082],
            [8.27057125, 5.3287325, 3.663734375, 2.047915, 0.72155125, 0.6985184375, 0.3943275],
            [8.573153125, 5.53368375, 3.8207515625, 2.1018075, 0.773090625, 0.752250625, 0.405594],
            [8.875735, 5.8923484375, 3.97776875, 2.2095925, 0.82463, 0.8059828125, 0.4191138],
            [9.178316875, 6.046061875, 4.187125, 2.3173775, 0.876169375, 0.859715, 0.4326336],
            [9.380038125, 6.251013125, 4.291803125, 2.4251625, 0.92770875, 0.9134471875, 0.45066],
            [9.68262, 6.455964375, 4.4488203125, 2.58684, 0.979248125, 0.9456865, 0.473193],
            [9.88434125, 6.865866875, 4.6058375, 2.694625, 0.989556, 0.967179375, 0.4867128],
            [10.1364928125, 6.9683425, 4.6581765625, 2.8563025, 1.0307875, 1.0209115625, 0.5069925],
            [10.4390746875, 7.378245, 4.7628546875, 3.01798, 1.082326875, 1.031658, 0.563325],
            [10.751742625, 7.685671875, 4.919871875, 3.23355, 1.13386625, 1.07464375, 0.585858],
            [10.9433778125, 7.99309875, 5.129228125, 3.44912, 1.185405625, 1.1283759375, 0.630924],
            [11.2459596875, 8.40300125, 5.2862453125, 3.66469, 1.236945, 1.203601, 0.67599],
            [11.598971875, 8.710428125, 5.547940625, 3.88026, 1.288484375, 1.182108125, 0.698523],
            [11.90155375, 9.1715684375, 5.757296875, 4.09583, 1.34002375, 1.2358403125, 0.7323225],
            [12.30499625, 9.530233125, 6.07133125, 4.3114, 1.391563125, 1.2895725, 0.788655]
          ],
  [temp80F.key]:
          [
            [3.8360625, 1.91883, 1.5184125, 0.9057, 0.2434725, 0.1798575, 0.10033],
            [4.83343875, 3.19805, 2.235440625, 1.17741, 0.32463, 0.314750625, 0.20066],
            [5.485569375, 3.59780625, 2.488509375, 1.403835, 0.4057875, 0.404679375, 0.250825],
            [5.830815, 3.797684375, 2.6994, 1.53969, 0.44636625, 0.44964375, 0.30099],
            [6.06097875, 4.07751375, 2.8681125, 1.63026, 0.52752375, 0.5395725, 0.3090164],
            [6.2911425, 4.157465, 2.95246875, 1.72083, 0.5681025, 0.584536875, 0.351155],
            [6.52130625, 4.3173675, 3.079003125, 1.766115, 0.60868125, 0.62950125, 0.361188],
            [6.75147, 4.597196875, 3.2055375, 1.856685, 0.64926, 0.674465625, 0.3732276],
            [6.98163375, 4.71712375, 3.37425, 1.947255, 0.68983875, 0.71943, 0.3852672],
            [7.13507625, 4.87702625, 3.45860625, 2.037825, 0.7304175, 0.764394375, 0.40132],
            [7.36524, 5.03692875, 3.585140625, 2.17368, 0.77099625, 0.791373, 0.421386],
            [7.5186825, 5.35673375, 3.711675, 2.26425, 0.779112, 0.80935875, 0.4334256],
            [7.710485625, 5.436685, 3.753853125, 2.400105, 0.811575, 0.854323125, 0.451485],
            [7.940649375, 5.75649, 3.838209375, 2.53596, 0.85215375, 0.863316, 0.50165],
            [8.17848525, 5.99634375, 3.96474375, 2.7171, 0.8927325, 0.8992875, 0.521716],
            [8.324255625, 6.2361975, 4.13345625, 2.89824, 0.93331125, 0.944251875, 0.561848],
            [8.554419375, 6.5560025, 4.259990625, 3.07938, 0.97389, 1.007202, 0.60198],
            [8.82294375, 6.79585625, 4.47088125, 3.26052, 1.01446875, 0.98921625, 0.622046],
            [9.0531075, 7.155636875, 4.63959375, 3.44166, 1.0550475, 1.034180625, 0.652145],
            [9.3599925, 7.43546625, 4.8926625, 3.6228, 1.09562625, 1.079145, 0.70231]
          ]
}

// For a given target temperature, target volume, and starter seed pct as whole number, return the time in fractional hours.
// If format = true, returns a formatted string, hh:MM, otherwise an object with time components, {hours: h, minutes: m, seconds: 0}
function getLevainTime(targetTempKey, targetVolumePct, starterSeedPct, hydrationPct, format = true) {
  if (starterSeedPct < 1) {
    return format ? "00:00" : {hours: 0, minutes: 0, seconds: 0}
  }
  if (!Object.keys(levainFermentTimings).includes(targetTempKey)) {
    console.warn(`FermentationTimings.getLevainTime: unknown targetTempKey, ${targetTempKey}!`)
    return format ? "00:00" : {hours: 0, minutes: 0, seconds: 0}
  }
  if (targetVolumePct % 5 !== 0 || (targetVolumePct < 5 || targetVolumePct > 100)) {
    console.warn(`FermentationTimings.getLevainTime: invalid targetVolumePct, ${targetVolumePct}!`)
    return format ? "00:00" : {hours: 0, minutes: 0, seconds: 0}
  }
  const volumeArrayIndex = (targetVolumePct / 5) - 1
  const tempAndVolumeRow = levainFermentTimings[targetTempKey][volumeArrayIndex]
  const seedIndexDecimal = (starterSeedPct / 5) - 1
  const seedIndexBase = Math.trunc(seedIndexDecimal)
  const seedIndexFraction = Number.parseFloat((seedIndexDecimal % 1).toFixed(6))
  let lowerBound
  let upperBound
  if (seedIndexBase >= 19) {
    lowerBound = tempAndVolumeRow[19]
    upperBound = tempAndVolumeRow[19]
  } else {
    lowerBound = tempAndVolumeRow[seedIndexBase]
    upperBound = tempAndVolumeRow[seedIndexBase + 1]
  }

  let hoursDecimal =  (lowerBound - ((lowerBound - upperBound) * seedIndexFraction))
  const timeComponents = getTimeComponents(hoursDecimal.toFixed(6))
  if (!format) {
    // baseline measured extrapolation
    return timeComponents
  }
  // TODO: monitor and adjust as needed!
  //  add buffer based on observations during winter/low humidity months...
  const bufferedHoursDecimal = hoursDecimal + (hoursDecimal * (0.2))
  const bufferedTimeComponents = getTimeComponents(bufferedHoursDecimal.toFixed(6))
  const rangeStartFmt = formatTimeComponents(timeComponents)
  const rangeEndFmt = formatTimeComponents(bufferedTimeComponents)
  return `${rangeStartFmt}~${rangeEndFmt}`
}

// For a given target temperature, target volume, and yeastRatioId, return the time in fractional hours.
// If format = true, returns a formatted string, hh:MM, otherwise an object with time components, {hours: h, minutes: m, seconds: 0}
function getYeastTime(targetTempKey, targetVolumePct, yeastRatioId, hydrationPct, format = true) {
  if (!Object.keys(yeastFermentTimings).includes(targetTempKey)) {
    console.warn(`FermentationTimings.getYeastTime: unknown targetTempKey, ${targetTempKey} !`)
    return format ? "00:00" : {hours: 0, minutes: 0, seconds: 0}
  }
  if (targetVolumePct % 5 !== 0 || (targetVolumePct < 5 || targetVolumePct > 100)) {
    console.warn(`FermentationTimings.getYeastTime: invalid targetVolumePct, ${targetVolumePct} !`)
    return format ? "00:00" : {hours: 0, minutes: 0, seconds: 0}
  }
  let yeastRatioIndex = getYeastTimingIndex(yeastRatioId)
  if (yeastRatioIndex < 0) {
    console.warn(`FermentationTimings.getYeastTime: no timings for yeastRatioId ${yeastRatioId}!`)
    return format ? "00:00" : {hours: 0, minutes: 0, seconds: 0}
  }
  if (yeastRatioIndex > 6) {
    yeastRatioIndex = 6
  }
  const volumeArrayIndex = (targetVolumePct / 5) - 1
  const tempAndVolumeRow = yeastFermentTimings[targetTempKey][volumeArrayIndex]
  const hoursDecimal = tempAndVolumeRow[yeastRatioIndex]
  const timeComponents = getTimeComponents(hoursDecimal)
  if (!format) {
    // baseline measured extrapolation
    return timeComponents
  }
  // TODO: monitor and adjust as needed!
  //  add buffer based on observations during winter/low humidity months...
  const bufferedHoursDecimal = hoursDecimal + (hoursDecimal * (0.22))
  const bufferedTimeComponents = getTimeComponents(bufferedHoursDecimal.toFixed(6))
  const rangeStartFmt = formatTimeComponents(timeComponents)
  const rangeEndFmt = formatTimeComponents(bufferedTimeComponents)
  return `${rangeStartFmt}~${rangeEndFmt}`
}

function getTimeComponents(hoursFraction) {
  let hours = Math.trunc(hoursFraction)
  const fractionalHours = (hoursFraction % 1).toFixed(6)
  let minutes = fractionalHours > 0 ? Math.round(60 * fractionalHours) : 0
  if (minutes >= 60) {
    ++hours
    minutes = 0
  }
  return {
    hours: hours, minutes: minutes, seconds: 0
  }
}

// produces hh:MM[:ss] string format
function formatTimeComponents(timeComponents) {
  let result = `${formatZeroFillDecimal(timeComponents.hours, 2)}:${formatZeroFillDecimal(timeComponents.minutes, 2)}`
  if (timeComponents.seconds > 0) {
    result += `:${formatZeroFillDecimal(timeComponents.seconds, 2)}`
  }
  return result
}

// exporting separately to more easily accommodate js console run/debug of the above functions
export {temp65F, temp70F, temp75F, temp80F, fermTemps, doughVolumes, levainVolumes, defaultLevainFermPct, defaultBulkFermPct, levainFermentTimings, yeastFermentTimings, getLevainTime, getYeastTime, getTimeComponents, formatTimeComponents}



