import * as React from 'react';
import PropTypes from "prop-types";
import {
  Grid,
  Table, TableBody, TableContainer, TableRow
} from "@mui/material";
import {RecipeIngredientTableCell} from "./RecipeTableCell";
import {getUnitLabel, unitGrams} from "../shared/Units";

function RecipeIngredientTable(props) {
  const {ingredientList, includeTotalRow} = props
  let generateTotals = includeTotalRow
  if (generateTotals === undefined) {
    generateTotals =  true
  }

  function renderRow(ingredient, ix) {
    if (!Object.hasOwn(ingredient, 'unit')) {
      ingredient['unit'] = unitGrams
    }
    let percentDisplay = ''
    if (Object.hasOwn(ingredient, 'percent') && ingredient.percent > 0 ) {
      let percentStr = `${(Math.round(ingredient.percent * 100) / 100).toFixed(2)}`
      if (ingredient.percent < 10) {
        percentStr = `0${percentStr}`
      } else if (ingredient.percent === 100) {
        percentStr = '100'
      }
      // Use this after implementing a 3-column, single table layout
      // ... that will require creating some new components for a couple different styles of row headers that
      // use <Typography> tags:
      //  1. Bold 14pt for the Preferment and Main Dough sections
      //  2. Gray 14pt for the Ingredient group subheadings, Flour, Liquid, etc.
      // Then in DoughRecipe, wrap everything after RecipeSummaryTable in a new <TableContainer><Table><TableBody>
      //  vvvvv
      // percentStr = trimZeroesDecimalStr(percentStr)
      percentDisplay = (percentStr !== '' ? `${percentStr}%` : '')
    }
    let altUnitDisplay = ''
    if (Object.hasOwn(ingredient, 'altUnit') && ingredient.altUnit !== ingredient.unit && ingredient.altUnitAmount > 0) {
      altUnitDisplay = `(${ingredient.altUnitAmount} ${getUnitLabel(ingredient.altUnit)})`
    }
    return (
      <TableRow key={`${ingredient.name}.${ix}`}>
        <RecipeIngredientTableCell>{percentDisplay} {ingredient.name} {altUnitDisplay}</RecipeIngredientTableCell>
        {ingredient.weight > 0 ?
           (<RecipeIngredientTableCell align={'right'}>{ingredient.weight}{getUnitLabel(ingredient.unit)}</RecipeIngredientTableCell>)
                : null
        }
      </TableRow>
    )
  }

  function renderTotalRow(ingredientList) {
    if (generateTotals && ingredientList.length > 1) {
      let totalWeight = 0
      ingredientList.forEach(ig => {
        totalWeight += ig.weight
      })
      return (totalWeight > 0 ? renderRow({id: 'total', name: 'Total', weight: totalWeight}) : null)
    } else {
      return null
    }
  }

  return (
    <Grid item container direction={'row'} spacing={1} columns={{xs: 4, sm: 8, md: 12}}>
      <Grid item xs={3.5} sm={5} md={6}>
      <TableContainer>
        <Table size={"small"}>
        <TableBody>
          {
            ingredientList.map((ig, ix) => (
              renderRow(ig, ix)
            ))
          }
          {
            renderTotalRow(ingredientList)
          }
        </TableBody>
        </Table>
      </TableContainer>
      </Grid>
    </Grid>
    )
}

RecipeIngredientTable.propTypes = {
  ingredientList: PropTypes.arrayOf(PropTypes.shape({
    percent: PropTypes.number,
    name: PropTypes.string,
    altUnitAmount: PropTypes.number,
    altUnit: PropTypes.string,
    weight: PropTypes.number,
    unit: PropTypes.string
  }))
}

export { RecipeIngredientTable }