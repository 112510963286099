// make this 50 minutes in prod
import {toFixedDecimal} from "./NumberUtils";

export const renewAccessTokenInterval = 1000 * 60 * 50

const guestUser = 'Guest';
const unknownUser = 'Logged In User';

export function formatToken(token) {
  if (!token || token.length < 14) {
    return ''
  }
  return `${token.substring(0,7)}..${token.substring(token.length-7)}`
}

export function formatTokenExpiration(expiresIn) {
  if (!expiresIn) {
    return 'n/a'
  }
  return `${toFixedDecimal(expiresIn / 60, 2)} minutes`
}

export function extractUsername(auth) {
  if (!auth?.isAuthenticated) {
    return guestUser;
  }
  for (const key of Object.keys(auth?.user?.profile)) {
    if (key.toLowerCase().includes('username')) {
      return auth?.user?.profile[key];
    }
  }
  return unknownUser;
}
