import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {useLocation} from 'react-router-dom';
import {Container, useTheme} from '@mui/system';
import {Grid, Tooltip, useMediaQuery} from '@mui/material';
import {StyledNavLink} from "./StyledNavLink";
import {DrawerMenu} from "./DrawerMenu";
import {urlLevain, urlWeightDough, urlRatioDough} from "../../App";
import PercentIcon from "@mui/icons-material/Percent";
import NumbersIcon from "@mui/icons-material/Numbers";
import CalculateIcon from '@mui/icons-material/Calculate';
import SaveIcon from "@mui/icons-material/Save";
import {useAuth} from "react-oidc-context";
import {LoggedInUserIcon} from "../icon/LoggedInUserIcon";
import {GuestUserIcon} from "../icon/GuestUserIcon";
import {extractUsername} from "../utils/AuthUtils";



function MainMenu(props) {
  const currentLocation = useLocation()
  const theme = useTheme();
  const auth = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
      <AppBar position='static'>
        <Container maxWidth={'lg'}>
        <Toolbar>
          {isMobile ? (<DrawerMenu />)
          : (
          <Grid container direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={{ xs: 1.5, sm: 1.5, md: 1.5}} >
            <Grid item xs={1} sm={1.5} md={1.5}>
              <StyledNavLink className={`${currentLocation.pathname === '/' ? 'active' : undefined}`} to={'/'}>Home</StyledNavLink>
            </Grid>
            <Grid item xs={1} sm={1.5} md={1.5}>
              <StyledNavLink className={`${currentLocation.pathname === '/help' ? 'active' : undefined}`} to={'/help'}>Help</StyledNavLink>
            </Grid>
            <Grid item xs={1} sm={2} md={1.5}>
              <Tooltip title={'Bakers Percent Formula'} placement={'bottom'} arrow>
                <StyledNavLink className={`${currentLocation.pathname.startsWith(urlRatioDough) ? 'active' : undefined}`} to={urlRatioDough}><PercentIcon/> Bakers</StyledNavLink>
              </Tooltip>
            </Grid>
            <Grid item xs={1} sm={2} md={1.5}>
              <Tooltip title={'Weight Formula'} placement={'bottom'} arrow>
                <StyledNavLink className={`${currentLocation.pathname === urlWeightDough ? 'active' : undefined}`} to={urlWeightDough}><NumbersIcon/> Weight</StyledNavLink>
              </Tooltip>
            </Grid>
            <Grid item xs={1} sm={2} md={1.5}>
              <Tooltip title={'Levain Formula'} placement={'bottom'} arrow>
                <StyledNavLink className={`${currentLocation.pathname.startsWith(urlLevain) ? 'active' : undefined}`} to={urlLevain}><CalculateIcon/> Levain</StyledNavLink>
              </Tooltip>
              </Grid>
            <Grid item xs={1} sm={2} md={1.5}>
              <Tooltip title={'Saved Formulas'} placement={'bottom'} arrow>
                <StyledNavLink className={`${currentLocation.pathname === '/saved' ? 'active' : undefined}`} to={'/saved'}><SaveIcon/> Saved</StyledNavLink>
              </Tooltip>
            </Grid>
            <Grid item xs={1} sm={1} md={1.5}>
              <Tooltip title={`User (${extractUsername(auth)})`} placement={'bottom'} arrow>
                <StyledNavLink className={`${currentLocation.pathname === '/user' ? 'active' : undefined}`} to={'/user'}>
                  {auth.isAuthenticated ? (<LoggedInUserIcon/>) : (<GuestUserIcon/>)}
                </StyledNavLink>
              </Tooltip>
            </Grid>
          </Grid>
        )}
        </Toolbar>
        </Container>
      </AppBar>
  )
}

export default MainMenu;
