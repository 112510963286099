import {compressSync} from "fflate";
import {decompressSync} from "fflate";
import {
  compressToUTF16,
  decompressFromUTF16
} from "lz-string"

const encoder = new TextEncoder()
const decoder = new TextDecoder()

function dataToString(data) {
  let result = ''
  for (let value of data) {
    result += String.fromCharCode(data[value])
  }
  return result
}

function stringToData(str) {
  let result = new Uint8Array(str.length)
  for (let i = 0; i < str.length; i++) {
    result[i] = str.charCodeAt(i)
  }
  return result
}

// Verified, converts a JSON object to an LZ compressed string using Uint16 chars
// This is suitable for compressing JSON in Local Storage
// Note that dynamodb requires UTF-8 encoding if using a string, so
//  either (1) use GZ serialized for both Local Storage AND DDB, or (2) use LZ for Local and GZ for DDB
function compressJsonLz(jsonObject) {
  if (!jsonObject) {
    return jsonObject
  }

  const jsonString = JSON.stringify(jsonObject)
  return compressToUTF16(jsonString)
}

// Verified, converts an LZ compressed string of Uint16 chars back to a JSON object
// This is suitable for decompressing JSON in Local Storage
function decompressJsonLz(compressedJsonStr) {
  if (!compressedJsonStr || (typeof compressedJsonStr === 'string' && compressedJsonStr.charAt(0) === '{')) {
    // not compressed
    return JSON.parse(compressedJsonStr)
  }

  const jsonString = decompressFromUTF16(compressedJsonStr)
  return JSON.parse(jsonString)
}

// Verified, converts a JSON object to a gzip array of Uint8 numbers, then encodes that to a string.
// Consider using this for Local Storage, since it's a string which is required for local storage.
//  Currently, we do get better compression with LZ, but the tradeoff is using two different
//  compression techniques, one for local storage and the other for DDB
// On the other hand, LZ is much better compressed,  e.g. 2810 bytes raw: GZ to 1800 bytes, LZ to 944 bytes
function compressJsonGz(jsonObject) {
  const jsonString = JSON.stringify(jsonObject)
  const buffer = encoder.encode(jsonString)
  const gzipUint8Array = compressSync(buffer)
  // alternative if not serializing to a string:
  // const gzipSimpleArray = Array.from(gzipUint8Array)
  // return gzipSimpleArray
  return window.btoa(String.fromCharCode(...gzipUint8Array))
}

// Verified, decodes an encoded string into a gzip array of Uint8 numbers, then decompresses that to a JSON object.
function decompressJsonGz(gzipSerializedString) {
  if (!gzipSerializedString || (typeof gzipSerializedString === 'string' && gzipSerializedString.charAt(0) === '{')) {
    // not compressed!
    return JSON.parse(gzipSerializedString)
  }

  const uInt8Array = Uint8Array.from(window.atob(gzipSerializedString), (c) => c.charCodeAt(0))
  // alternative:
  // const uInt8Array = Uint8Array.from(gzipSimpleArray) // passed in as input
  const decompressed = decompressSync(uInt8Array)
  const jsonString = decoder.decode(decompressed)
  return JSON.parse(jsonString)
}

export {compressJsonLz, decompressJsonLz, compressJsonGz, decompressJsonGz}