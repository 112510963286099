import {useEffect, useState} from "react";
import {
  Accordion, AccordionDetails,
  AccordionSummary, Alert, FormControl, FormControlLabel, FormGroup,
  Grid,
  Paper, Snackbar, Switch, Tooltip,
  Typography
} from "@mui/material";
import {FormTextField} from "../shared/FormTextField";
import * as React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import {useNavigate, useParams} from "react-router";
import {SavedObjectDialog} from "../shared/SavedObjectDialog";
import {QuantityDoughFormDetail} from "./QuantityDoughFormDetail";
import {mainFormQuantityDefaults, prefermentQuantityDefaults} from "./QuantityDoughFormDefaults";
import {formTypeRatioDough, LEVAIN_PCT_TYPE_TOTAL_WT} from "../shared/Types";
import {DoughRecipeWrapper} from "../dough/DoughRecipeWrapper";
import {convertToRatioForm} from "./QuantityToRatioConverter";
import {urlQueryParamConverted, urlRatioDough, urlWeightDough} from "../../App";
import {getLocalDate} from "../utils/DateUtils";
import {getNewStateKey} from "../utils/FormStateUtils";
import {calculateQuantityRecipe} from "./QuantityDoughRecipeCalculator";
import IconButton from "@mui/material/IconButton";
import {helpWeightButtons} from "../help/PopupHelpContent";
import {ArrowDropDown, ArrowDropUp, HelpOutline} from "@mui/icons-material";
import {ContextualHelpDialog} from "../help/ContextualHelpDialog";
import AttributionIcon from "@mui/icons-material/Attribution";
import {AttributionDialog} from "../shared/AttributionDialog";
import ReplayIcon from '@mui/icons-material/Replay';
import {maxUnitsToBake, prefermentDefaults} from "../dough/DoughFormDefaults";
import {unitGrams} from "../shared/Units";
import {
  storageGet,
  storageHasFormulaName,
  storagePut
} from "../utils/StorageUtils";
import {useAuth} from "react-oidc-context";
import {FormulaLabelControl} from "../shared/FormulaLabelControl";
import {DoughProcess, processAutolyse} from "../shared/DoughProcess";
import {ProcessForm} from "../shared/ProcessForm";
import {applyLockStateEffect} from "../shared/WakeLockFactory";
import {
  clearSessionForm, doSaveSessionForm,
  getSessionForm,
  ratioSessionKey,
  saveSessionForm,
  weightSessionKey
} from "../utils/SessionManager";

function QuantityDoughForm(props) {
  const {wakeLock, setWakeLock} = props;
  const {formKey} = useParams()
  const [savedFormName, setSavedFormName] = useState(initSavedFormName())
  const [savedFormData, setSavedFormData] = useState(initSavedFormData())
  const [sessionFormData, setSessionFormData] = useState(initSessionFormData())
  const [formData, setFormData] = useState(initFormData())
  const [prefermentFormData, setPrefermentFormData] = useState(initPrefermentFormData())
  const [formNameError, setFormNameError] = useState(false)
  const [oldFormName, setOldFormName] = useState(undefined)
  const [recipeVisible, setRecipeVisible] = useState(false)
  const [recipeItems, setRecipeItems] = useState(undefined)
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true)
  const [convertToRatioBtnDisabled, setConvertToRatioBtnDisabled] = useState(true)
  const [openFormExistsDialog, setOpenFormExistsDialog] = useState(false)
  const [openAttributionDialog, setOpenAttributionDialog] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)
  const [formStateKey, setFormStateKey] = useState(getNewStateKey())
  const [openContextualHelpDialog, setOpenContextualHelpDialog] = useState(false)
  const [contextualHelpContent, setContextualHelpContent] = useState({title: undefined, content: undefined})
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [errorAlertMessage, setErrorAlertMessage] = useState('')
  const navigate = useNavigate()
  const auth = useAuth();

  useEffect(() => {
    applyLockStateEffect('QuantityDoughForm', recipeVisible, wakeLock, setWakeLock)
  }, [recipeVisible])

  function openHelp(title, content) {
    setContextualHelpContent({title: title, content: content})
    setOpenContextualHelpDialog(true)
  }

  function onCloseHelp() {
    setOpenContextualHelpDialog(false)
    setContextualHelpContent({title: undefined, content: undefined})
  }

  function initSavedFormName() {
    if (formKey === undefined) {
      return undefined
    }
    return window.atob(formKey)
  }

  function initSavedFormData() {
    if (savedFormName === undefined) {
      return undefined
    }
    // try local storage
    const storedForm = storageGet(savedFormName)
    return storedForm ?? undefined
  }

  function initSessionFormData() {
    if (!!savedFormData) {
      // no session data if saved data is present
      return undefined
    }
    // look in session data for this form
    // Note that session storage is populated either by:
    //   - a previous visit here
    //   - set specially by the "Convert to %" button in the Weight Formula
    // The latter case uses session storage to hold the converted form and should guarantee the form name
    // will be distinct if the Weight formula was already persisted.
    // When navigating here via the "Convert to %" button, the "?converted" query param is applied to distinguish that use case
    const weightSessionFormData = getSessionForm(weightSessionKey)
    return weightSessionFormData ?? undefined
  }

  function initFormData() {
    const savedOrSessionData = savedFormData ?? sessionFormData
    if (savedOrSessionData === undefined) {
      return mainFormQuantityDefaults
    }

    const updatedForm = migrateFormData(savedOrSessionData)
    // save updated form in session so navigating away and returning here will still present this one
    // to get the complete formula for the session save, apply preferment defaults here to limit session saves
    const mergedForm = {...updatedForm, prefermentData: (savedOrSessionData['includePreferment'] ?
              applyNewPrefermentFormDefaults(savedOrSessionData['prefermentData']) : undefined)}
    saveSessionForm(250, weightSessionKey, savedOrSessionData['formName'], mergedForm)
    return updatedForm
  }

  function migrateFormData(savedOrSessionData) {

    // exclude preferment from the savedOrSessionData to split into separately managed states: formData and prefermentFormData
    // this is mostly being done to omit the preferment structure in the persisted data to save space
    // these are, of course, merged when calculating the recipe
    const {prefermentData, ...remainder} = savedOrSessionData

    // add new and prune obsolete fields in main form...
    if (!Object.hasOwn(remainder, 'process')) {
      remainder['process'] = {...mainFormQuantityDefaults.process}
    }
    if (remainder['autolyse']) {
      remainder['process'][processAutolyse] = new DoughProcess(undefined, undefined, undefined, undefined)
    }
    if (Object.hasOwn(remainder, 'autolyse')) {
      delete remainder['autolyse']
    }
    // return only the main form for state initialization
    return applyNewMainFormDefaults(remainder)
  }

  function initPrefermentFormData() {
    const savedOrSessionData = savedFormData ?? sessionFormData
    if (savedOrSessionData === undefined) {
      return prefermentQuantityDefaults
    }
    return migratePrefermentFormData(savedOrSessionData)
  }

  function migratePrefermentFormData(savedOrSessionData) {
    if (savedOrSessionData['prefermentData'] === undefined) {
      return prefermentDefaults
    }
    return applyNewPrefermentFormDefaults(savedOrSessionData['prefermentData'])
  }

  function applyNewMainFormDefaults(storedForm) {
    Object.keys(mainFormQuantityDefaults).forEach(k => {
      if (storedForm[k] === undefined) {
        storedForm[k] = mainFormQuantityDefaults[k]
      }
      // in case of type rename
      storedForm.formType = mainFormQuantityDefaults.formType
    })
    return storedForm
  }

  function applyNewPrefermentFormDefaults(storedForm) {
    Object.keys(prefermentQuantityDefaults).forEach(k => {
      if (storedForm[k] === undefined) {
        storedForm[k] = prefermentQuantityDefaults[k]
      }
    })
    return storedForm
  }

  function updateFormData(formData) {
    setFormData(formData)
    setHasChanges(true)
  }

  function updatePrefermentData(prefermentData) {
    setPrefermentFormData(prefermentData)
    setHasChanges(true)
  }

  function hasCandidateNewName() {
    return  (!formData.hasOwnProperty('createdAt') || (savedFormName && (savedFormName !== formData.formName)));
  }

  function isSavedForm() {
    return formData.hasOwnProperty('createdAt')
  }

  function onIncludePrefermentChange(evt) {
    const includePreferment = evt.target.checked

    updateFormData({
      ...formData,
      includePreferment: includePreferment,
      starterType: LEVAIN_PCT_TYPE_TOTAL_WT,
      starterAmount: (includePreferment ? 0 : mainFormQuantityDefaults.starterAmount),
      starterUnit: unitGrams,
      yeastAmount: (includePreferment ? 0 : mainFormQuantityDefaults.yeastAmount),
      yeastUnit: unitGrams
    })
  }

  function onFormNameFieldChange(evt) {
    if (evt.target.validity.valid) {
      setFormNameError(false)
    } else {
      setFormNameError(true)
    }
    updateFormData({
      ...formData,
      [evt.target.name]: evt.target.value
    })
  }

  function onLabelsAdd(labelsToAdd) {
    if (labelsToAdd?.length > 0) {
      const newLabels = [...formData.labels]
      newLabels.push.apply(newLabels, labelsToAdd)
      updateFormData({
        ...formData,
        labels: newLabels
      })
    }
  }

  function onLabelRemove(deleteLabel) {
    updateFormData({
      ...formData,
      labels: formData.labels.filter(label => label !== deleteLabel)
    })
  }

  function onNumericFieldChange(evt) {
    let intVal = parseInt(evt.target.value)
    if (isNaN(intVal)) {
      intVal = 0
    }
    if (evt.target.name === 'numBatches' && intVal >= maxUnitsToBake) {
      intVal = maxUnitsToBake
    }
    if (evt.target.name === 'numBatches' && intVal < 1) {
      intVal = 1
    }
    updateFormData({
      ...formData,
      [evt.target.name]: intVal
    })
  }

  function recipeCloseRequest() {
    setRecipeVisible(false)
  }

  function clickAttribution() {
    setOpenAttributionDialog(true)
  }

  function applyAttribution(attributionText) {
    updateFormData({...formData, attribution: attributionText})
  }

  function incrementBatches() {
    const currVal = formData.numBatches
    if (currVal >= maxUnitsToBake) {
      return
    }
    updateFormData({...formData, numBatches: currVal + 1})
  }

  function decrementBatches() {
    const currVal = formData.numBatches
    if (currVal === 1) {
      return
    }
    updateFormData({...formData, numBatches: currVal - 1})
  }

  function applyForm() {
    clearSessionForm(weightSessionKey)
    // clear savedData reference to force reliance on session cache...
    // ...this would be overridden by Save, Revert or Clear button actions
    setSavedFormData(undefined)
    setSessionFormData(undefined)
    const mergedFormData = {...formData, prefermentData: (formData.includePreferment ? prefermentFormData : undefined)}
    setRecipeItems(calculateQuantityRecipe(mergedFormData))
    setRecipeVisible(true)
    setSaveBtnDisabled(false)
    setConvertToRatioBtnDisabled(false)
    doSaveSessionForm(weightSessionKey, formData.formName, mergedFormData)
  }

  function loadSavedForm() {
    const formName = formData.formName
    const storedForm = storageGet(formName)
    if (!storedForm) {
      console.error(`loadSavedForm: no saved form found for '${formName}', ignoring.`)
      return;
    }

    clearSessionForm(weightSessionKey)
    const revertedFormData = migrateFormData(storedForm)
    const revertedPrefermentFormData = migratePrefermentFormData(storedForm)

    setFormNameError(false)
    setRecipeItems(undefined)
    setRecipeVisible(false)
    setSaveBtnDisabled(true)
    setFormStateKey(getNewStateKey())
    setHasChanges(false)
    setFormData(revertedFormData)
    setPrefermentFormData(revertedPrefermentFormData)

    // save reverted form in session so navigating away and returning here will still present this one
    const mergedForm = {...revertedFormData, prefermentData: (revertedFormData['includePreferment'] ?
              revertedPrefermentFormData : undefined)}
    doSaveSessionForm(weightSessionKey, formName, mergedForm)
  }

  function resetForm() {
    clearSessionForm(weightSessionKey)
    setSavedFormName(undefined)
    setSavedFormData(undefined)
    setSessionFormData(undefined)
    setFormData(mainFormQuantityDefaults)
    setPrefermentFormData(prefermentQuantityDefaults)
    setFormNameError(false)
    setRecipeItems(undefined)
    setRecipeVisible(false)
    setSaveBtnDisabled(true)
    setConvertToRatioBtnDisabled(true)
    setFormStateKey(getNewStateKey())
    setHasChanges(false)
    navigate(urlWeightDough)
  }

  function handleSaveClick() {
    saveForm(formData.formName, false)
  }

  function setNewFormName(newFormName) {
    updateFormData({...formData, formName: newFormName})
  }

  function applyNewFormName(newFormName) {
    saveForm(newFormName, true)
  }

  // save quantity form as currently rendered
  function saveForm(formName, forceSave = false) {
    let formNameTrimmed = formName.trim()
    if (!forceSave && hasCandidateNewName() && storageHasFormulaName(formNameTrimmed)) {
      setOldFormName(formData.formName)
      setOpenFormExistsDialog(true)
      return
    }
    let timeStampField = 'createdAt'
    if (formData.hasOwnProperty(timeStampField)) {
      timeStampField = 'modifiedAt'
    }
    // this component tracks state of the main formData separately from the prefermentData, thus the two separate objects here
    const updatedFormData = {
      ...formData,
      formName: formNameTrimmed,
      [timeStampField]: getLocalDate().getTime()
    }
    // mergedFormData is complete formula including prefermentData for persisting
    const mergedFormData = {...updatedFormData, prefermentData: (formData.includePreferment ? prefermentFormData : undefined)}

    // update session before the subsequent state changes induce re-rendering!
    doSaveSessionForm(weightSessionKey, formNameTrimmed, mergedFormData)
    // update local storage
    handleStoragePutError(storagePut(formNameTrimmed, mergedFormData, auth))

    setFormData(updatedFormData)
    setSavedFormData(mergedFormData)
    setRecipeItems(calculateQuantityRecipe(mergedFormData))
    setSaveBtnDisabled(true)
    setHasChanges(false)
    setOldFormName(undefined)
    setSavedFormName(formNameTrimmed)
  }

  async function handleStoragePutError(storagePromise) {
    storagePromise.then((result) => {
      // console.log(`storagePut for ${formData.formName} completed: ${result}`)
    })
    .catch((e) => {
      console.error(`storagePut for ${formData.formName} completed in error: ${e}`)
      setErrorAlertMessage(`Error saving ${formData.formName} to account backend. Do a manual Sync on Saved Items menu option.`);
      setShowErrorAlert(true)
    })
  }

  // Convert to Ratio button
  function handleConvertToRatioClick() {
    convertToRation()
  }

  function convertToRation() {
    const convertedFormName = `${formData.formName} (converted)`;
    const mergedFormData = {...formData,
      formName: convertedFormName,
      formType: formTypeRatioDough,
      prefermentData: (formData.includePreferment ? prefermentFormData : undefined)}
    const ratioFormData = convertToRatioForm(mergedFormData)
    // save using ratio session key and navigate to the ratio formula screen
    doSaveSessionForm(ratioSessionKey, convertedFormName, ratioFormData)
    const ratioConvertUrl = `${urlRatioDough}/${window.btoa(convertedFormName)}?${urlQueryParamConverted}`
    navigate(ratioConvertUrl)
  }
  
  function dismissErrorAlert(event, reason) {
    setShowErrorAlert(false)
    setErrorAlertMessage('')
  }

  return (
      <div key={formStateKey}>
        <Grid container direction={'column'}>
          <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} autoHideDuration={3000} open={showErrorAlert} onClose={dismissErrorAlert}>
            <Alert sx={{marginTop: '1rem'}} severity={'error'} onClose={dismissErrorAlert}>{errorAlertMessage}</Alert>
          </Snackbar>
        </Grid>
        <Grid container direction={'row'} paddingLeft={{xs: '5%', sm: '8%', md: '8%', lg: '20%'}} paddingRight={{xs: '5%', sm: '8%', md: '8%', lg: '20%'}} rowSpacing={1.25} columns={{xs: 4, sm: 8, md: 12}}>
          <Grid item container direction={'column'} marginTop={1.5} alignItems={'center'}>
            <Typography variant={'h5'}>Fixed Weight Formula</Typography>
          </Grid>
          <Grid item container direction={'column'} justifyContent={'center'} paddingLeft={{xs: 0, sm: 0, md: 8}} paddingRight={{xs: 0, sm: 0, md: 8}}>
            <Grid item container direction={'row'} columns={{xs: 4, sm: 8, md: 12}}>
              <Grid item xs={3.5} sm={7.5} md={11.5}>
                <FormTextField sx={{width: '100%', paddingLeft: 0.5}} required
                      id="outlined-required"
                      disabled={isSavedForm()}
                      size={'small'}
                      name={'formName'}
                      label="Name"
                      value={formData.formName}
                      onChange={onFormNameFieldChange}
                      error={formNameError}
                      helperText={
                        formNameError ? 'Formula name is required' : ''
                      }
                      variant="filled"/>
              </Grid>
              <Grid item xs={0.5} sm={0.5} md={0.5}>
                <Tooltip title={'Attribution'} placement={'bottom'} arrow>
                  <IconButton onClick={clickAttribution}><AttributionIcon sx={{marginTop: 0.5, paddingLeft: 0.5}} color={'primary'}/></IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container direction={'row'} columns={{xs: 4, sm: 8, md: 12}} justifyContent={'center'} alignContent={'center'}>
            <Grid item xs={1.5} sm={2.25} md={2.75}>
              <FormTextField size={'small'}
                             sx={{width: '8ch'}}
                             id="batches-select"
                             label={'Bake Units'}
                             name={'numBatches'} variant={'filled'}
                             value={formData.numBatches}
                             onChange={onNumericFieldChange}>
              </FormTextField>
              <FormControl>
                <IconButton onClick={incrementBatches} sx={{padding: 0, marginTop: -0.3}} color={"primary"}><ArrowDropUp fontSize={'large'}/></IconButton>
                <IconButton onClick={decrementBatches} sx={{padding: 0, marginTop: -1.5}} color={"primary"}><ArrowDropDown fontSize={'large'}/></IconButton>
              </FormControl>
            </Grid>
          </Grid>

          <FormulaLabelControl existingFormLabels={formData.labels} onLabelsAdd={onLabelsAdd} onLabelRemove={onLabelRemove}/>

          <Grid item container direction={'column'} alignItems={'flex-start'} marginBottom={1}>
            <FormGroup row>
              <FormControlLabel control={<Switch name={'includePreferment'} checked={formData.includePreferment}
                                                 onChange={onIncludePrefermentChange}/>}
                                label={<Typography variant={'subtitle2'}>Add Preferment Formula</Typography>}/>
            </FormGroup>
          </Grid>
          {formData.includePreferment ?
              (
                <Grid item container direction={'column'}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header">
                      <Typography>Preferment</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <QuantityDoughFormDetail visible={true} isPrefermentPanel={true} formData={prefermentFormData}
                                               setFormData={updatePrefermentData}/>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ) : null
          }
          <Grid item container direction={'column'}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                <Typography>Main Dough</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <QuantityDoughFormDetail visible={true} isPrefermentPanel={false} formData={formData} setFormData={updateFormData}/>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item container direction={'column'}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                <Typography>Process & Notes</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ProcessForm formData={formData} setFormData={updateFormData}/>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item container spacing={2} direction={'row'} justifyContent={'center'} columns={{xs: 4, sm: 8, md: 12}}>
            {isSavedForm() ?
                (
                <Grid item>
                  <Tooltip title={'Revert to Saved copy'} placement={'bottom'} arrow>
                  <span>
                    <IconButton sx={{marginRight: -1}} onClick={loadSavedForm} disabled={!hasChanges || formNameError}><ReplayIcon fontSize={'medium'} color={(!hasChanges || formNameError ? 'grey': 'primary')}/></IconButton>
                  </span>
                  </Tooltip>
                </Grid>
              ) : null
            }
            <Grid item>
              <Button variant={'contained'} onClick={applyForm} disabled={formNameError}>Apply</Button>
            </Grid>
            <Grid item>
              <Button color={"secondary"} variant={'outlined'} onClick={resetForm}>Clear</Button>
            </Grid>
            <Grid item>
              <Button color={"secondary"} variant={'outlined'} disabled={saveBtnDisabled}
                      onClick={handleSaveClick}>Save</Button>
            </Grid>
            <Grid item container justifyContent={'center'} direction={'row'} columns={{xs: 4, sm: 8, md: 12}}>
            <Grid item>
              <Button color={"secondary"} variant={'outlined'} disabled={convertToRatioBtnDisabled}
                      onClick={handleConvertToRatioClick}>Convert To %</Button>
            </Grid>
            <Grid item>
              <IconButton color={'primary'} onClick={() => openHelp(undefined, helpWeightButtons)}><HelpOutline fontSize={'small'} /></IconButton>
            </Grid>
            </Grid>
          </Grid>
          <Grid item container direction={'column'} justifyContent={'center'} columns={{xs: 4, sm: 8, md: 12}}>
            <Paper elevation={3} sx={{marginBottom: '1.5rem'}}>
              <DoughRecipeWrapper items={recipeItems} visible={recipeVisible} closeRequest={recipeCloseRequest}/>
            </Paper>
          </Grid>
        </Grid>
        <SavedObjectDialog key={'qformName'} objectName={formData.formName}
                           setNewObjectName={setNewFormName}
                           apply={applyNewFormName}
                           existingFormName={oldFormName}
                           dialogOpen={openFormExistsDialog}
                           setDialogOpen={setOpenFormExistsDialog}
                           objectType={'Formula'} />
        <AttributionDialog attributionText={formData.attribution} setNewAttributionText={applyAttribution} dialogOpen={openAttributionDialog} setDialogOpen={setOpenAttributionDialog}/>
        <ContextualHelpDialog isOpen={openContextualHelpDialog} close={onCloseHelp} title={contextualHelpContent.title} content={contextualHelpContent.content}/>
      </div>
  )


}

export {QuantityDoughForm}
