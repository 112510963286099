import {Dialog, DialogContent, DialogTitle, Typography} from "@mui/material";


function ContextualHelpDialog(props) {
  const {isOpen, close, title, content} = props

  function renderParagraph(paragraph, pIx) {
    const lineArray = paragraph.split('\n')

    return (
      <div key={pIx}>
        {lineArray.map((l, lIx) => {
          return renderLine(l, pIx, lIx)
        })}
        <p/>
      </div>
    )
  }

  function renderLine(line, pIx, lIx) {
    return (
        <Typography key={`${pIx}.${lIx}`} sx={{fontSize: 12, marginRight: 2}}>
          {line}
        </Typography>
    )
  }

  if (content === undefined) {
    return null
  }

  const paragraphPattern = /\n\n/
  const paragraphArray = content.split(paragraphPattern)

  return (
    <Dialog open={isOpen} onClose={close}>
    {title !== undefined ?
        (<DialogTitle sx={{fontSize: 14}}>
          {title}
        </DialogTitle>)
        : null
    }

    <DialogContent>
      {paragraphArray.map((p, ix) => {
        return renderParagraph(p, ix)
      })}
    </DialogContent>
  </Dialog>
  )
}

export {ContextualHelpDialog}