import * as React from 'react';
import './App.css';
import MainMenu from "./components/menu/MainMenu";
import {createTheme, ThemeProvider} from "@mui/material";
import {amber, blue, grey, red, yellow} from "@mui/material/colors";
import {BrowserRouter} from "react-router-dom";
import {DoughRecipePrintWrapper} from "./components/dough/DoughRecipePrintWrapper";
import {LevainRecipePrintWrapper} from "./components/levain/LevainRecipePrintWrapper";
import {PRINT_DOUGH_URI, PRINT_LEVAIN_URI} from "./components/utils/RoutingUtils";
import {formTypeLevain, formTypeWeightDough, formTypeRatioDough} from "./components/shared/Types";
import {useAuth} from "react-oidc-context";
import {useEffect} from "react";
import {formatToken, renewAccessTokenInterval} from "./components/utils/AuthUtils";
import AppRoutes from "./AppRoutes";

const theme = createTheme({
  palette: {
    primary: {
      main: amber[500]
    },
    secondary: {
      main: yellow[400]
    },
    attention: {
      main: red[500]
    },
    water: {
      main: blue[300]
    },
    dark: {
      main: grey[800]
    }
  }
})

// this supports un-themed print page rendering...
//   keep this in sync with the actual theme palette!
export const THEME_PRIMARY_COLOR = '#ffc107'

export const urlRatioDough = '/ratio'
export const urlWeightDough = '/weight'
export const urlLevain = '/levain'
export const urlQueryParamConverted = 'converted'
export const formTypeUrlMap = [
  {
    formType: formTypeRatioDough,
    url: urlRatioDough
  },
  {
    formType: formTypeWeightDough,
    url: urlWeightDough
  },
  {
    formType: formTypeLevain,
    url: urlLevain
  }
]

function App(props) {
  const auth = useAuth();

  useEffect(() => {
    let intervalRef;
    if (auth.isAuthenticated) {
      // console.log(`App.useEffect: detected isAuthenticated, starting refresh interval...`)
      intervalRef = setInterval(() => {
        auth.signinSilent().then(user => {
          console.info(`App.useEffect.signinSilent got access_token: ${formatToken(user.access_token)}...`)
        });
      }, renewAccessTokenInterval)
    }
    return () => {
      clearInterval(intervalRef);
      // console.log(`App.useEffect stopped refresh interval.`)
    }
  });

const currentHref = window.location.href

  // bypass router encapsulated components to single out only the component of interest for printing
  if (currentHref.match(`.*${PRINT_DOUGH_URI}`)) {
    return (
        <div>
          <DoughRecipePrintWrapper/>
        </div>
    )
  } else if (currentHref.match(`.*${PRINT_LEVAIN_URI}`)) {
    return (
        <div>
          <LevainRecipePrintWrapper/>
        </div>
    )
  }

  return (
      <ThemeProvider theme={theme}>
        <div className="App">
          <header className="App-content">
            <BrowserRouter>
              <MainMenu/>
              <AppRoutes/>
            </BrowserRouter>
          </header>
        </div>
      </ThemeProvider>
  );
}

export default App;
