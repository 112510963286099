import React from 'react'
import './index.css'
import App from './App'
import {createRoot} from "react-dom/client";
import {AuthProvider} from "react-oidc-context";

//TODO: uncomment this for production builds:
// console.log = function() {}

// Note: these attributes are visible in the cognito network calls from the browser
//  ... if this becomes a problem, e.g. bots, etc., then introduce a pre-auth and/or pre-signup
//  lambda trigger as an extension to the app client config in cognito
const oidcProviderConfig = {
  authority: "https://cognito-idp.us-east-2.amazonaws.com/us-east-2_CTymIgieh",
  client_id: "366fi9n4rv0n7hd7vhohq2hojh",
  redirect_uri: "https://formuladough.com/signin",
  response_type: "code",
  scope: "phone openid email"
};

const container = document.getElementById('app');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AuthProvider {...oidcProviderConfig}>
      <App/>
    </AuthProvider>
  </React.StrictMode>,
);
