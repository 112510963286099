// class to hold state for a WakeLockSentinel and helper functions to release and check status
export class WakeLockWrapper {
  constructor(wakeLockSentinel) {
    this.wakeLockSentinel = wakeLockSentinel;
  }

  isLocked() {
    if (!this.wakeLockSentinel) {
      console.error('WakeLockWrapper.isLocked(): no WakeLockSentinel is present!')
      return false;
    }
    return !this.wakeLockSentinel.released
  }

  release() {
    if (!this.wakeLockSentinel) {
      console.error('WakeLockWrapper.release(): no WakeLockSentinel is present!')
      return ;
    }
    Promise.resolve(this.wakeLockSentinel).then(wls => wls.release());
  }
}

// returns a WakeLockWrapper instance
export async function requestWakeLock() {
  if (!('wakeLock' in navigator)) {
    console.warn('lockWakeState: not supported, ignoring.')
    return undefined;
  }
  try {
    let wakeLock = await navigator.wakeLock.request('screen');
    wakeLock.addEventListener('release', () => {
      // console.log('wakeLock release event');
    });
    return new WakeLockWrapper(wakeLock);
  } catch(e) {
    console.error('Failed to lock wake state with reason:', e.message);
    return undefined;
  }
}

export function applyLockStateEffect(componentName, newLockState, currentWakeLock, setNewWakeLock) {
  if (!newLockState) {
    if (currentWakeLock) {
      // console.log(`${componentName} effect is releasing wakeLock`)
      currentWakeLock.release();
      setNewWakeLock(undefined)
    }
    return;
  }
  currentWakeLock?.release();
  // console.log(`${componentName} effect is creating wakeLock`)
  requestWakeLock().then(wl => setNewWakeLock(wl));
}
