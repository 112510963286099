import * as React from 'react';
import './App.css';
import {Home} from "./components/home/Home";
import {SavedItems} from "./components/saved/SavedItems";
import {DoughForm} from "./components/dough/DoughForm";
import {LevainForm} from "./components/levain/LevainForm";
import {DoughRecipePrintWrapper} from "./components/dough/DoughRecipePrintWrapper";
import {LevainRecipePrintWrapper} from "./components/levain/LevainRecipePrintWrapper";
import {PRINT_DOUGH_URI, PRINT_LEVAIN_URI} from "./components/utils/RoutingUtils";
import {QuantityDoughForm} from "./components/quantity/QuantityDoughForm";
import {Help} from "./components/help/Help";
import {UserProfile} from "./components/user/UserProfile";
import {useEffect} from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import {urlLevain, urlRatioDough, urlWeightDough} from "./App";

function AppRoutes(props) {
  const location = useLocation();
  const [wakeLockWrapper, setWakeLockWrapper] = React.useState(undefined);

  useEffect(() => {
    if (wakeLockWrapper !== undefined) {
      // console.log('AppRoutes effect: releasing wakeLock')
      wakeLockWrapper.release();
      setWakeLockWrapper(undefined);
    }
  }, [location]);


  return (
      <Routes>
        <Route path={'/'} element={<Home/>} />
        <Route path={'/help'} element={<Help/>} />
        <Route path={`${urlRatioDough}/:formKey`} element={<DoughForm wakeLock={wakeLockWrapper} setWakeLock={setWakeLockWrapper}/>} />
        <Route path={`${urlRatioDough}`} element={<DoughForm wakeLock={wakeLockWrapper} setWakeLock={setWakeLockWrapper}/>} />
        <Route path={`${urlWeightDough}/:formKey`} element={<QuantityDoughForm wakeLock={wakeLockWrapper} setWakeLock={setWakeLockWrapper}/>} />
        <Route path={`${urlWeightDough}`} element={<QuantityDoughForm wakeLock={wakeLockWrapper} setWakeLock={setWakeLockWrapper}/>} />
        <Route path={`${urlLevain}/:formKey`} element={<LevainForm wakeLock={wakeLockWrapper} setWakeLock={setWakeLockWrapper}/>} />
        <Route path={`${urlLevain}`} element={<LevainForm wakeLock={wakeLockWrapper} setWakeLock={setWakeLockWrapper}/>} />
        <Route path={'/saved'} element={<SavedItems/>} />
        <Route path={`${PRINT_DOUGH_URI}`} element={<DoughRecipePrintWrapper/>} />
        <Route path={`${PRINT_LEVAIN_URI}`} element={<LevainRecipePrintWrapper/>} />
        <Route path={'/user'} element={<UserProfile/>} />
        <Route path={'/signin'} element={<UserProfile/>} />
      </Routes>
  );
}

export default AppRoutes;
