import {SvgIcon} from "@mui/material";

export function SortAscendingIcon() {
  return (
      <SvgIcon>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
             width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
             preserveAspectRatio="xMidYMid meet">
          <metadata>
            Created by potrace 1.16, written by Peter Selinger 2001-2019
          </metadata>
          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
             fill="#000000" stroke="none">
            <path d="M2055 4044 c-84 -31 -135 -108 -135 -204 0 -100 58 -181 149 -209 53
-16 929 -16 982 0 91 28 149 109 149 209 0 98 -51 174 -139 205 -63 22 -945
21 -1006 -1z"/>
            <path d="M1415 2764 c-84 -31 -135 -108 -135 -204 0 -100 58 -181 149 -209 54
-16 2208 -16 2262 0 91 28 149 109 149 209 0 98 -51 174 -139 205 -64 22
-2224 22 -2286 -1z"/>
            <path d="M775 1484 c-84 -31 -135 -108 -135 -204 0 -100 58 -181 149 -209 55
-16 3487 -16 3542 0 21 7 54 25 74 41 99 82 103 240 8 330 -65 62 51 58 -1857
57 -1497 0 -1747 -2 -1781 -15z"/>
          </g>
        </svg>
      </SvgIcon>
  )
}


