import {
  ingredientDryYeast,
  ingredientFlour,
  ingredientLevain,
  ingredientUnknown,
  ingredientWater,
  LEVAIN_PCT_TYPE_TOTAL_WT,
  noPreferment
} from "../shared/Types";
import {getAutolyseLiquidPct, getUnitLabel, toGrams, unitGrams, unitTeaspoon} from "../shared/Units";
import {toFixedDecimal, toFixedWholePercent,} from "../utils/NumberUtils";
import {yeastDefs} from "../shared/Yeast";
import * as Types from "../shared/Types";
import {convertToRatioForm} from "./QuantityToRatioConverter";
import {findObjectForFieldValue} from "../utils/ArrayUtils";
import {processAutolyse} from "../shared/DoughProcess";

const quantityRecipeItems = {
  formName: '',
  formType: '',
  attribution: '',
  createdAt: '',
  modifiedAt: '',
  notes: '',
  numBatches: 1,
  batchSize: 0,
  batchType: Types.BATCH_TYPE_TOTAL_WT,
  includePreferment: false,
  recipeFlourTotalWeight: 0,
  effectiveRecipeFlourTotalWeight: 0,
  effectiveRecipeHydrationPct: 0,
  recipeLiquidTotalWeight: 0,
  recipeYeastTotalWeight: 0,
  recipeStarterTotalWeight: 0,
  recipeMixinTotalWeight: 0,
  recipeSoakerTotalWeight: 0,
  recipeUnitWeight: 0,
  recipeTotalWeight: 0,
  mainDough: {
    hydrationPct: 0,
    hydrationPctFraction: 0,
    process: {},
    prefermentPffPct: 0,
    prefermentPffPctFraction: 0,
    starterType: LEVAIN_PCT_TYPE_TOTAL_WT,
    starterPct: 0,
    starterPctFraction: 0,
    starterHydrationPct: 0,
    starterHydrationPctFraction: 0,
    yeast: {
      flourUnitGrams: yeastDefs.flourUnitGrams,
      ratioId: 0,
      percent: 0,
      weight: 0,
      altUnit: unitTeaspoon,
      altUnitAmount: 0,
      volumeDisplayLabel: ''
    },
    starterWeight: 0,
    starterAltUnit: unitGrams,
    starterAltUnitAmount: 0,
    flourWeight: 0,
    flour: [],
    liquid: [],
    mixinInput: [],
    mixin: [],
    soakerInput: [],
    soaker: [],
    toppingsInput: [],
    toppings: [],
    totalWeight: 0,
    prefermentByWeightPct: 0
  },
  prefermentDough: {
    prefermentType: noPreferment,
    hydrationPct: 0,
    hydrationPctFraction: 0,
    levainFermPct: 0,
    levainFermTempF: '0',
    levainFermTimeStr: '',
    starterType: LEVAIN_PCT_TYPE_TOTAL_WT,
    starterPct: 0,
    starterPctFraction: 0,
    starterHydrationPct: 0,
    starterHydrationPctFraction: 0,
    yeast: {
      flourUnitGrams: yeastDefs.flourUnitGrams,
      ratioId: 0,
      percent: 0,
      weight: 0,
      altUnit: unitTeaspoon,
      altUnitAmount: 0,
      volumeDisplayLabel: ''
    },
    starterWeight: 0,
    starterAltUnit: unitGrams,
    starterAltUnitAmount: 0,
    addBuffer: false,
    flourWeight: 0,
    flour: [],
    liquid: [],
    totalWeight: 0
  }
}

function calculateQuantityRecipe(inputData) {
  // get basic percentages to be applied in output items
  const ratioItems = convertToRatioForm(inputData)
  
  // initialize the recipe items
  let items = JSON.parse(JSON.stringify(quantityRecipeItems))
  items.formName = inputData.formName
  items.formType = inputData.formType
  items.numBatches = inputData.numBatches
  items.attribution = inputData.attribution
  items.createdAt = inputData.createdAt
  items.modifiedAt = inputData.modifiedAt
  items.notes = inputData.notes
  items.includePreferment = inputData.includePreferment
  // alt units...
  items.mainDough.starterAltUnit = inputData.starterUnit
  items.mainDough.starterAltUnitAmount = inputData.starterAmount
  items.mainDough.process = inputData.process ?? {}

  // add alt unit and amount to ratio-calculated flours and push to recipe
  let mainDoughFlourWeight = 0
  ratioItems.flour.forEach((fd, ix) => {
    const inputFlour = findObjectForFieldValue(inputData.flour, 'id', fd.id)
    const flourWeight = Math.round(toGrams(inputFlour.amount, inputFlour.unit, ingredientFlour))
    mainDoughFlourWeight += flourWeight
    const flourItem = {...fd, weight: flourWeight, group: 'Flour', altUnit: inputFlour.unit, altUnitAmount: inputFlour.amount}
    items.mainDough.flour.push(flourItem)
  })

  // add alt unit and amount to ratio-calculated liquids and push to recipe
  let mainDoughLiquidWeight = 0
  ratioItems.liquid.forEach((ld, ix) => {
    const inputLiquid = findObjectForFieldValue(inputData.liquid, 'id', ld.id)
    const liquidWeight = Math.round(toGrams(inputLiquid.amount, inputLiquid.unit, ingredientWater))
    mainDoughLiquidWeight += liquidWeight
    const liquidItem = {...ld, weight: liquidWeight, group: 'Liquid', altUnit: inputLiquid.unit, altUnitAmount: inputLiquid.amount}
    items.mainDough.liquid.push(liquidItem)
  })

  // add alt unit and amount to ratio-calculated mixins and push to recipe
  let recipeMixinWeight = 0
  ratioItems.mixin.forEach((md, ix) => {
    const inputMixin = findObjectForFieldValue(inputData.mixin, 'id', md.id)
    const mixinWeight = Math.round(toGrams(inputMixin.amount, inputMixin.unit, ingredientUnknown))
    recipeMixinWeight += mixinWeight
    items.mainDough.mixin.push({...md, weight: mixinWeight, altUnit: inputMixin.unit, altUnitAmount: inputMixin.amount})
  })

  let recipeSoakerWeight = 0
  ratioItems.soaker.forEach((sd, ix) => {
    const inputSoaker = findObjectForFieldValue(inputData.soaker, 'id', sd.id)
    const soakerWeight = Math.round(toGrams(inputSoaker.amount, inputSoaker.unit, ingredientUnknown))
    recipeSoakerWeight += soakerWeight
    items.mainDough.soaker.push({...sd, weight: soakerWeight, altUnit: inputSoaker.unit, altUnitAmount: inputSoaker.amount})
  })

  // toppings is just a category of mixin but weight isn't included
  ratioItems.toppings.forEach((td, ix) => {
    const inputTopping = findObjectForFieldValue(inputData.toppings, 'id', td.id)
    items.mainDough.toppings.push({...td, weight: 0, group: 'Toppings', altUnit: inputTopping.unit, altUnitAmount: inputTopping.amount})
  })

  // main dough percentages and weights...
  items.mainDough.hydrationPct = ratioItems.hydrationPct
  items.mainDough.starterPct = ratioItems.starterPct
  items.mainDough.starterHydrationPct = ratioItems.starterHydrationPct
  items.mainDough.yeast = calculateYeastForQuantity(mainDoughFlourWeight, inputData.yeastAmount, inputData.yeastUnit)
  items.mainDough.yeast.ratioId = ratioItems.yeastRatioId
  // not setting starterFlourWeight and starterWaterWeight since Quantity form is by levain total weight only
  items.mainDough.starterWeight = Math.round(toGrams(inputData.starterAmount, inputData.starterUnit, ingredientLevain))
  items.mainDough.flourWeight = mainDoughFlourWeight
  items.mainDough.totalWeight = Math.round(mainDoughFlourWeight + mainDoughLiquidWeight + recipeMixinWeight + recipeSoakerWeight + items.mainDough.starterWeight + items.mainDough.yeast.weight)

  let prefermentFlourWeight = 0
  let prefermentLiquidWeight = 0
  let prefermentYeastWeight = 0
  let prefermentStarterWeight = 0
  let prefermentTotalWeight = 0
  if (inputData.includePreferment) {
    items.prefermentDough.prefermentType = inputData.prefermentData.prefermentType
    // alt units...
    items.prefermentDough.starterAltUnit = inputData.prefermentData.starterUnit
    items.prefermentDough.starterAltUnitAmount = inputData.prefermentData.starterAmount
    // add alt unit and amount to ratio-calculated flours and push to recipe
    ratioItems.prefermentData.flour.forEach((fd, ix) => {
      const inputFlour = findObjectForFieldValue(inputData.prefermentData.flour, 'id', fd.id)
      const flourWeight = Math.round(toGrams(inputFlour.amount, inputFlour.unit, ingredientFlour))
      prefermentFlourWeight += flourWeight
      items.prefermentDough.flour.push({...fd, weight: flourWeight, group: 'Flour', altUnit: inputFlour.unit, altUnitAmount: inputFlour.amount})
    })
    // add alt unit and amount to ratio-calculated liquids and push to recipe
    ratioItems.prefermentData.liquid.forEach((ld, ix) => {
      const inputLiquid = findObjectForFieldValue(inputData.prefermentData.liquid, 'id', ld.id)
      const liquidWeight = Math.round(toGrams(inputLiquid.amount, inputLiquid.unit, ingredientWater))
      prefermentLiquidWeight += liquidWeight
      items.prefermentDough.liquid.push({...ld, weight: liquidWeight, group: 'Liquid', altUnit: inputLiquid.unit, altUnitAmount: inputLiquid.amount})
    })

    // preferment percentages and weights...
    items.mainDough.prefermentPffPct = ratioItems.prefermentPffPct
    items.prefermentDough.hydrationPct = ratioItems.prefermentData.hydrationPct
    items.prefermentDough.starterPct = ratioItems.prefermentData.starterPct
    items.prefermentDough.starterHydrationPct = ratioItems.prefermentData.starterHydrationPct

    items.prefermentDough.yeast = calculateYeastForQuantity(prefermentFlourWeight, inputData.prefermentData.yeastAmount, inputData.prefermentData.yeastUnit)
    items.prefermentDough.yeast.ratioId = ratioItems.prefermentData.yeastRatioId
    prefermentYeastWeight = items.prefermentDough.yeast.weight
    // not setting starterFlourWeight and starterLiquidWeight since Quantity form is by levain total weight only
    prefermentStarterWeight = Math.round(toGrams(inputData.prefermentData.starterAmount, inputData.prefermentData.starterUnit, ingredientLevain))
    items.prefermentDough.starterWeight = prefermentStarterWeight
    items.prefermentDough.flourWeight = prefermentFlourWeight
    prefermentTotalWeight = Math.round(prefermentFlourWeight + prefermentLiquidWeight + items.prefermentDough.starterWeight + items.prefermentDough.yeast.weight)
    items.prefermentDough.totalWeight = prefermentTotalWeight
    items.mainDough.prefermentByWeightPct = toFixedWholePercent(prefermentTotalWeight / ratioItems.effectiveRecipeFlourTotalWeight, 2)
  }
  const recipeFlourWeight = mainDoughFlourWeight + prefermentFlourWeight

  if (items.mainDough.process[processAutolyse]) {
    // highest weight to the top
    items.mainDough.liquid.sort((a, b) => b.weight - a.weight)
    const highestWeightLiquid = items.mainDough.liquid.shift()
    const autolysePctFraction = getAutolyseLiquidPct(highestWeightLiquid.weight / ratioItems.effectiveRecipeFlourTotalWeight)
    const autolyseWeight = Math.round(highestWeightLiquid.weight * autolysePctFraction)
    const autolysePct = autolyseWeight / ratioItems.effectiveRecipeFlourTotalWeight
    const finalMixWeight = highestWeightLiquid.weight - autolyseWeight
    const finalMixPct = finalMixWeight / ratioItems.effectiveRecipeFlourTotalWeight
    items.mainDough.liquid.unshift({id: highestWeightLiquid.id, name: highestWeightLiquid.name, percent: toFixedWholePercent(finalMixPct, 2), weight: finalMixWeight})
    items.mainDough.liquid.unshift({id: `a.${highestWeightLiquid.id}`, name: `Autolyse ${highestWeightLiquid.name}`, percent: toFixedWholePercent(autolysePct, 2), weight: autolyseWeight})
  }

  // calculate all recipe weights
  items.recipeFlourTotalWeight = recipeFlourWeight
  items.effectiveRecipeFlourTotalWeight = ratioItems.effectiveRecipeFlourTotalWeight
  items.effectiveRecipeHydrationPct = items.mainDough.hydrationPct
  items.recipeLiquidTotalWeight = mainDoughLiquidWeight + prefermentLiquidWeight
  items.recipeYeastTotalWeight = toFixedDecimal(items.mainDough.yeast.weight + prefermentYeastWeight, 1)
  items.recipeStarterTotalWeight = items.mainDough.starterWeight + prefermentStarterWeight
  items.recipeMixinTotalWeight = recipeMixinWeight
  items.recipeSoakerTotalWeight = recipeSoakerWeight
  items.recipeTotalWeight = items.mainDough.totalWeight + items.prefermentDough.totalWeight
  items.recipeUnitWeight = Math.round(items.recipeTotalWeight / items.numBatches)
  items.batchSize = items.recipeUnitWeight / items.numBatches

  return items
}

function calculateYeastForQuantity(flourWeight, yeastAltUnitAmount, yeastAltUnit) {
  // early return if 0 yeast amount?
  if (yeastAltUnitAmount <= 0) {
    return {weight: 0, percent: 0, altUnit: yeastAltUnit, altUnitAmount: yeastAltUnitAmount }
  }
  let yeastWeight = toFixedDecimal(toGrams(yeastAltUnitAmount, yeastAltUnit, ingredientDryYeast), 1)
  let displayLabel = `${yeastAltUnitAmount} ${getUnitLabel(yeastAltUnit)}`
  return {
    weight: yeastWeight,
    percent: toFixedWholePercent(yeastWeight / flourWeight),
    altUnit: yeastAltUnit,
    altUnitAmount: yeastAltUnitAmount,
    volumeDisplayLabel: displayLabel
  }
}

export {calculateQuantityRecipe}