import {Chip, Grid, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {LocalOffer} from "@mui/icons-material";
import * as React from "react";
import {CreateLabelDialog} from "./CreateLabelDialog";
import {useState} from "react";

function FormulaLabelControl(props) {
  const {existingFormLabels, onLabelsAdd, onLabelRemove} = props;
  const [labelText, setLabelText] = useState('')
  const [openCreateLabelDialog, setOpenCreateLabelDialog] = useState(false)

  function onLabelsAddComplete() {
    const newLabels = []
    const proposedLabels = labelText.split(',')
    for(const label of proposedLabels) {
      const proposedLabel = label.trim()
      if (!existingFormLabels.includes(proposedLabel)) {
        newLabels.push(proposedLabel)
      }
    }
    onLabelsAdd(newLabels)
    setLabelText('')
  }

  return (
    <Grid item container direction={'row'} marginTop={-1.5} columns={{xs: 4, sm: 8, md: 12}} justifyContent={'flex-start'} alignContent={'center'}>
      <Grid item xs={0.5} sm={0.5} md={0.5}>
        <Tooltip title={'Create Label'} placement={'bottom'} arrow>
          <IconButton sx={{padding: 0}} onClick={() => setOpenCreateLabelDialog(true)}><LocalOffer color={'primary'}/></IconButton>
        </Tooltip>
      </Grid>

      {existingFormLabels?.map((label, ix) => {
        return (
          <Grid key={`${ix}-${label}`} marginLeft={'0.3rem'}>
            <Chip size={"small"} label={label} onDelete={() => onLabelRemove(label)}/>
          </Grid>
        )
      })}
      <CreateLabelDialog labelText={labelText} setLabelText={setLabelText} onLabelsAdd={onLabelsAddComplete} dialogOpen={openCreateLabelDialog} setDialogOpen={setOpenCreateLabelDialog}/>
    </Grid>
  )
}

export {FormulaLabelControl}