
import {useEffect, useState} from "react";
import {getLocalDate} from "../utils/DateUtils";
import {FormTextField} from "../shared/FormTextField";
import {
  Alert,
  Box,
  Divider,
  FormControl, FormControlLabel, FormLabel, Grid,
  Paper, Radio, RadioGroup, Snackbar, Tooltip,
  Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import ReplayIcon from '@mui/icons-material/Replay';
import {SavedObjectDialog} from "../shared/SavedObjectDialog";
import * as React from "react";
import {calculateLevainRecipe} from "./LevainRecipeCalculator";
import {useNavigate, useParams} from "react-router";
import {levainFlourDefaults, levainFormDefaults} from "./LevainFormDefaults";
import * as Types from "../shared/Types";
import {LevainRecipeWrapper} from "./LevainRecipeWrapper";
import {urlLevain} from "../../App";
import {getNewStateKey} from "../utils/FormStateUtils";
import IconButton from "@mui/material/IconButton";
import {FormulaSlider} from "../shared/FormulaSlider";
import {toFixedDecimal} from "../utils/NumberUtils";
import {FermentationTimingControl} from "../shared/FermentationTimingControl";
import {fermTemps, getLevainTime, levainVolumes} from "../shared/FermentationTiming";
import {helpFermVolTemp} from "../help/PopupHelpContent";
import {ContextualHelpDialog} from "../help/ContextualHelpDialog";
import {IngredientGroupForm} from "../shared/IngredientGroupForm";
import {storageGet, storageHasFormulaName, storagePut} from "../utils/StorageUtils";
import {FormulaLabelControl} from "../shared/FormulaLabelControl";
import {useAuth} from "react-oidc-context";
import {applyLockStateEffect} from "../shared/WakeLockFactory";
import {
  clearSessionForm,
  doSaveSessionForm,
  getSessionForm,
  levainSessionKey,
  saveSessionForm
} from "../utils/SessionManager";

function LevainForm(props) {
  const {wakeLock, setWakeLock} = props;
  const {formKey} = useParams()
  const [savedFormName, setSavedFormName] = useState(initSavedFormName())
  const [savedFormData, setSavedFormData] = useState(initSavedLevainFormData())
  const [sessionFormData, setSessionFormData] = useState(initSessionFormData())
  const [formData, setFormData] = useState(initLevainFormData())
  const [flourFormData, setFlourFormData] = useState(initLevainFlourFormData())
  const [formNameError, setFormNameError] = useState(false)
  const [oldFormName, setOldFormName] = useState(undefined)
  const [openContextualHelpDialog, setOpenContextualHelpDialog] = useState(false)
  const [contextualHelpContent, setContextualHelpContent] = useState({title: undefined, content: undefined})
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [errorAlertMessage, setErrorAlertMessage] = useState('')
  const [recipeVisible, setRecipeVisible] = useState(false)
  const [recipeItems, setRecipeItems] = useState(undefined)
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true)
  const [openFormExistsDialog, setOpenFormExistsDialog] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)
  const [formStateKey, setFormStateKey] = useState(getNewStateKey())
  const navigate = useNavigate()
  const auth = useAuth();

  useEffect(() => {
    applyLockStateEffect('LevainForm', recipeVisible, wakeLock, setWakeLock)
  }, [recipeVisible])

  function initSavedFormName() {
    if (formKey === undefined) {
      // no storage key parameter, carry on
      return undefined
    }
    return window.atob(formKey)
  }

  function initSavedLevainFormData() {
    if (savedFormName === undefined) {
      return undefined
    }
    // try local storage
    const storedForm = storageGet(savedFormName)
    return storedForm ?? undefined
  }

  function initSessionFormData() {
    if (!!savedFormData) {
      // no session data if saved data is present
      return undefined
    }
    // look in session data for this form
    // Note that session storage is populated either by:
    //   - a previous visit here
    //   - set specially by the "Convert to %" button in the Weight Formula
    // The latter case uses session storage to hold the converted form and should guarantee the form name
    // will be distinct if the Weight formula was already persisted.
    // When navigating here via the "Convert to %" button, the "?converted" query param is applied to distinguish that use case
    const levainSessionFormData = getSessionForm(levainSessionKey)
    return levainSessionFormData ?? undefined
  }

  function initLevainFormData() {
    const savedOrSessionData = savedFormData ?? sessionFormData
    if (savedOrSessionData === undefined) {
      return levainFormDefaults
    }
    const updatedForm = migrateLevainFormData(savedOrSessionData)
    // save updated form in session so navigating away and returning here will still present this one
    // construct the complete formula including flour for the session save
    const mergedForm = {...updatedForm, levainFlour: savedOrSessionData['levainFlour']}
    saveSessionForm(250, levainSessionKey, savedOrSessionData['formName'], mergedForm)
    return updatedForm
  }

  function migrateLevainFormData(savedOrSessionData) {
    const {levainFlour, ...remainder} = savedOrSessionData
    return fillFormGaps(remainder)
  }

  function initLevainFlourFormData() {
    const savedOrSessionData = savedFormData ?? sessionFormData
    if (savedOrSessionData === undefined || savedOrSessionData['levainFlour'] === undefined) {
      return levainFlourDefaults
    }
    return savedOrSessionData['levainFlour']
  }

  function extractLevainFlourFormData(savedOrSessionData) {
    if (savedOrSessionData['levainFlour'] === undefined) {
      return levainFlourDefaults
    }
    return savedOrSessionData['levainFlour']
  }

  function fillFormGaps(storedForm) {
    Object.keys(levainFormDefaults).forEach(k => {
      if (storedForm[k] === undefined) {
        storedForm[k] = levainFormDefaults[k]
      }
      storedForm.formType = levainFormDefaults.formType
    })
    return storedForm
  }

  function hasCandidateNewName() {
    return  (!formData.hasOwnProperty('createdAt') || (savedFormName && (savedFormName !== formData.formName)));
  }

  function isSavedForm() {
    return formData.hasOwnProperty('createdAt')
  }

  function updateFormData(formData) {
    setFormData(formData)
    setHasChanges(true)
  }

  function updateFlourFormData(flourFormData) {
    setFlourFormData(flourFormData)
    setHasChanges(true)
  }

  function openHelp(title, content) {
    setContextualHelpContent({title: title, content: content})
    setOpenContextualHelpDialog(true)
  }

  function onCloseHelp() {
    setOpenContextualHelpDialog(false)
    setContextualHelpContent({title: undefined, content: undefined})
  }

  function onNumericFieldChange(evt) {
    let intVal = parseInt(evt.target.value)
    if (isNaN(intVal)) {
      intVal = 0
    }
    updateFormData({
      ...formData,
      [evt.target.name]: intVal
    })
  }

  function onStringFieldChange(evt) {
    updateFormData({
      ...formData,
      [evt.target.name]: evt.target.value
    })
  }

  function onFormNameFieldChange(evt) {
    if (evt.target.validity.valid) {
      setFormNameError(false)
    } else {
      setFormNameError(true)
    }
    updateFormData({
      ...formData,
      [evt.target.name]: evt.target.value
    })
  }

  function onGeneralFieldChange(evt) {
    setFormData({
      ...formData,
      [evt.target.name]: evt.target.value
    })
  }

  function recipeCloseRequest() {
    setRecipeVisible(false)
  }

  function onLabelsAdd(labelsToAdd) {
    if (labelsToAdd?.length > 0) {
      const newLabels = [...formData.labels]
      newLabels.push.apply(newLabels, labelsToAdd)
      updateFormData({
        ...formData,
        labels: newLabels
      })
    }
  }

  function onLabelRemove(deleteLabel) {
    updateFormData({
      ...formData,
      labels: formData.labels.filter(label => label !== deleteLabel)
    })
  }

  function dismissErrorAlert(event, reason) {
    setShowErrorAlert(false)
    setErrorAlertMessage('')
  }

  function applyForm() {
    clearSessionForm(levainSessionKey)
    // clear savedData reference to force reliance on session cache...
    // ...this would be overridden by Save, Revert or Clear button actions
    setSavedFormData(undefined)
    setSessionFormData(undefined)
    const recipeFormData = {...formData, levainFlour: flourFormData}
    setRecipeItems(calculateLevainRecipe(recipeFormData))
    setRecipeVisible(true)
    setSaveBtnDisabled(false)
    doSaveSessionForm(levainSessionKey, formData.formName, recipeFormData)
  }

  function loadSavedForm() {
    const formName = formData.formName
    const storedForm = storageGet(formName)
    if (!storedForm) {
      console.error(`loadSavedForm: no saved form found for '${formName}', ignoring.`)
      return;
    }

    clearSessionForm(levainSessionKey)
    const revertedFormData = migrateLevainFormData(storedForm)
    const revertedFlourFormData = extractLevainFlourFormData(storedForm)

    setFormNameError(false)
    setRecipeItems(undefined)
    setRecipeVisible(false)
    setSaveBtnDisabled(true)
    setFormStateKey(getNewStateKey())
    setHasChanges(false)
    setFormData(revertedFormData)
    setFlourFormData(revertedFlourFormData)

    // save reverted form in session so navigating away and returning here will still present this one
    const mergedForm = {...revertedFormData, levainFlour: revertedFlourFormData}
    doSaveSessionForm(levainSessionKey, formName, mergedForm)
  }

  function prefermentLevainTimeFormatted() {
    return `~${getLevainTime(formData.levainFermTempF, formData.levainFermPct, formData.levainStarterPct, formData.levainHydrationPct)}`
  }

  function resetForm() {
    clearSessionForm(levainSessionKey)
    setSavedFormName(undefined)
    setSavedFormData(undefined)
    setSessionFormData(undefined)
    setFormData(levainFormDefaults)
    setFlourFormData(levainFlourDefaults)
    setFormNameError(false)
    setRecipeItems(undefined)
    setRecipeVisible(false)
    setSaveBtnDisabled(true)
    setFormStateKey(getNewStateKey())
    setHasChanges(false)
    navigate(urlLevain)
  }

  function handleSaveClick() {
    saveForm(formData.formName, false)
  }

  function saveForm(formName, forceSave = false) {
    let formNameTrimmed = formName.trim()
    if (!forceSave && hasCandidateNewName() && storageHasFormulaName(formNameTrimmed)) {
      setOldFormName(formData.formName)
      setOpenFormExistsDialog(true)
      return
    }
    let timeStampField = 'createdAt'
    if (formData.hasOwnProperty(timeStampField)) {
      timeStampField = 'modifiedAt'
    }
    // this component tracks state of the main formData separately from the levainFlour, thus the two separate objects here
    const updatedFormData = {
      ...formData,
      formName: formNameTrimmed,
      [timeStampField]: getLocalDate().getTime()
    }
    // recipeFormData is complete formula including levainFlour for persisting
    const recipeFormData = {...updatedFormData, levainFlour: flourFormData}

    // update session before the subsequent state changes induce re-rendering!
    doSaveSessionForm(levainSessionKey, formData.formName, recipeFormData)
    // update local storage
    handleStoragePutError(storagePut(formNameTrimmed, recipeFormData, auth))

    setFormData(updatedFormData)
    setSavedFormData(recipeFormData)
    setRecipeItems(calculateLevainRecipe(recipeFormData))
    setSaveBtnDisabled(true)
    setHasChanges(false)
    setOldFormName(undefined)
    setSavedFormName(formNameTrimmed)
  }

  async function handleStoragePutError(storagePromise) {
    storagePromise.then((result) => {
      // console.log(`storagePut for ${formData.formName} completed: ${result}`)
    })
    .catch((e) => {
      console.error(`storagePut for ${formData.formName} completed in error: ${e}`)
      setErrorAlertMessage(`Error saving ${formData.formName} to account backend. Do a manual Sync on Saved Items menu option.`);
      setShowErrorAlert(true)
    })
  }

  function setNewFormName(newFormName) {
    updateFormData({...formData, formName: newFormName})
  }

  function applyNewFormName(newFormName) {
    saveForm(newFormName, true)
  }

  return (
    <div key={formStateKey}>
      <Grid container direction={'column'}>
        <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} autoHideDuration={3000} open={showErrorAlert} onClose={dismissErrorAlert}>
          <Alert sx={{marginTop: '1rem'}} severity={'error'} onClose={dismissErrorAlert}>{errorAlertMessage}</Alert>
        </Snackbar>
      </Grid>

      <Grid container direction={'row'} paddingLeft={{xs: '5%', sm: '10%', md: '15%', lg: '20%'}}
            paddingRight={{xs: '5%', sm: '10%', md: '15%', lg: '20%'}} rowSpacing={1.5} columns={{xs: 4, sm: 8, md: 12}}>
        <Grid item container direction={'column'} marginTop={1.5} alignItems={'center'}>
          <Typography variant={'h5'}>Levain Calculator</Typography>
        </Grid>
        <Grid item container direction={'column'} alignItems={'stretch'}>
          <FormTextField required
                  id="outlined-required"
                  disabled={isSavedForm()}
                  name={'formName'}
                  label="Name"
                  value={formData.formName}
                  onChange={onFormNameFieldChange}
                  error={formNameError}
                  helperText={
                    formNameError ? 'Formula name is required' : ''
                  }
                  variant="filled"/>
        </Grid>
        <Grid item container direction={'row'} columnSpacing={1.5} columns={{xs: 4, sm: 8, md: 12}} justifyContent={'flex-start'}>
          <Grid item xs={1.5} sm={2} md={3}>
              <FormTextField size={'small'}
                  id="outlined-required"
                  name={'batchSize'}
                  label={formData.batchType === 'FW' ? 'Flour g' : 'Total g'}
                  value={formData.batchSize}
                  onChange={onNumericFieldChange}
                  variant="filled"/>
            </Grid>
            <Grid item xs={2.5} sm={6} md={6}>
              <FormControl sx={{marginLeft: '1rem'}}>
                <FormLabel id="batchTypeLabel" sx={{fontSize: '0.75rem', marginBottom: 0}} disabled={true}
                           color="secondary">Unit Type</FormLabel>
                <RadioGroup aria-labelledby="batchTypeLabel" row
                            defaultValue={levainFlourDefaults.batchType}
                            value={formData.batchType}
                            name="batchType" onChange={onStringFieldChange}>
                  <FormControlLabel sx={{fontSize: '0.85rem', marginTop: 0}} value={Types.BATCH_TYPE_FLOUR_WT}
                                    control={<Radio/>}
                                    label={<Typography sx={{fontSize: 12}}>Flour Weight</Typography>}/>
                  <FormControlLabel sx={{fontSize: '0.85rem', marginTop: 0}} value={Types.BATCH_TYPE_TOTAL_WT}
                                    control={<Radio/>}
                                    label={<Typography sx={{fontSize: 12}}>Total Weight</Typography>}/>
                </RadioGroup>
              </FormControl>
            </Grid>
        </Grid>

        <FormulaLabelControl existingFormLabels={formData.labels} onLabelsAdd={onLabelsAdd} onLabelRemove={onLabelRemove}/>

        <Grid item container direction={'column'} rowSpacing={1.5} columnSpacing={1.5} columns={{xs: 4, sm: 8, md: 12}} justifyContent={'flex-start'}>
          <Grid item container direction={'column'} alignItems={'flex-start'}>
            <Typography variant={'subtitle2'} gutterBottom>Levain Hydration:
              <Box component="span" fontWeight='fontWeightBold'> {toFixedDecimal(formData.levainHydrationPct, 2)}% </Box>
            </Typography>
            <FormulaSlider getAriaLabel={() => 'Levain Hydration'}
                           sx={{marginLeft: 1, width: '90%'}}
                           name={'levainHydrationPct'}
                           value={formData.levainHydrationPct}
                           onChange={onNumericFieldChange}
                           valueLabelDisplay="auto"
                           color={"water"}
                           min={45}
                           max={160}
                           step={1}/>
          </Grid>

          <Divider textAlign={'right'} sx={{width: '90%', marginTop: 2}}>
            <Typography sx={{fontSize: 12, fontWeight: 450}}gutterBottom>Starter</Typography>
          </Divider>
          <Grid item container direction={'column'} alignItems={'flex-start'}>
            <Typography variant={'subtitle2'} gutterBottom>Starter Seed:
              <Box component="span" fontWeight='fontWeightBold'> {toFixedDecimal(formData.levainStarterPct, 2)}% Weight</Box>
            </Typography>
            <FormulaSlider getAriaLabel={() => 'Levain '}
                           sx={{marginLeft: 1, width: '90%'}}
                           name={'levainStarterPct'}
                           value={formData.levainStarterPct}
                           onChange={onNumericFieldChange}
                           color={"primary"}
                           valueLabelDisplay={'auto'}
                           min={1}
                           max={150}
                           step={1}/>
          </Grid>
          <Grid item alignItems={'flex-start'} marginBottom={2.5}>
            <FermentationTimingControl headingLabel={'Levain'} fermPctValueArr={levainVolumes} fermTempValueArr={fermTemps}
                                       timeDisplayValue={prefermentLevainTimeFormatted} timeDisplayWarning={() => undefined}
                                       fermPctValue={formData.levainFermPct} fermPctName={'levainFermPct'} fermPctLabel={'Levain Ferm Goal'}
                                       fermStateLabel={'maturity'} fermTempValue={formData.levainFermTempF} fermTempName={'levainFermTempF'} fermTempLabel={'Levain Ferm Temp'}
                                       onChange={onGeneralFieldChange} onHelpClick={() => openHelp(undefined, helpFermVolTemp)}/>
          </Grid>
        </Grid>

        <Grid item container direction={'column'} marginBottom={1} alignItems={'flex-start'}>
          <Divider textAlign={'right'} sx={{width: '90%'}}>
            <Typography sx={{fontSize: 12, fontWeight: 450}}gutterBottom>Flour</Typography>
          </Divider>
          <IngredientGroupForm ingredientData={flourFormData} setIngredientData={updateFlourFormData} balanceGoal={100} idField={'id'} nameField={'name'}
                               unitField={'percent'} groupName={'Flour'} minGroupSize={1} disabled={false}/>
        </Grid>

        <Grid item container marginTop={1} spacing={2} direction={'row'} justifyContent={'center'} columns={{xs: 4, sm: 8, md: 12}}>
          {isSavedForm() ?
            (
              <Grid item>
                <Tooltip title={'Revert to Saved copy'} placement={'bottom'} arrow>
                  <span>
                    <IconButton sx={{marginRight: -1}} onClick={loadSavedForm} disabled={!hasChanges || formNameError}><ReplayIcon fontSize={'medium'} color={(!hasChanges || formNameError ? 'grey': 'primary')}/></IconButton>
                  </span>
              </Tooltip>
            </Grid>
            ) : null
          }
          <Grid item>
            <Button variant={'contained'} onClick={applyForm} disabled={formNameError}>Apply</Button>
          </Grid>
          <Grid item>
            <Button color={"secondary"} variant={'outlined'} onClick={resetForm}>Clear</Button>
          </Grid>
          <Grid item>
            <Button color={"secondary"} variant={'outlined'} disabled={saveBtnDisabled}
                    onClick={handleSaveClick}>Save</Button>
          </Grid>
        </Grid>
      <Grid item container direction={'column'} justifyContent={'center'} columns={{xs: 4, sm: 8, md: 12}}>
        <Paper elevation={3} sx={{marginBottom: '1.5rem'}}>
          <LevainRecipeWrapper items={recipeItems} visible={recipeVisible} closeRequest={recipeCloseRequest}/>
        </Paper>
      </Grid>
    </Grid>
    <ContextualHelpDialog isOpen={openContextualHelpDialog} close={onCloseHelp}
                          title={contextualHelpContent.title} content={contextualHelpContent.content}/>

    <SavedObjectDialog objectName={formData.formName}
                       setNewObjectName={setNewFormName}
                       apply={applyNewFormName}
                       existingFormName={oldFormName}
                       dialogOpen={openFormExistsDialog}
                       setDialogOpen={setOpenFormExistsDialog}
                       objectType={'Levain'} />

  </div>
  )
}

export {LevainForm}