import * as React from 'react';
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent, List, ListItem, ListItemText,
  Typography
} from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {RecipeSummaryTable} from "./RecipeSummaryTable";
import {RecipeIngredientTable} from "./RecipeIngredientTable";
import {
  getPrefermentLabel,
  LEVAIN_PCT_TYPE_NONE,
  LEVAIN_PCT_TYPE_TOTAL_WT
} from "../shared/Types";
import {forwardRef} from "react";
import {getDateTimeStr, getLocalDateTimeStr} from "../utils/DateUtils";
import {toCapitalizedWords, valueOrUndefined} from "../utils/StringUtils";
import {toFixedDecimal} from "../utils/NumberUtils";
import {getNormalizedFlourName} from "../utils/FlourNameUtils";
import {
  processAutolyse, processBake, processBulk1, processBulk2, processBulk3,
  processFinalMix, processPrep, processProof1, processProof2, processProof3,
  processShape
} from "../shared/DoughProcess";

class RecipeDoughProcessGroup {
  constructor(seq, groupName, groupItems) {
    this.seq = seq;
    this.groupName = groupName;
    this.groupItems = groupItems; //array
  }
}

class RecipeDoughProcessItem {
  constructor(groupSeq, groupName, itemName, params, notes) {
    this.groupSeq = groupSeq;
    this.groupName = groupName;
    this.itemName = itemName;
    this.params = params;
    this.notes = notes;
  }
}
const prepTitle= 'Prep'
const autolyseTitle= 'Autolyse'
const mixTitle= 'Final Mix'
const bulkTitle = 'Bulk Fermentation'
const divideTitle = 'Divide/Shape'
const proofTitle = 'Proof'
const bakeTitle = 'Bake'

const DoughRecipe = forwardRef(function DoughRecipe(props, printRef) {
  const {items, variant, elevation} = props

  function renderStarter(dough, isPreferment) {
    if (dough.starterPct === 0 || dough.starterType === LEVAIN_PCT_TYPE_NONE) {
      return null
    }
    let levainName = isPreferment ? 'Starter' : 'Levain'
    let levainDescription = levainName
    if (dough.starterType === LEVAIN_PCT_TYPE_TOTAL_WT) {
      return (
        <RecipeIngredientTable ingredientList={[{name: levainDescription, percent: dough.starterPct, weight: dough.starterWeight, altUnit: dough.starterAltUnit, altUnitAmount: dough.starterAltUnitAmount}]}/>
      )
    } else {
      levainDescription = `${levainDescription} PFF Flour`
      if (dough.starterAltTypePct !== undefined) {
        levainDescription = `${levainDescription} (${dough.starterAltTypePct}% weight)`
      }
      let levainIngredients = []
      levainIngredients.push({percent: dough.starterPct, name: levainDescription, weight: dough.starterFlourWeight})
      levainIngredients.push({percent: dough.starterHydrationPct, name: `${levainName} Water`, weight: dough.starterLiquidWeight})
      return (
        <RecipeIngredientTable ingredientList={levainIngredients}/>
      )
    }
  }

  function renderYeast(yeast, isPreferment) {
    if (yeast === undefined || yeast.ratioId === 0) {
      return null
    }
    return (
      <div>
        <RecipeIngredientTable ingredientList={[{name: `Dry Yeast`, percent: yeast.percent, weight: yeast.weight, altUnit: yeast.altUnit, altUnitAmount: yeast.altUnitAmount
        }]}/>
      </div>
    )
  }

  function renderPreferment(includePreferment, preferment, prefermentPffPct) {
    if (!includePreferment) {
      return null
    } else {
      const prefermentName = `${getPrefermentLabel(preferment.prefermentType)}`
      return (
        <div>
          <Typography sx={{fontSize: 14, fontWeight: 550, marginTop: 2}} component={'div'}>
            {`${prefermentPffPct}% PFF${preferment.addBuffer ? '*': ''} (${prefermentName})`}
          </Typography>
          {preferment.addBuffer ?
                  (<Typography sx={{fontSize: 10, fontStyle: 'italic'}}>*Formula includes a weight buffer</Typography>)
                  : null
          }
          <Typography sx={{fontSize: 14, marginTop: 0.5, marginBottom: 0}} color="text.secondary" gutterBottom>
            Flour
          </Typography>
          <RecipeIngredientTable ingredientList={preferment.flour}/>
          <Typography sx={{fontSize: 14, marginBottom: 0}} color="text.secondary" gutterBottom>
            {`Liquid (`}<Box component="span" fontWeight='900'>{`${preferment.hydrationPct}%`}</Box> {`hydration)`}
          </Typography>
          <RecipeIngredientTable ingredientList={preferment.liquid}/>
          <Typography sx={{fontSize: 14, marginBottom: 0}} color="text.secondary" gutterBottom>
            Leavening
          </Typography>
          {
            renderStarter(preferment, true)
          }
          {
            renderYeast(preferment.yeast, true)
          }
          <Typography sx={{fontSize: 14, marginBottom: 0}} color="text.secondary" gutterBottom>
            Total
          </Typography>
          <RecipeIngredientTable ingredientList={[{name: prefermentName, weight: (preferment.addBuffer ? preferment.totalBufferedWeight : preferment.totalWeight)}]}/>
          {renderPrefermentTiming(preferment)}
        </div>
      )
    }
  }

  function renderMainLeavening(includePreferment, main, preferment) {
    return (
      <div>
        {includePreferment ?
          (
            <RecipeIngredientTable ingredientList={[{name: `Preferment`, percent: main.prefermentByWeightPct, weight: preferment.totalWeight}]}/>
          )
          : null
        }
        {
          renderStarter(main)
        }
        {
          renderYeast(main.yeast)
        }
      </div>
    )
  }

  function renderPrefermentTiming(preferment) {
    if (preferment.levainFermTimeStr.length === 0) {
      return null
    }
    return (
      <div>
        <Typography sx={{fontSize: 14, marginBottom: 0}} color="text.secondary" gutterBottom>
          Timing
        </Typography>
        <Typography sx={{fontSize: 12, marginLeft: 2}} gutterBottom>
          {`Estimated ${preferment.levainFermPct}% rise time @ ${preferment.levainFermTempF}°F: `}
          <Box component="span" fontWeight='800'>{preferment.levainFermTimeStr}</Box>
        </Typography>
      </div>
    )
  }

  function renderProcessGroupItem(processGroupItem, ix) {
    return (
      <div key={`${processGroupItem.groupName}.${ix}`}>
        <Typography sx={{marginTop: -1, marginLeft: 3, fontSize: 13, fontWeight: 600, fontStyle: 'italic'}} >{processGroupItem.itemName}</Typography>
        <ListItem sx={{marginTop: -2, marginLeft: 1}}>
          <ListItemText primaryTypographyProps={{fontSize: 12}} secondaryTypographyProps={{fontSize: 12, whiteSpace: "pre-wrap"}} primary={processGroupItem.params} secondary={processGroupItem.notes}/>
        </ListItem>
      </div>
    )
  }

  function renderProcessDisplayGroup(processGroup, ix) {
    return (
      <div key={`process.${ix}`}>
        <Typography sx={{fontSize: 14, fontWeight: 500, fontStyle: 'italic'}} gutterBottom>
          <CheckCircleOutlineIcon sx={{marginBottom: -0.5}} fontSize={'small'}/> {processGroup.groupName}
        </Typography>
        {processGroup.groupItems.map((pi, ix) => renderProcessGroupItem(pi, ix))}
      </div>
    )
  }

  function getProcessDisplayParams(doughProcess) {
    const params = []
    const time = valueOrUndefined(doughProcess.tm)
    const temp = valueOrUndefined(doughProcess.tp)
    if (time) {
      params.push(`Time: ${time}`)
    }
    if (temp) {
      params.push(`Temperature: ${temp}`)
    }
    return params.length > 0 ? params.join(', ') : undefined
  }

  function getProcessGroup(processName, doughProcess) {
    switch (processName) {
      case processPrep: {
        return new RecipeDoughProcessGroup(0, prepTitle, [new RecipeDoughProcessItem(0, prepTitle, undefined, undefined, valueOrUndefined(doughProcess.nt))]);
      }
      case processAutolyse: {
        return new RecipeDoughProcessGroup(1, autolyseTitle, [new RecipeDoughProcessItem(0, autolyseTitle, undefined, getProcessDisplayParams(doughProcess), valueOrUndefined(doughProcess.nt))]);
      }
      case processFinalMix: {
        return new RecipeDoughProcessGroup(2, mixTitle, [new RecipeDoughProcessItem(0, mixTitle, undefined, getProcessDisplayParams(doughProcess), valueOrUndefined(doughProcess.nt))]);
      }
      case processBulk1: {
        return new RecipeDoughProcessGroup(3, bulkTitle, [new RecipeDoughProcessItem(0, bulkTitle, doughProcess.sn, getProcessDisplayParams(doughProcess), valueOrUndefined(doughProcess.nt))]);
      }
      case processBulk2: {
        return new RecipeDoughProcessGroup(4, bulkTitle, [new RecipeDoughProcessItem(1, bulkTitle, doughProcess.sn, getProcessDisplayParams(doughProcess), undefined)]);
      }
      case processBulk3: {
        return new RecipeDoughProcessGroup(5, bulkTitle, [new RecipeDoughProcessItem(2, bulkTitle, doughProcess.sn, getProcessDisplayParams(doughProcess), undefined)]);
      }
      case processShape: {
        return new RecipeDoughProcessGroup(8, divideTitle, [new RecipeDoughProcessItem(0, divideTitle, undefined, undefined, valueOrUndefined(doughProcess.nt))]);
      }
      case processProof1: {
        return new RecipeDoughProcessGroup(9, proofTitle, [new RecipeDoughProcessItem(0, proofTitle, doughProcess.sn, getProcessDisplayParams(doughProcess), undefined)]);
      }
      case processProof2: {
        return new RecipeDoughProcessGroup(10, proofTitle, [new RecipeDoughProcessItem(1, proofTitle, doughProcess.sn, getProcessDisplayParams(doughProcess), undefined)]);
      }
      case processProof3: {
        return new RecipeDoughProcessGroup(11, proofTitle, [new RecipeDoughProcessItem(2, proofTitle, doughProcess.sn, getProcessDisplayParams(doughProcess), undefined)]);
      }
      case processBake: {
        return new RecipeDoughProcessGroup(14, bakeTitle, [new RecipeDoughProcessItem(0, bakeTitle, undefined, getProcessDisplayParams(doughProcess), valueOrUndefined(doughProcess.nt))]);
      }
      default: return undefined
    }
  }

  function getProcessDisplayGroups(processItems) {
    const processGroups = []
    const groupings = {}
    for (const processKey in processItems) {
      const processGroup = getProcessGroup(processKey, processItems[processKey])
      if (!!processGroup) {
        if (!groupings.hasOwnProperty(processGroup.groupName)) {
          groupings[processGroup.groupName] = processGroup.groupItems
        } else {
          groupings[processGroup.groupName].push.apply(groupings[processGroup.groupName], processGroup.groupItems)
        }
        processGroups.push(processGroup)
      }
    }

    const multiItemGroupNames = Object.keys(groupings).filter(grp => groupings[grp].length > 1)
    const multiItemGroups = []
    const adjustedProcessGroups = []

    processGroups.forEach(group => {
      if (!multiItemGroupNames.includes(group.groupName)) {
        adjustedProcessGroups.push(group)
      } else if (!multiItemGroups.find(g => g.groupName === group.groupName)) {
        groupings[group.groupName].sort((a,b) => {return a.groupSeq - b.groupSeq})
        multiItemGroups.push(new RecipeDoughProcessGroup(group.seq, group.groupName, groupings[group.groupName]))
      }
    })

    if (multiItemGroups.length > 0) {
      adjustedProcessGroups.push.apply(adjustedProcessGroups, multiItemGroups)
    }
    adjustedProcessGroups.sort((a, b) => {return a.seq - b.seq})
    return adjustedProcessGroups
  }

  // build grain distribution/summary
  const grainSummaryItems = []
  const allGrains = [...items.mainDough.flour]
  if (items.prefermentDough?.flour?.length > 0) {
    allGrains.push.apply(allGrains, items.prefermentDough.flour)
  }
  for (let grain of allGrains) {
    const flourName = getNormalizedFlourName(toCapitalizedWords(grain['name'].trim()))
    let grainItem = grainSummaryItems.find(gr => gr['name'] === flourName)
    if (!grainItem) {
      grainSummaryItems.push({name: flourName, percent: 0, weight: grain['weight'] ?? 0})
    } else {
      grainItem['weight'] += grain['weight']
    }
  }
  for (const grainSummary of grainSummaryItems) {
    grainSummary['percent'] = toFixedDecimal(grainSummary['weight'] / items.recipeFlourTotalWeight * 100)
  }
  grainSummaryItems.sort((a, b) => b.percent - a.percent)

  const processDisplayGroups = getProcessDisplayGroups(items.mainDough.process)
  return (
      <Card elevation={elevation} variant={variant}>
          <CardContent id={'print-dough-card'} ref={printRef}>
            <Typography sx={{fontWeight: 600}} variant="h6" component={'div'}>
              {items.formName}
            </Typography>
            {items.attribution ? (
                    <Typography sx={{ fontSize: 10}}>
                      Attribution: {items.attribution}
                    </Typography>) : null
            }
            <Typography sx={{ fontSize: 10}}>
              Generated: {getLocalDateTimeStr()}
            </Typography>
            {items.createdAt ? (
                <Typography sx={{ fontSize: 10}}>{`Created: ${getDateTimeStr(new Date(items.createdAt))}`}</Typography>
            ) : null}
            {items.modifiedAt ? (
                    <Typography sx={{ fontSize: 10}}>{`Last modified: ${getDateTimeStr(new Date(items.modifiedAt))}`}</Typography>
            ) : null}
            <Typography marginTop={0.75} sx={{ fontSize: 14, fontWeight: 550}}>
              Recipe Totals
            </Typography>
            <RecipeSummaryTable items={items}/>

            <Typography marginTop={0.75} sx={{ fontSize: 14, fontWeight: 550}}>
              Recipe Grain Distribution{items.prefermentDough?.addBuffer ? '*' : null}
            </Typography>
            {items.prefermentDough?.addBuffer ?
                    (<Typography sx={{fontSize: 10, fontStyle: 'italic'}}>*Amounts include a weight buffer</Typography>)
                    : null
            }
            <RecipeIngredientTable ingredientList={grainSummaryItems} includeTotalRow={false}/>

            {renderPreferment(items.includePreferment, items.prefermentDough, items.mainDough.prefermentPffPct)}

            <Typography sx={{fontSize: 14, fontWeight: 550, marginTop: 1}} component={'div'}>
              Main Dough
            </Typography>
            <Typography sx={{fontSize: 10, fontStyle: 'italic'}}>(Bakers percentages, eff. flour weight {items.effectiveRecipeFlourTotalWeight}g)</Typography>

            <Typography sx={{fontSize: 14, marginTop: 0.5, marginBottom: 0}} color="text.secondary" gutterBottom>
              Flour
            </Typography>
            <RecipeIngredientTable ingredientList={items.mainDough.flour}/>
            <Typography sx={{fontSize: 14, marginBottom: 0}} color="text.secondary" gutterBottom>
              Liquid
            </Typography>
            <RecipeIngredientTable ingredientList={items.mainDough.liquid}/>
            <Typography sx={{fontSize: 14, marginBottom: 0}} color="text.secondary" gutterBottom>
              Leavening
            </Typography>
            {renderMainLeavening(items.includePreferment, items.mainDough, items.prefermentDough)}
            {items.mainDough.mixin.length > 0 ? (
              <div>
                <Typography sx={{fontSize: 14, marginBottom: 0}} color="text.secondary" gutterBottom>
                  Inclusions
                </Typography>
                <RecipeIngredientTable ingredientList={items.mainDough.mixin}/>
              </div>) : null
            }
            {items.mainDough.soaker.length > 0 ? (
              <div>
                <Typography sx={{fontSize: 14, marginBottom: 0}} color="text.secondary" gutterBottom>
                  Soaker
                </Typography>
                <RecipeIngredientTable ingredientList={items.mainDough.soaker}/>
              </div>) : null
            }
            {items.mainDough.toppings.length > 0 ? (
              <div>
                <Typography sx={{fontSize: 14, marginBottom: 0}} color="text.secondary" gutterBottom>
                  Toppings
                </Typography>
                <RecipeIngredientTable ingredientList={items.mainDough.toppings}/>
              </div>) : null
            }
            <Typography sx={{fontSize: 14, marginBottom: 0}} color="text.secondary" gutterBottom>
              Total
            </Typography>
            <RecipeIngredientTable ingredientList={[{name: 'Main Dough', weight: items.recipeTotalWeight}]}/>
            {processDisplayGroups.length > 0 ? (
              <div>
                <Typography sx={{fontSize: 14, fontWeight: 550, marginTop: 2}} component={'div'}>
                  Process
                </Typography>
                <List>
                {processDisplayGroups.map((pg, ix) => {
                  return renderProcessDisplayGroup(pg, ix)
                })}
                </List>
              </div>) : null
            }
            {items.notes.trim() !== '' ? (
              <div>
                <Typography sx={{marginTop: 1, fontSize: 14, fontWeight: 550}} component={'div'}>
                  Notes
                </Typography>
                <Typography sx={{fontSize: 12, whiteSpace: "pre-wrap"}} color="text.secondary" gutterBottom>
                  {items.notes}
                </Typography>
              </div>) : null
            }
          </CardContent>
      </Card>
  )
})

DoughRecipe.propTypes = {
  items: PropTypes.shape({
    formName: PropTypes.string,
    attribution: PropTypes.string,
    createdAt: PropTypes.number,
    modifiedAt: PropTypes.number,
    notes: PropTypes.string,
    numBatches: PropTypes.number,
    batchSize: PropTypes.number,
    includePreferment: PropTypes.bool,
    recipeFlourTotalWeight: PropTypes.number,
    recipeLiquidTotalWeight: PropTypes.number,
    recipeYeastTotalWeight: PropTypes.number,
    recipeMixinTotalWeight: PropTypes.number,
    recipeSoakerTotalWeight: PropTypes.number,
    recipeTotalWeight: PropTypes.number,
    mainDough: PropTypes.shape({
      hydrationPct: PropTypes.number,
      prefermentPffPct: PropTypes.number,
      starterPct: PropTypes.number,
      starterAltTypePct:  PropTypes.number,
      starterHydrationPct: PropTypes.number,
      yeast: PropTypes.shape({
        ratioId: PropTypes.number,
        percent: PropTypes.number,
        weight: PropTypes.number,
        altUnit: PropTypes.string,
        altUnitAmount: PropTypes.number,
        volumeDisplayLabel: PropTypes.string
      }),
      starterWeight: PropTypes.number,
      starterAltUnit: PropTypes.string,
      starterAltUnitAmount: PropTypes.number,
      starterFlourWeight: PropTypes.number,
      starterLiquidWeight: PropTypes.number,
      flourWeight: PropTypes.number,
      liquidWeight: PropTypes.number,
      flour: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.number,
        percent: PropTypes.number,
        altUnit: PropTypes.string,
        altUnitAmount: PropTypes.number
      })),
      liquid: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.number,
        percent: PropTypes.number,
        altUnit: PropTypes.string,
        altUnitAmount: PropTypes.number
      })),
      mixin: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.number,
        percent: PropTypes.number,
        altUnit: PropTypes.string,
        altUnitAmount: PropTypes.number
      })),
      soaker: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.number,
        percent: PropTypes.number,
        altUnit: PropTypes.string,
        altUnitAmount: PropTypes.number
      })),
      toppings: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.number,
        percent: PropTypes.number,
        altUnit: PropTypes.string,
        altUnitAmount: PropTypes.number
      })),
      totalWeight: PropTypes.number,
      prefermentByWeightPct: PropTypes.number
    }),
    prefermentDough: PropTypes.shape( {
      hydrationPct: PropTypes.number,
      starterPct: PropTypes.number,
      starterAltTypePct:  PropTypes.number,
      starterHydrationPct: PropTypes.number,
      yeast: PropTypes.shape({
        ratioId: PropTypes.number,
        percent: PropTypes.number,
        weight: PropTypes.number,
        altUnit: PropTypes.string,
        altUnitAmount: PropTypes.number,
        volumeDisplayLabel: PropTypes.string
      }),
      starterWeight: PropTypes.number,
      starterAltUnit: PropTypes.string,
      starterAltUnitAmount: PropTypes.number,
      starterFlourWeight: PropTypes.number,
      starterLiquidWeight: PropTypes.number,
      flourWeight: PropTypes.number,
      liquidWeight: PropTypes.number,
      autolyse: PropTypes.bool,
      levainFermPct: PropTypes.number,
      levainFermTempF: PropTypes.string,
      levainFermTimeStr: PropTypes.string,
      flour: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.number,
        percent: PropTypes.number,
        altUnit: PropTypes.string,
        altUnitAmount: PropTypes.number
      })),
      liquid: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.number,
        percent: PropTypes.number,
        altUnit: PropTypes.string,
        altUnitAmount: PropTypes.number
      })),
      totalWeight: PropTypes.number
    })
  }),
  visible: PropTypes.bool
}

export { DoughRecipe }