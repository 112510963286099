import axios from "axios";

export const apiFormNameAttr = 'formName';
export const apiFormAttr = 'form';
export const apiLastModifiedAttr = 'lastModified';

// stage name: "default"
// Note: if a nicer domain is preferred, e.g. api.formuladough.com, need to do the following:
//  1. Create a wildcard cert, *.formuladough.com (DONE)
//  2. Create a Route53 hosted zone for api.formuladough.com (DONE)
//  3. Switch from API Gateway HTTP APIs to REST APIs, required by AWS in order to
//     use an edge-optimized domain ... assuming can still use the built-in JWT Authorizer and
//     existing Lambda integration (?)
//  4. The edge domain config as part of the REST API definition should create a CloudFront host name DNS entry.
//     Need to add an 'A' (Alias) record to the api.formuladough.com DNS entries in Route53 with a ref to this cloudfront.net host
//  5. Create route mappings for the custom domain in the API Gateway config
const baseUrl = 'https://qqew4ynw58.execute-api.us-east-2.amazonaws.com'

const client = axios.create({
  baseURL: baseUrl,
  timeout: 5000,
  headers: {
    post: {
      "Content-Type": "application/json",
    },
    get: {
      Accept: "application/json"
    }
  }
})

export async function apiPutFormula(auth, formulaName, lastModified, formulaJson, ) {
  const requestBody = {[apiFormNameAttr]: formulaName, [apiLastModifiedAttr]: lastModified, [apiFormAttr]: formulaJson};
  const requestHeaders = {"Authorization": `Bearer ${auth?.user?.access_token}`, "Content-Type": "application/json", "Accept": "application/json"};
  return client.put('/forms', requestBody, { headers: requestHeaders } )
  .then(response => {return response.data})
  .catch(error => {
    console.error(`apiPutFormula error for formulaName '${formulaName}' : ${error}`);
    throw error;
  })
}

export async function apiDeleteFormula(auth, formulaName, ) {
  const requestHeaders = {"Authorization": `Bearer ${auth?.user?.access_token}`, "Accept": "application/json"};
  return client.delete(`/forms/${formulaName}`, { headers: requestHeaders } )
  .then(response => {return response.data})
  .catch(error => {
    console.error(`apiDeleteFormula error for formulaName '${formulaName}' : ${error}`);
    throw error;
  })
}

export async function apiGetFormula(auth, formulaName, ) {
  const requestHeaders = {"Authorization": `Bearer ${auth?.user?.access_token}`, "Accept": "application/json"};
  return client.get(`/forms/${formulaName}`, { headers: requestHeaders } )
  .then(response => {return response.data})
  .catch(error => {
    console.error(`apiGetFormula error for formulaName '${formulaName}' : ${error}`);
    throw error;
  })
}

export async function apiGetAllFormulas(auth, limit, lastReadFormName) {
  const requestHeaders = {"Authorization": `Bearer ${auth?.user?.access_token}`, "Accept": "application/json"};
  let uriPath = '/forms'
  let queryParams = []
  if (limit !== undefined && !isNaN(limit)) {
    queryParams.push(`limit=${limit}`)
  }
  if (lastReadFormName !== undefined && typeof lastReadFormName === 'string') {
    queryParams.push(`lastReadFormName=${lastReadFormName}`)
  }
  if (queryParams.length > 0) {
    uriPath = uriPath + `?${queryParams.join('&')}`
  }
  return client.get(uriPath, { headers: requestHeaders } )
  .then(response => {return response.data})
  .catch(error => {
    console.error(`apiGetAllFormulas error: ${error}`);
    throw error;
  })
}

// Batch Put usage notes...
// 1. formula array is limited to 25 items; a 400 will be returned if max item count is exceeded
// 2. formulaArray is expected to contain these fields:
//      "formName" (string), "lastModified" (number), "form" (string of serialized GZip'd JSON)
// 3. caller is responsible for checking response field, "UnprocessedItems": {} to determine if any formulas didn't make it
export async function apiBatchPutFormulas(auth, formulaArray) {
  const requestHeaders = {"Authorization": `Bearer ${auth?.user?.access_token}`, "Accept": "application/json"};
  return client.put(`/forms/batch`, formulaArray, { headers: requestHeaders } )
  .then(response => {return response.data})
  .catch(error => {
    console.error(`apiBatchPutFormulas error: ${error}`);
    throw error;
  })
}

