import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import Button from "@mui/material/Button";

function CreateLabelDialog(props) {
  const {labelText, setLabelText, onLabelsAdd, dialogOpen, setDialogOpen} = props

  const handleClose = () => {
    setDialogOpen(false);
    setLabelText('')
  };

  function onOkClick() {
    if (labelText?.length > 0) {
      onLabelsAdd();
    }
    handleClose()
  }

  function onFieldChange(evt) {
    setLabelText(evt.target.value)
  }

  return (
    <div>
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>Formula Label</DialogTitle>
        <DialogContent>
          <DialogContentText>
            New Label(s), comma separated
          </DialogContentText>
          <TextField
                  autoFocus
                  margin="dense"
                  size={'small'}
                  label={`Label`}
                  value={labelText}
                  onChange={onFieldChange}
                  fullWidth
                  variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button variant={"outlined"} onClick={onOkClick}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export {CreateLabelDialog}