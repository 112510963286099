import * as Types from "../shared/Types";
import {
  BATCH_TYPE_FLOUR_WT,
  LEVAIN_PCT_TYPE_TOTAL_WT,
  noPreferment, PREFERMENT_BUFFER_WEIGHT
} from "../shared/Types";
import {unitGrams, unitTeaspoon} from "../shared/Units";
import {toFixedDecimal, toFixedWholePercent} from "../utils/NumberUtils";
import {getYeastPercentFromId, getYeastTotalTspFromId, yeastDefs} from "../shared/Yeast";
import {getLevainTime, getYeastTime} from "../shared/FermentationTiming";
import {
  calculateLevainFlourByTotalWeight,
  calculatePrimaryLevainComponentWeights
} from "../levain/LevainRecipeCalculator";

const ratioRecipeItems = {
  formName: '',
  formType: '',
  attribution: '',
  createdAt: '',
  modifiedAt: '',
  notes: '',
  numBatches: 1,
  batchSize: 0,
  batchType: Types.BATCH_TYPE_TOTAL_WT,
  includePreferment: false,
  recipeFlourTotalWeight: 0,
  effectiveRecipeFlourTotalWeight: 0,
  effectiveRecipeHydrationPct: 0,
  recipeLiquidTotalWeight: 0,
  recipeYeastTotalWeight: 0,
  recipeStarterTotalWeight: 0,
  recipeMixinTotalWeight: 0,
  recipeSoakerTotalWeight: 0,
  recipeUnitWeight: 0,
  recipeTotalWeight: 0,
  mainDough: {
    hydrationPct: 0,
    hydrationPctFraction: 0,
    process: {},
    prefermentPffPct: 0,
    prefermentPffPctFraction: 0,
    starterType: LEVAIN_PCT_TYPE_TOTAL_WT,
    starterPct: 0,
    starterAltTypePct: 0,
    starterPctFraction: 0,
    starterHydrationPct: 0,
    starterHydrationPctFraction: 0,
    yeastInputRatioId: 0,
    yeastInputPct: 0,
    yeastInputPctFraction: 0,
    yeast: {
      flourUnitGrams: yeastDefs.flourUnitGrams,
      ratioId: 0,
      percent: 0,
      weight: 0,
      altUnit: unitTeaspoon,
      altUnitAmount: 0,
      volumeDisplayLabel: ''
    },
    starterWeight: 0,
    starterAltUnit: unitGrams,
    starterAltUnitAmount: 0,
    starterFlourWeight: 0,
    starterLiquidWeight: 0,
    flourWeight: 0,
    flourInput: [],
    flour: [],
    liquidWeight: [],
    liquidInput: [],
    liquid: [],
    mixinInput: [],
    mixin: [],
    soakerInput: [],
    soaker: [],
    toppingsInput: [],
    toppings: [],
    totalWeight: 0,
    prefermentByWeightPct: 0
  },
  prefermentDough: {
    prefermentType: noPreferment,
    hydrationPct: 0,
    hydrationPctFraction: 0,
    levainFermPct: 0,
    levainFermTempF: '0',
    levainFermTimeStr: '',
    starterType: LEVAIN_PCT_TYPE_TOTAL_WT,
    starterPct: 0,
    starterAltTypePct: 0,
    starterPctFraction: 0,
    starterHydrationPct: 0,
    starterHydrationPctFraction: 0,
    yeastInputRatioId: 0,
    yeastInputPct: 0,
    yeastInputPctFraction: 0,
    yeast: {
      flourUnitGrams: yeastDefs.flourUnitGrams,
      ratioId: 0,
      percent: 0,
      weight: 0,
      altUnit: unitTeaspoon,
      altUnitAmount: 0,
      volumeDisplayLabel: ''
    },
    starterWeight: 0,
    starterAltUnit: unitGrams,
    starterAltUnitAmount: 0,
    starterFlourWeight: 0,
    starterLiquidWeight: 0,
    addBuffer: false,
    flourWeight: 0,
    flourInput: [],
    flour: [],
    liquidWeight: [],
    liquidInput: [],
    liquid: [],
    totalWeight: 0,
    totalBufferedWeight: 0
  }
}


function calculateDoughRecipe(inputData) {
  let items = JSON.parse(JSON.stringify(ratioRecipeItems))
  items.formName = inputData.formName
  items.formType = inputData.formType
  items.attribution = inputData.attribution
  items.createdAt = inputData.createdAt
  items.modifiedAt = inputData.modifiedAt
  items.notes = inputData.notes
  items.numBatches = inputData.numBatches
  items.batchSize = inputData.batchSize
  items.batchType = inputData.batchType
  items.includePreferment = inputData.includePreferment
  items.mainDough.prefermentPffPct = inputData.prefermentPffPct
  items.mainDough.prefermentPffPctFraction = inputData.prefermentPffPct / 100
  items.mainDough.hydrationPct = inputData.hydrationPct
  items.mainDough.hydrationPctFraction = inputData.hydrationPct / 100
  items.mainDough.starterType = inputData.starterType
  items.mainDough.starterPct = inputData.starterPct
  items.mainDough.starterPctFraction = inputData.starterPct / 100
  items.mainDough.starterHydrationPct = inputData.starterHydrationPct
  items.mainDough.starterHydrationPctFraction = inputData.starterHydrationPct / 100
  items.mainDough.yeastInputRatioId = inputData.yeastRatioId
  items.mainDough.yeastInputPct = inputData.yeastPct
  items.mainDough.yeastInputPctFraction = inputData.yeastPct / 100
  items.mainDough.flourInput = inputData.flour
  items.mainDough.liquidInput = inputData.liquid
  items.mainDough.mixinInput = inputData.mixin
  items.mainDough.soakerInput = inputData.soaker
  items.mainDough.toppingsInput = inputData.toppings
  items.mainDough.process = inputData.process

  if (inputData.includePreferment) {
    items.prefermentDough.prefermentType = inputData.prefermentData.prefermentType
    items.prefermentDough.levainFermPct = inputData.prefermentData.levainFermPct
    items.prefermentDough.levainFermTempF = inputData.prefermentData.levainFermTempF
    items.prefermentDough.hydrationPct = inputData.prefermentData.hydrationPct
    items.prefermentDough.hydrationPctFraction = inputData.prefermentData.hydrationPct / 100
    items.prefermentDough.yeastInputRatioId = inputData.prefermentData.yeastRatioId
    items.prefermentDough.yeastInputPct = inputData.prefermentData.yeastPct
    items.prefermentDough.yeastInputPctFraction = inputData.prefermentData.yeastPct / 100
    items.prefermentDough.starterType = inputData.prefermentData.starterType
    items.prefermentDough.starterPct = inputData.prefermentData.starterPct
    items.prefermentDough.starterPctFraction = inputData.prefermentData.starterPct / 100
    items.prefermentDough.starterHydrationPct = inputData.prefermentData.starterHydrationPct
    items.prefermentDough.starterHydrationPctFraction = inputData.prefermentData.starterHydrationPct / 100
    items.prefermentDough.addBuffer = inputData.prefermentData.addBuffer
    items.prefermentDough.flourInput = inputData.prefermentData.flour
    items.prefermentDough.liquidInput = inputData.prefermentData.liquid
  }
  return calculateRecipeWeights(items)
}

// Big picture...
//  The total recipe flour, on which bakers percentages are based, may be determined slightly differently in
//  different scenarios.  The difference only applies when using levain rather than yeast, and whether
//  adding a preferment formula or not.
//  No Preferment with Levain:
//    The total recipe flour includes additional flour presumed to be in the levain weight percentage based on the starter
//    hydration percent. This is done _instead_ of treating the levain as an independent ingredient.
//    The reason this is done is to get a more accurate hydration percentage
//  Preferment with levain:
//    There will be no main dough levain (current limitation), so no added flour due to a levain weight percentage.
//    In this scenario, the preferment starter is identified as an independent ingredient which makes for no
//    magically added flour or liquid.  Specifically, the PFF levain formula is NOT being adjusted to account for
//    water or flour implicitly contained in the PFF starter seed to avoid additional complexity,
//    e.g. adjusting the liquid amount in the PFF levain to account for implied liquid in the starter seed
//    affects the total target dough weight and quickly becomes confusing, so this idea was abandoned.
//    We are left with the PFF levain starter being treated as a separate ingredient.
//    The baker can adjust for this if desired.
//
function calculateRecipeWeights(items) {
  let flourAndLiquidWeights = calculateRecipeLiquidAndFlour(items)

  // When calculating weights for all main dough ingredients (other than levain/starter), the effectiveRecipeFlourWeight
  // must be used as the basis to get everything in terms of Bakers Percentages.
  // This includes main dough flour, water, mixins, soakers, yeast and main levain weight.
  const effectiveRecipeFlourWeight = flourAndLiquidWeights.flourWeight
  const effectiveRecipeLiquidWeight = flourAndLiquidWeights.liquidWeight
  // consoleLog(`effectiveRecipeFlourWeight=${effectiveRecipeFlourWeight}, effectiveRecipeLiquidWeight=${effectiveRecipeLiquidWeight}`)

  let primaryWeights = calculateAllComponentWeights(effectiveRecipeFlourWeight, items)
  // main weights
  let mainLevainWeight = primaryWeights.mainDough.levainWeight
  let mainEffLevainFlourWeight = primaryWeights.mainDough.effectiveLevainFlourWeight
  let mainEffLevainLiquidWeight = primaryWeights.mainDough.effectiveLevainLiquidWeight

  // preferment weights
  let prefermentDoughFlourWeight = primaryWeights.prefermentDough.doughFlourWeight
  let prefermentDoughLiquidWeight = primaryWeights.prefermentDough.doughLiquidWeight
  let prefermentLevainWeight = primaryWeights.prefermentDough.levainWeight
  let prefermentEffLevainFlourWeight = primaryWeights.prefermentDough.effectiveLevainFlourWeight
  let prefermentEffLevainLiquidWeight = primaryWeights.prefermentDough.effectiveLevainLiquidWeight

  // consoleLog(`main components... levainWeight=${mainLevainWeight}, effLevainFlourWeight=${mainEffLevainFlourWeight}, effLevainLiquidWeight=${mainEffLevainLiquidWeight}`)
  // consoleLog(`pref components... flourWeight=${prefermentDoughFlourWeight}, liquidWeight=${prefermentDoughLiquidWeight}, levainWeight=${prefermentLevainWeight}, effLevainFlourWeight=${prefermentEffLevainFlourWeight}, effLevainLiquidWeight=${prefermentEffLevainLiquidWeight}`)

  // recalculate mainDough flour based on ratios for each flour
  let mainDoughFlourWeightAdjusted = calculateFlourDistribution(items.mainDough, effectiveRecipeFlourWeight)
  // recalculate mainDough liquids based on ratios for each liquid
  let mainDoughLiquidWeightAdjusted = calculateLiquidDistribution(items.mainDough, effectiveRecipeFlourWeight)
  // consoleLog(`after main ingredient % distributions applied... mainDoughFlourWeightAdjusted=${mainDoughFlourWeightAdjusted}, mainDoughLiquidWeightAdjusted=${mainDoughLiquidWeightAdjusted}`)

  // recalculate prefermentDough flour and liquid ingredients based on desired percentages...
  // preferment flour %'s based on visible flour total, i.e. excluding implicit levain flour since overall weight will include starter
  let prefermentDoughFlourWeightAdjusted = calculateFlourDistribution(items.prefermentDough, prefermentDoughFlourWeight)
  // preferment liquid %'s based on visible liquid total, i.e. excluding implicit levain liquid since overall weight will include starter
  let prefermentDoughLiquidWeightAdjusted = calculateLiquidDistribution(items.prefermentDough, prefermentDoughLiquidWeight)

  // consoleLog(`after pre ingredient % distributions applied... prefermentDoughFlourWeightAdjusted=${prefermentDoughFlourWeightAdjusted}, prefermentDoughLiquidWeightAdjusted=${prefermentDoughLiquidWeightAdjusted}`)

  // adjust main dough liquid if needed...
  items.mainDough.liquid.sort((a, b) => b.weight - a.weight)  // weight descending
  // adjust recipe liquid to account for starter inferred liquid
  let recipeLiquidCurrent =  mainDoughLiquidWeightAdjusted + prefermentDoughLiquidWeightAdjusted // + prefermentEffLevainLiquidWeight
  let mainLiquidTarget = effectiveRecipeLiquidWeight - mainEffLevainLiquidWeight
  if (recipeLiquidCurrent !== mainLiquidTarget) {
    let liquidImbalance = recipeLiquidCurrent - mainLiquidTarget
    let highestWeightLiquid = items.mainDough.liquid[0]
    highestWeightLiquid['weight'] -= liquidImbalance
    mainDoughLiquidWeightAdjusted -= liquidImbalance
  }

  items.mainDough.liquidWeight = mainDoughLiquidWeightAdjusted
  items.prefermentDough.liquidWeight = prefermentDoughLiquidWeightAdjusted

  let recipeMixinWeight = 0
  items.mainDough.mixinInput.forEach((md, ix) => {
    let mixinWeight = Math.round(effectiveRecipeFlourWeight * (md.percent / 100))
    items.mainDough.mixin.push({id: md.id, name: md.name, percent: md.percent, weight: mixinWeight})
    recipeMixinWeight += mixinWeight
  })

  let recipeSoakerWeight = 0
  items.mainDough.soakerInput.forEach((sd, ix) => {
    let soakerWeight = Math.round(effectiveRecipeFlourWeight * (sd.percent / 100))
    items.mainDough.soaker.push({id: sd.id, name: sd.name, percent: sd.percent, weight: soakerWeight})
    recipeSoakerWeight += soakerWeight
  })
  // topping items are just a category of mixins, but weight doesn't count in dough weight
  items.mainDough.toppingsInput.forEach((td, ix) => {
    items.mainDough.toppings.push({id: td.id, name: td.name, group: 'Toppings', percent: td.percent, weight: 0})
  })

  // prefermentDough weights...
  items.prefermentDough.starterWeight = prefermentLevainWeight
  items.prefermentDough.starterFlourWeight = Math.round(prefermentEffLevainFlourWeight)
  items.prefermentDough.starterLiquidWeight = prefermentEffLevainLiquidWeight
  items.prefermentDough.flourWeight = prefermentDoughFlourWeightAdjusted
  if (items.prefermentDough.starterType === LEVAIN_PCT_TYPE_TOTAL_WT) {
    items.prefermentDough.starterAltTypePct = Math.round(100 * levainTwToPff(items.prefermentDough.flourWeight, items.prefermentDough.starterPctFraction, items.prefermentDough.starterHydrationPctFraction))
  } else {
    items.prefermentDough.starterAltTypePct = levainPffToTw(items.prefermentDough.flourWeight, items.prefermentDough.starterWeight)
  }
  items.prefermentDough.yeast = calculateYeastForRatio(prefermentDoughFlourWeightAdjusted, items.prefermentDough.yeastInputRatioId, items.prefermentDough.yeastInputPct)
  // consoleLog(`prefermentDough yeast: ${JSON.stringify(items.prefermentDough.yeast)}`)
  items.prefermentDough.totalWeight = Math.round(prefermentDoughFlourWeightAdjusted + prefermentDoughLiquidWeightAdjusted + prefermentLevainWeight + items.prefermentDough.yeast.weight)


  // mainDough weights...
  items.mainDough.starterWeight = mainLevainWeight
  items.mainDough.starterFlourWeight = mainEffLevainFlourWeight
  items.mainDough.starterLiquidWeight = mainEffLevainLiquidWeight
  items.mainDough.flourWeight = mainDoughFlourWeightAdjusted
  if (items.mainDough.starterType === LEVAIN_PCT_TYPE_TOTAL_WT) {
    items.mainDough.starterAltTypePct = Math.round(100 * levainTwToPff(effectiveRecipeFlourWeight, items.mainDough.starterPctFraction, items.mainDough.starterHydrationPctFraction))
  } else {
    items.mainDough.starterAltTypePct = levainPffToTw(effectiveRecipeFlourWeight, items.mainDough.starterWeight)
  }
  items.mainDough.yeast = calculateYeastForRatio(effectiveRecipeFlourWeight, items.mainDough.yeastInputRatioId, items.mainDough.yeastInputPct)
  // consoleLog(`mainDough yeast: ${JSON.stringify(items.mainDough.yeast)}`)
  items.mainDough.totalWeight = Math.round(mainDoughFlourWeightAdjusted + mainDoughLiquidWeightAdjusted + mainLevainWeight + recipeMixinWeight + recipeSoakerWeight + items.mainDough.yeast.weight)
  items.mainDough.prefermentByWeightPct = toFixedWholePercent(items.prefermentDough.totalWeight / effectiveRecipeFlourWeight, 2)

  // timing projections...
  if (items.prefermentDough.flourWeight > 0) {
    // only sending preferment timing to the recipe rendering
    if (items.prefermentDough.starterPct > 0) {
      items.prefermentDough.levainFermTimeStr = getLevainTime(items.prefermentDough.levainFermTempF, items.prefermentDough.levainFermPct, items.prefermentDough.starterPct, items.prefermentDough.hydrationPct)
    } else if (items.prefermentDough.yeastInputRatioId > 0) {
      items.prefermentDough.levainFermTimeStr = getYeastTime(items.prefermentDough.levainFermTempF, items.prefermentDough.levainFermPct, items.prefermentDough.yeastInputRatioId, items.prefermentDough.hydrationPct)
    }
  }

  // finalize recipe totals...
  items.recipeFlourTotalWeight = Math.round(mainDoughFlourWeightAdjusted + prefermentDoughFlourWeightAdjusted)
  items.effectiveRecipeFlourTotalWeight = effectiveRecipeFlourWeight
  items.recipeLiquidTotalWeight = mainDoughLiquidWeightAdjusted + prefermentDoughLiquidWeightAdjusted
  items.recipeMixinTotalWeight = recipeMixinWeight
  items.recipeSoakerTotalWeight = recipeSoakerWeight
  items.recipeYeastTotalWeight = toFixedDecimal(items.mainDough.yeast.weight + items.prefermentDough.yeast.weight, 1)
  items.recipeStarterTotalWeight = mainLevainWeight + prefermentLevainWeight

  items.recipeTotalWeight = items.mainDough.totalWeight + items.prefermentDough.totalWeight
  items.recipeUnitWeight = Math.round(items.recipeTotalWeight / items.numBatches)
  items.effectiveRecipeHydrationPct = items.mainDough.hydrationPct
  if (prefermentLevainWeight > 0) {
    let effTotalFlour = items.recipeFlourTotalWeight + prefermentEffLevainFlourWeight
    let effTotalLiquid = items.recipeLiquidTotalWeight + prefermentEffLevainLiquidWeight
    items.effectiveRecipeHydrationPct = toFixedDecimal(100 * (effTotalLiquid / effTotalFlour))
  }

  // weight buffer for preferment (if requested)
  // recalculate preferment to include a weight buffer
  // note that the real, unbuffered preferment weight has already been applied (above) to calculate the main dough
  if (items.includePreferment && items.prefermentDough.addBuffer && items.prefermentDough.starterType === LEVAIN_PCT_TYPE_TOTAL_WT) {
    let targetPrefermentWeight = items.prefermentDough.totalWeight + PREFERMENT_BUFFER_WEIGHT
    const newPrefermentFlourWeight = calculateLevainFlourByTotalWeight(targetPrefermentWeight, items.prefermentDough.starterPctFraction, items.prefermentDough.hydrationPctFraction)
    const prefermentComponents = calculatePrimaryLevainComponentWeights(newPrefermentFlourWeight, items.prefermentDough.starterPctFraction, items.prefermentDough.hydrationPctFraction)
    // consoleLog(`prefermentBuffer:: orig starterWeight=${items.prefermentDough.starterWeight}, targetPrefermentWeight=${targetPrefermentWeight}, newPrefermentFlourWeight=${newPrefermentFlourWeight}, components=${JSON.stringify(prefermentComponents)} `)
    const newPrefermentLiquidWeight = prefermentComponents.levainLiquidWeight
    items.prefermentDough.starterWeight = Math.round(prefermentComponents.levainStarterWeight)
    items.prefermentDough.yeast = calculateYeastForRatio(newPrefermentFlourWeight, items.prefermentDough.yeastInputRatioId, items.prefermentDough.yeastInputPct)
    // recalculate prefermentDough flour based on ratios for each flour
    prefermentDoughFlourWeightAdjusted = calculateFlourDistribution(items.prefermentDough, newPrefermentFlourWeight)
    // recalculate prefermentDough liquids based on ratios for each liquid
    prefermentDoughLiquidWeightAdjusted = calculateLiquidDistribution(items.prefermentDough, newPrefermentLiquidWeight)
    items.prefermentDough.totalBufferedWeight = Math.round(prefermentDoughFlourWeightAdjusted + prefermentDoughLiquidWeightAdjusted + items.prefermentDough.starterWeight + items.prefermentDough.yeast.weight)
    // consoleLog(`after bufferedPreferment... totalBufferedWeight=${items.prefermentDough.totalBufferedWeight}, originalWeight=${items.prefermentDough.totalWeight}`)
  }

  return items;
}

// recalculate dough flour based on ratios for each flour
function calculateFlourDistribution(dough, doughFlourWeight) {
  let doughFlourWeightAdjusted = 0
  dough.flour = []
  dough.flourInput.forEach((fd, ix) => {
    if (!fd.ro) {
      let flourWeight = Math.round(doughFlourWeight * (fd.percent / 100))
      dough.flour.push({id: fd.id, name: fd.name, group: 'Flour', percent: fd.percent, weight: flourWeight})
      doughFlourWeightAdjusted += flourWeight
    }
  })
  return doughFlourWeightAdjusted
}

// recalculate dough liquids based on ratios for each liquid
function calculateLiquidDistribution(dough, doughLiquidWeight) {
  let doughLiquidWeightAdjusted = 0
  dough.liquid = []
  dough.liquidInput.forEach((ld, ix) => {
    if (!ld.ro) {
      let liquidWeight = Math.round(doughLiquidWeight * (ld.percent / 100))
      dough.liquid.push({id: ld.id, name: ld.name, group: 'Liquid', percent: ld.percent, weight: liquidWeight})
      doughLiquidWeightAdjusted += liquidWeight
    }
  })
  return doughLiquidWeightAdjusted
}

// Derive overall recipe total flour for the Bakers Percentage basis
// The effectiveRecipeFlourWeight is used as the basis for the PFF percentage calculation when using a preferment.
//
// The calculateRecipeLiquidAndFlour calculation excludes the main dough levain weight ratio (if specified),
// so it implicitly includes inferred main dough-specified levain flour and liquid in order to achieve a more accurate
// total hydration percentage.
// Note, however that calculateRecipeLiquidAndFlour FACTORS OUT the levain weight ratio for the preferment
// starter, if preferment is specified and levain is specified for the preferment.
// This leaves the preferment starter as an independent ingredient, i.e. PFF levain is NOT implicitly included
// to avoid the added calculation complexity and confusing recipe layout.
// Note this idea was attempted but then abandoned as it became challenging to adjust the PFF liquid without affecting
// the target total dough weight!  [Some fancier math or iterative calculations might be able to address that]
function calculateRecipeLiquidAndFlour(items) {
  let recipeFlourWeight
  if (items.batchType === BATCH_TYPE_FLOUR_WT) {
    // user-specified total flour weight
    recipeFlourWeight = Math.round(items.numBatches * items.batchSize)
  } else {
    // When the batch is specified as a total weight, solve for the recipe flour by dividing total weight
    // by the known, non-flour/water ingredient percentages:
    //   1. Sum of mixin percentages
    //   2. Sum of dry yeast percentages
    //   3. Total of preferment starter percentage
    //   4. Total hydration percent
    // TWc = rf + rf*(sum(Mi)) + rf*yp/100 + rf*hp + rf*pff*psp
    //  rf = TWc / (1 + sum(Mi) + yp/100 + hp + pff*psp)
    // where:
    //    TWc = total weight (constant)
    //     rf = recipe flour
    //     Mi = mixin pct(s)
    //     yp = yeast pct (percent per 100g flour here)
    //     hp = total hydration pct
    //     pff = %pff preferment flour % of recipe flour
    //     psp = preferment starter percent by weight
    //
    // Any main dough levain/starter included in the recipe will have its respective flour and water amounts taken
    // into account outside of this calculation because that will influence the final total hydration calculation.
    let targetTotalWeight = items.numBatches * items.batchSize
    // sum(Mi):
    let mixinRatioSum = 0
    items.mainDough.mixinInput.forEach((md) => {
      mixinRatioSum += (md.percent / 100)
    })
    // soaker items are just another category of mixins
    items.mainDough.soakerInput.forEach((sd) => {
      mixinRatioSum += (sd.percent / 100)
    })

    // yeast percentage, only for the main dough, is proportionate to the respective main and preferment specifications
    const mainYeastPct = items.mainDough.yeastInputPctFraction || (getYeastPercentFromId(items.mainDough.yeastInputRatioId) / yeastDefs.flourUnitGrams)
    let prefermentYeastPct = items.prefermentDough.yeastInputPctFraction || (getYeastPercentFromId(items.prefermentDough.yeastInputRatioId) / yeastDefs.flourUnitGrams)
    prefermentYeastPct = prefermentYeastPct * items.mainDough.prefermentPffPctFraction
    const totalYeastPct = mainYeastPct + prefermentYeastPct
    // consoleLog(`calcRecipeLiquidAndFlour: targetTotalWeight: ${targetTotalWeight}, mixinRatioSum: ${mixinRatioSum}, mainYeastPct: ${mainYeastPct}, hydrationPct: ${items.mainDough.hydrationPct}`)

    // if using a preferment, extract preferment starter by weight from the recipe flour calculation
    //   preferment's starter will be treated as a separate ingredient
    let prefermentLevainAsWeightPctFraction = 0
    if (items.mainDough.prefermentPffPct > 0) {
      prefermentLevainAsWeightPctFraction = (items.prefermentDough.starterPctFraction * (items.mainDough.prefermentPffPct / 100))
    }
    // and now...
    recipeFlourWeight = Math.round(targetTotalWeight / (1 + mixinRatioSum + totalYeastPct + items.mainDough.hydrationPctFraction + prefermentLevainAsWeightPctFraction))
  }

  let recipeLiquidWeight = Math.round(recipeFlourWeight * items.mainDough.hydrationPctFraction)
  return {flourWeight: recipeFlourWeight, liquidWeight: recipeLiquidWeight}
}

// Derive both the main and preferment (if designated) component weights
// If a preferment is designated, the total recipe flour quantity is split based on the provided percentage.
// The preferment has its own hydration percentage specified separately from the total recipe hydration percentage.
// When a preferment is designated, the main dough liquid will be the remainder from the preferment liquid to satisfy
// the total recipe hydration.  Note that when levain is specified as PFF, the levain hydration factors into the
// hydration percentage of both the preferment and the total hydration.
function calculateAllComponentWeights(recipeFlourWeight, items) {
  let mainDoughPrimaryWeights = undefined
  let prefermentDoughPrimaryWeights = undefined
  if (!items.includePreferment) {
    prefermentDoughPrimaryWeights = calculateComponentWeights(0, items.prefermentDough)
    mainDoughPrimaryWeights = calculateComponentWeights(recipeFlourWeight, items.mainDough)
  } else {
    // here, preferment flour weight includes flour implicitly in starter included as a total weight
    let prefermentFlourWeight = Math.round(recipeFlourWeight * items.mainDough.prefermentPffPctFraction)
    prefermentDoughPrimaryWeights = calculateComponentWeights(prefermentFlourWeight, items.prefermentDough)
    mainDoughPrimaryWeights = calculateComponentWeights(recipeFlourWeight - prefermentFlourWeight, items.mainDough)
  }
  return {
    mainDough: mainDoughPrimaryWeights,
    prefermentDough: prefermentDoughPrimaryWeights
  }
}

function calculateComponentWeights(totalFlourWeight, doughParams) {
  let doughFlourWeight = 0
  let doughLiquidWeight = 0
  let levainWeight = 0
  let effectiveLevainFlourWeight = 0
  let effectiveLevainLiquidWeight = 0

  doughFlourWeight = totalFlourWeight
  doughLiquidWeight = Math.round(totalFlourWeight * doughParams.hydrationPctFraction)
  levainWeight = Math.round(totalFlourWeight * doughParams.starterPctFraction)
  if (levainWeight > 0) {
    effectiveLevainFlourWeight = Math.round(levainWeight / (1 + doughParams.starterHydrationPctFraction))
    effectiveLevainLiquidWeight = levainWeight - effectiveLevainFlourWeight
  }

  return {
    doughFlourWeight: doughFlourWeight,
    doughLiquidWeight: doughLiquidWeight,
    levainWeight: levainWeight,
    effectiveLevainFlourWeight: effectiveLevainFlourWeight,
    effectiveLevainLiquidWeight: effectiveLevainLiquidWeight
  }
}

function calculateYeastForRatio(flourWeight, yeastRatioId, yeastPct) {
  const yeastPercent = yeastPct || getYeastPercentFromId(yeastRatioId)
  const altUnitAmt = toFixedDecimal(getYeastTotalTspFromId(yeastRatioId, flourWeight), 1)
  return {
    ratioId: yeastRatioId,
    weight: toFixedDecimal(yeastPercent * (flourWeight / yeastDefs.flourUnitGrams), 1),
    percent: toFixedDecimal(yeastPercent),
    altUnit: unitTeaspoon,
    altUnitAmount: altUnitAmt,
    volumeDisplayLabel: `${altUnitAmt} Tsp`
  }
}

function consoleLog(message) {
  // debug
  console.log(message)
}

function levainTwToPff(doughFLourWeight, starterPctF, starterHydrationPctF) {
  if (!doughFLourWeight) {
    return 0
  }
  const levainFlourWeight = (doughFLourWeight * starterPctF) / (1 + starterHydrationPctF)
  return levainFlourWeight / (doughFLourWeight + levainFlourWeight )
}

function levainPffToTw(flourWeight, levainWeight) {
  return Math.round(100 * (levainWeight / flourWeight))
}

function levainPffToTwEstimate(pffPct, levainHydrationPct, levainSeedPct) {
  const pffPctF = pffPct / 100
  const levainHydrationPctF = levainHydrationPct / 100
  const levainSeedPctF = levainSeedPct / 100
  return Math.round((pffPctF + (pffPctF * levainHydrationPctF) + (pffPctF * levainSeedPctF)) * 100)
}

export {calculateDoughRecipe, calculateYeastForRatio, levainTwToPff, levainPffToTw, levainPffToTwEstimate}