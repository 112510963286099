import * as React from 'react';
import {
  Box, Divider,
  FormControlLabel, FormGroup,
  Grid,
  Switch,
  Typography
} from "@mui/material";
import {
  getLevainTypeLabel
} from "../shared/Types";
import {
  getYeastPercentFromId,
  getYeastRatioSelectLabel,
  getMaxYeastRatioId
} from "../shared/Yeast";
import {toFixedDecimal} from "../utils/NumberUtils";
import {useState} from "react";
import {
  helpFermVolTemp
} from "../help/PopupHelpContent";
import {ContextualHelpDialog} from "../help/ContextualHelpDialog";
import {FormulaSlider} from "../shared/FormulaSlider";
import {
  defaultBulkFermPct,
  doughVolumes,
  fermTemps,
  getLevainTime,
  getYeastTime
} from "../shared/FermentationTiming";
import {levainPffToTwEstimate} from "./DoughRecipeCalculator";
import {FermentationTimingControl} from "../shared/FermentationTimingControl";
import {IngredientGroupForm} from "../shared/IngredientGroupForm";
import {IngredientCollectionForm} from "../shared/IngredientCollectionForm";

function MainDoughForm(props) {
  const {formData, setFormData, prefermentLevainFermPct, prefermentHydrationPct, prefermentStarterPct} = props
  const [openContextualHelpDialog, setOpenContextualHelpDialog] = useState(false)
  const [contextualHelpContent, setContextualHelpContent] = useState({title: undefined, content: undefined})
  const [showFermentationTimings, setShowFermentationTimings]= useState(false)
  const [bulkTimingTempF, setBulkTimingTempF] = useState('75')
  const [bulkTimingPct, setBulkTimingPct] = useState(defaultBulkFermPct)

  function openHelp(title, content) {
    setContextualHelpContent({title: title, content: content})
    setOpenContextualHelpDialog(true)
  }

  function onCloseHelp() {
    setOpenContextualHelpDialog(false)
    setContextualHelpContent({title: undefined, content: undefined})
  }

  function updateFlourData(updatedFlourData) {
    setFormData({
      ...formData,
      flour: updatedFlourData
    })
  }

  function updateLiquidData(updatedLiquidData) {
    setFormData({
      ...formData,
      liquid: updatedLiquidData
    })
  }

  function updateMixinData(mixinData) {
    setFormData({
      ...formData,
      mixin: mixinData
    })
  }

  function updateSoakerData(soakerData) {
    setFormData({
      ...formData,
      soaker: soakerData
    })
  }

  function updateToppingsData(toppingsData) {
    setFormData({
      ...formData,
      toppings: toppingsData
    })
  }

  function onNumericFieldChange(evt) {
    let intVal = parseInt(evt.target.value)
    if (isNaN(intVal)) {
      intVal = 0
    }
    setFormData({
      ...formData,
      [evt.target.name]: intVal
    })
  }

  function onDecimalFieldChange(evt) {
    let floatVal = parseFloat(evt.target.value)
    if (isNaN(floatVal)) {
      floatVal = 0
    }
    setFormData({
      ...formData,
      [evt.target.name]: floatVal
    })
  }

  function onYeastRatioChange(evt) {
    let ratioId = parseInt(evt.target.value)
    if (isNaN(ratioId)) {
      ratioId = 0
    }
    setFormData({
      ...formData,
      yeastRatioId: ratioId,
      yeastPct: getYeastPercentFromId(ratioId)
    })
  }

  function onBulkTimingParamChange(evt) {
    const fieldName = evt.target.name
    if (fieldName === 'bulkTimingTempF') {
      setBulkTimingTempF(evt.target.value)
    } else if (fieldName === 'bulkTimingPct') {
      setBulkTimingPct(evt.target.value)
    }
  }

  function renderToggles() {
    return (
        <Grid item container marginTop={-2} direction={'column'} alignItems={'flex-start'} marginBottom={1}>
          <FormGroup row>
            <FormControlLabel control={<Switch name={'sft'} checked={showFermentationTimings}
                                               onChange={(evt) => setShowFermentationTimings(evt.target.checked)}/>}
                              label={<Typography variant={'subtitle2'}>Show Fermentation Timings</Typography>}/>
          </FormGroup>
        </Grid>
      )
  }

  function getStarterSliderHeading() {
    if (formData.starterPct > 0) {
      return `${toFixedDecimal(formData.starterPct, 2)}% ${getLevainTypeLabel(formData.starterType)}`
    }
    return 'None'
  }

  function getYeastSliderHeading() {
    if (formData.yeastRatioId > 0) {
      return `${toFixedDecimal(getYeastPercentFromId(formData.yeastRatioId), 2)}%`
    }
    return 'None'
  }

  function renderMainLeavening() {
    return (
      <div>
        <Grid item container direction={'column'} alignItems={'flex-start'} marginBottom={0.5}>
          <Typography variant={'subtitle2'} gutterBottom>Levain:
            <Box component="span" fontWeight='fontWeightBold'> {getStarterSliderHeading()}</Box>
          </Typography>
          <FormulaSlider getAriaLabel={() => 'Levain '}
                         sx={{marginLeft: 1, width: '90%'}}
                         disabled={formData.yeastRatioId !== 0}
                         name={'starterPct'}
                         value={formData.starterPct}
                         onChange={onNumericFieldChange}
                         color={formData.starterPct > 0 ? "primary" : "attention"}
                         valueLabelDisplay={'auto'}
                         min={0}
                         max={150}
                         step={1}/>
        </Grid>
        <Grid item container direction={'column'} alignItems={'flex-start'} marginTop={1} marginBottom={1}>
          <Typography variant={'subtitle2'} gutterBottom>Dry Yeast:
            <Box component="span" fontWeight='fontWeightBold'> {getYeastSliderHeading()}</Box>
          </Typography>
          <FormulaSlider getAriaLabel={() => 'Dry Yeast'}
                         sx={{marginLeft: 1, width: '90%'}}
                         disabled={formData.starterPct > 0}
                         name={'yeastRatioId'}
                         value={formData.yeastRatioId}
                         onChange={onYeastRatioChange}
                         color={formData.yeastRatioId > 0 ? "primary" : "attention"}
                         valueLabelFormat={getYeastRatioSelectLabel}
                         valueLabelDisplay={'auto'}
                         min={0}
                         max={getMaxYeastRatioId()}
                         step={1}/>
        </Grid>
      </div>
    )
  }

  function renderPrefermentRatio() {
    return (
      <div>
        <Grid item container direction={'column'} alignItems={'flex-start'} marginBottom={1} >
          <Grid item container direction={'row'}>
            <Typography variant={'subtitle2'} gutterBottom>Preferment:
              <Box component="span" fontWeight='fontWeightBold'> {formData.prefermentPffPct}% PFF</Box>
            </Typography>
          </Grid>
          <FormulaSlider getAriaLabel={() => 'Preferment Flour'}
                         disabled={false}
                         sx={{marginLeft: 1, width: '90%'}}
                         name={'prefermentPffPct'}
                         value={formData.prefermentPffPct}
                         onChange={onDecimalFieldChange}
                         color={formData.prefermentPffPct > 0 ? "primary" : "attention"}
                         valueLabelDisplay="auto"
                         min={0.5}
                         max={45}
                         step={0.5}/>
        </Grid>
      </div>
  )
  }

  function prefermentMainDoughTimeFormatted() {
    if (formData.prefermentPffPct === 0) {
      return 'n/a'
    }
    let levainPct = levainPffToTwEstimate(formData.prefermentPffPct, prefermentHydrationPct, prefermentStarterPct)
    let qualifier = ''
    if (prefermentLevainFermPct < 100) {
      qualifier = '*'
    }
    // console.log(`calc, levainPct=${levainPct}`)
    return `~${getLevainTime(bulkTimingTempF, bulkTimingPct, levainPct, formData.hydrationPct)} ${qualifier}`
  }

  function prefermentMainDoughWarningMsg() {
    if (prefermentLevainFermPct < 100) {
      return `*Time adjusted due to preferment less than 100%`
    }
    return undefined
  }

  function mainDoughTimeFormatted() {
    if (formData.starterPct === 0 && formData.yeastRatioId === 0) {
      return 'n/a'
    }
    if (formData.starterPct > 0) {
      let levainPct = formData.starterPct
      return `~${getLevainTime(bulkTimingTempF, bulkTimingPct, levainPct, formData.hydrationPct )}`
    } else {
      return `~${getYeastTime(bulkTimingTempF, bulkTimingPct, formData.yeastRatioId, formData.hydrationPct)}`
    }
  }

  function getPrefermentFlourRatio() {
    if (formData.prefermentPffPct > 0 ) {
      return {name: 'PreFerm PFF', percent: formData.prefermentPffPct}
    } else if (formData.starterPct > 0) {
      let pct = toFixedDecimal(formData.starterPct / (1 + (formData.starterHydrationPct / 100)))
      return {name: 'Levain Flour', percent: pct}
    }
    return undefined
  }

  function getPrefermentLiquidRatio() {
    if (formData.prefermentPffPct > 0 ) {
      let pct = toFixedDecimal((prefermentHydrationPct / 100) * formData.prefermentPffPct)
      return {name: 'PreFerm Liquid', percent: pct}
    } else if (formData.starterPct > 0) {
      let pct = toFixedDecimal(formData.starterPct - (formData.starterPct / (1 + (formData.starterHydrationPct / 100))))
      return {name: 'Levain Liquid', percent: pct}
    }
    return undefined
  }

  const prefermentFlourRatio = getPrefermentFlourRatio()
  const readOnlyFlour = prefermentFlourRatio !== undefined ? {
    name: prefermentFlourRatio['name'],
    percent: prefermentFlourRatio['percent'],
    percentStr: `${prefermentFlourRatio['percent']}`,
    ro: true,
    id: 'pf'
  } : undefined

  const prefermentLiquidRatio = getPrefermentLiquidRatio()
  const readOnlyLiquid = prefermentLiquidRatio !== undefined ? {
    name: prefermentLiquidRatio['name'],
    percent: prefermentLiquidRatio['percent'],
    percentStr: `${prefermentLiquidRatio['percent']}`,
    ro: true,
    id: 'pf'
  } : undefined

  return (
          <Grid item container direction={'column'}>
            <Grid item container direction={'row'}>
            {renderToggles()}
            </Grid>
            <div>
              <Divider textAlign={'right'} sx={{width: '90%'}}>
                <Typography sx={{fontSize: 12, fontWeight: 350}} gutterBottom>Leavening</Typography>
              </Divider>
              {formData.includePreferment ? renderPrefermentRatio() : renderMainLeavening()}
              {showFermentationTimings ?
                  <FermentationTimingControl headingLabel={'Bulk Ferm'} fermPctValueArr={doughVolumes} fermTempValueArr={fermTemps}
                                             timeDisplayValue={formData.includePreferment ? prefermentMainDoughTimeFormatted : mainDoughTimeFormatted}
                                             timeDisplayWarning={formData.includePreferment ? prefermentMainDoughWarningMsg : () => undefined}
                                             fermPctValue={bulkTimingPct} fermPctName={'bulkTimingPct'} fermPctLabel={'Bulk Ferm Goal'}
                                             fermStateLabel={'rise'} fermTempValue={bulkTimingTempF} fermTempName={'bulkTimingTempF'} fermTempLabel={'Bulk Ferm Temp'}
                                             onChange={onBulkTimingParamChange} onHelpClick={() => openHelp(undefined, helpFermVolTemp)}/>
                  :null}
              <Divider textAlign={'right'} sx={{marginTop: 4, width: '90%'}}>
                <Typography sx={{fontSize: 12, fontWeight: 350}} gutterBottom>Flour</Typography>
              </Divider>
              <Grid item container direction={'column'} alignItems={'flex-start'} marginTop={1} marginBottom={1}>
                <IngredientGroupForm ingredientData={formData.flour} setIngredientData={updateFlourData} balanceGoal={100} idField={'id'} nameField={'name'}
                           unitField={'percent'} groupName={'Flour'} minGroupSize={1} disabled={false} readOnlyIngredient={readOnlyFlour}/>
              </Grid>
            </div>
            <div>
              <Divider textAlign={'right'} sx={{marginTop: 2, width: '90%'}}>
                <Typography sx={{fontSize: 12, fontWeight: 350}} gutterBottom>Liquid</Typography>
              </Divider>
              <Grid item container direction={'column'} alignItems={'flex-start'} marginTop={1} marginBottom={1}>
                <IngredientGroupForm ingredientData={formData.liquid} setIngredientData={updateLiquidData} balanceGoal={formData.hydrationPct} idField={'id'}
                            nameField={'name'} unitField={'percent'} minGroupSize={1} groupName={'Liquid'} disabled={false}  readOnlyIngredient={readOnlyLiquid}/>
              </Grid>
            </div>

            <Divider textAlign={'right'} sx={{marginTop: 2, width: '90%'}}>
              <Typography sx={{fontSize: 12, fontWeight: 350}} gutterBottom>Inclusions</Typography>
            </Divider>
            <Grid item container direction={'column'} alignItems={'flex-start'} marginTop={1}
                  marginBottom={1}>
              <IngredientCollectionForm ingredientData={formData.mixin} setIngredientData={updateMixinData} idField={'id'}
                                        nameField={'name'} unitField={'percent'} collectionName={'Inclusion'}/>
            </Grid>
            <Divider textAlign={'right'} sx={{marginTop: 2, width: '90%'}}>
              <Typography sx={{fontSize: 12, fontWeight: 350}} gutterBottom>Soaker</Typography>
            </Divider>
            <Grid item container direction={'column'} alignItems={'flex-start'} marginTop={1}
                  marginBottom={1}>
              <IngredientCollectionForm ingredientData={formData.soaker} setIngredientData={updateSoakerData} idField={'id'}
                                        nameField={'name'} unitField={'percent'} collectionName={'Soaker'}/>
            </Grid>
            <Divider textAlign={'right'} sx={{marginTop: 2, width: '90%'}}>
              <Typography sx={{fontSize: 12, fontWeight: 350}} gutterBottom>Toppings</Typography>
            </Divider>
            <Grid item container direction={'column'} alignItems={'flex-start'} marginTop={1}
                  marginBottom={1}>
              <IngredientGroupForm ingredientData={formData.toppings} setIngredientData={updateToppingsData} balanceGoal={100} idField={'id'}
                                   nameField={'name'} unitField={'percent'} groupName={'Toppings'} minGroupSize={0} disabled={false}/>
            </Grid>

            <ContextualHelpDialog isOpen={openContextualHelpDialog} close={onCloseHelp}
                                  title={contextualHelpContent.title} content={contextualHelpContent.content}/>
          </Grid>
  )
}

export { MainDoughForm }