import {
  Alert, Backdrop,
  Box, Chip, CircularProgress,
  FormControl, FormControlLabel, Grid, InputAdornment, InputLabel,
  List,
  ListItem,
  ListItemButton, ListItemText, MenuItem, OutlinedInput,
  Radio,
  RadioGroup, Select, Snackbar, SnackbarContent, TextField, ToggleButtonGroup, Tooltip, Typography
} from "@mui/material";
import * as React from "react";
import {getDateTimeStr, getLocalDate, getLocalDateStr} from "../utils/DateUtils";
import IconButton from "@mui/material/IconButton";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PublishIcon from '@mui/icons-material/Publish';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import {useState} from "react";
import {Link} from "react-router-dom";
import {
  getStorageCollectionJson,
  storageGet, storageGetFormulaNames, storagePut,
  storageRemove,
  putStorageCollectionJson, syncStorageWithAccount, sessionHasKey, sessionGetRaw, sessionPutRaw
} from "../utils/StorageUtils";
import {formTypeUrlMap} from "../../App";
import {formTypeLevain, formTypeWeightDough, formTypeRatioDough} from "../shared/Types";
import {ConfirmDialog} from "../shared/ConfirmDialog";
import {PromptFormDialog} from "../shared/PromptFormDialog";
import {useAuth} from "react-oidc-context";
import {arrayHasIntersectionIgnoreCase} from "../utils/ArrayUtils";
import {useTheme} from "@mui/system";
import {SortDescendingIcon} from "../icon/SortDescendingIcon";
import {SortAscendingIcon} from "../icon/SortAscendingIcon";
import {FormToggleButton} from "../shared/FormToggleButton";
import {clearUnknownSessionForm} from "../utils/SessionManager";
import CloseIcon from "@mui/icons-material/Close";

const exportFileNamePrefix = 'formulaDough'
const exportFormTypeDescription = 'all'
const sessionFormTypeFilterKey = 'sessFormTypeFilter'
const sessionNameFilterKey = 'sessNameFilter'
const sessionLabelFilterKey = 'sessLabelFilter'
const sessionSortTypeKey = 'sessSortType'
const sessionSortDescendingKey = 'sessSortDesc'
const sortTypeAccessTime = 'accessTime'
const sortTypeAlpha = 'alpha'

function SavedItems(props) {
  const [storageKeys, setStorageKeys] = useState(storageGetFormulaNames())
  const [uploadFile, setUploadFile] = useState(null)
  const [actionableFormName, setActionableFormName] = useState(null)
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)
  const [openCopyRenameDialog, setOpenCopyRenameDialog] = useState(false)
  const [candidateFormName, setCandidateFormName] = useState(undefined)
  const [invalidUploadFile, setInvalidUploadFile] = useState()
  const [fileImportComplete, setFileImportComplete] = useState()
  const [formType, setFormType] = useState(initTypeFilter())
  const [formNameFilter, setFormNameFilter] = useState(initNameFilter())
  const [formLabelFilters, setFormLabelFilters] = useState(initLabelFilter())
  const [sortType, setSortType] = useState(initSortType())
  const [sortDescending, setSortDescending] = useState(initSortDescending())
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorAlertMessage, setErrorAlertMessage] = useState('')
  const [successAlert, setSuccessAlert] = useState(false)
  const [successAlertMessage, setSuccessAlertMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [syncReportOpen, setSyncReportOpen] = useState(false)
  const [syncReportData, setSyncReportData] = useState([])

  const auth = useAuth();
  const theme = useTheme();

  const allDistinctFormLabels = []
  const visibleItems = []

  const storageMap = {}
  storageKeys.forEach(k => {
    storageMap[k] = storageGet(k)
    if (storageMap[k].labels?.length > 0) {
      for (const label of storageMap[k].labels) {
        const lowerCaseLabel = label.toLowerCase()
        if (!allDistinctFormLabels.includes(lowerCaseLabel)) {
          allDistinctFormLabels.push(lowerCaseLabel)
        }
      }
    }
  })
  const newFormLabelFilters = []
  formLabelFilters.forEach(label => {
    if (allDistinctFormLabels.includes(label)) {
      newFormLabelFilters.push(label)
    }
  })
  if (newFormLabelFilters.length !== formLabelFilters.length) {
    applyLabelFilter(newFormLabelFilters)
  }

  function initTypeFilter() {
    const defaultValue = formTypeRatioDough
    if (!sessionHasKey(sessionFormTypeFilterKey)) {
      return defaultValue
    }
    let sessionValue = sessionGetRaw(sessionFormTypeFilterKey)
    return sessionValue !== undefined ? sessionValue : defaultValue
  }

  function initNameFilter() {
    const defaultValue = ''
    if (!sessionHasKey(sessionNameFilterKey)) {
      return defaultValue
    }
    let sessionValue = sessionGetRaw(sessionNameFilterKey)
    return sessionValue !== undefined ? sessionValue : defaultValue
  }

  function initLabelFilter() {
    const defaultValue = []
    if (!sessionHasKey(sessionLabelFilterKey)) {
      return defaultValue
    }
    let sessionValue = sessionGetRaw(sessionLabelFilterKey)
    return sessionValue !== undefined ? JSON.parse(sessionValue) : defaultValue
  }

  function initSortType() {
    const defaultValue = sortTypeAccessTime
    if (!sessionHasKey(sessionSortTypeKey)) {
      return defaultValue
    }
    let sessionValue = sessionGetRaw(sessionSortTypeKey)
    return sessionValue !== undefined ? sessionValue : defaultValue
  }

  function initSortDescending() {
    const defaultValue = true
    if (!sessionHasKey(sessionSortDescendingKey)) {
      return defaultValue
    }
    let sessionValue = sessionGetRaw(sessionSortDescendingKey)
    return sessionValue !== undefined ? sessionValue === 'true' : defaultValue
  }

  function applyTypeFilter(value) {
    setFormType(value)
    sessionPutRaw(sessionFormTypeFilterKey, value)
  }

  function applyNameFilter(value) {
    setFormNameFilter(value)
    sessionPutRaw(sessionNameFilterKey, value)
  }

  function applyLabelFilter(arrayValue) {
    setFormLabelFilters(arrayValue)
    sessionPutRaw(sessionLabelFilterKey, JSON.stringify(arrayValue))
  }

  function applySortType(value) {
    setSortType(value)
    sessionPutRaw(sessionSortTypeKey, value)
  }

  function applySortDescending(value) {
    setSortDescending(value)
    sessionPutRaw(sessionSortDescendingKey, value)
  }

  function hasStoredItems() {
    return storageKeys?.length > 0;
  }

  function getExportFormulaTypeDescription() {
    return exportFormTypeDescription
  }

  function exportFormulas() {
    // credit: https://stackoverflow.com/questions/44656610/download-a-string-as-txt-file-in-react
    const element = document.createElement("a");
    const jsonArray = getStorageCollectionJson(undefined, undefined, 'createdAt', false)
    const file = new Blob([`[${jsonArray.join(',')}]`], {type: 'application/json'});
    element.href = URL.createObjectURL(file);
    const formTypeDescription = getExportFormulaTypeDescription()
    element.download = `${exportFileNamePrefix}-${formTypeDescription}-${getLocalDateStr()}.json`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    document.body.removeChild(element)
  }

  async function syncUserAccount() {
    setLoading(true)
    await syncStorageWithAccount(auth)
    .then(rsp => {
      let noChanges = (rsp.downloadedNames.length === 0 && rsp.uploadedNames.length === 0 && rsp.failedUploadNames?.length === 0);
      if (noChanges) {
        setSuccessAlertMessage('Up to date!')
      } else {
        const syncReportMessages = []
        rsp.downloadedNames.forEach(name => {syncReportMessages.push(`Downloaded ${name}`)})
        rsp.uploadedNames.forEach(name => {syncReportMessages.push(`Uploaded ${name}`)})
        if (rsp.failedUploadNames?.length > 0) {
          rsp.failedUploadNames.forEach(name => {syncReportMessages.push(`Upload failed for ${name}`)})
          setErrorAlertMessage('One or more uploads failed, see report')
          setErrorAlert(true)
        }
        setSuccessAlertMessage(`Downloaded: ${rsp.downloadedNames.length}, Uploaded: ${rsp.uploadedNames.length}`)
        setSyncReportData(syncReportMessages)
        setSyncReportOpen(true)
      }
      setSuccessAlert(true)
    })
    .catch(error => {
      console.error(`syncUserAccount caught error: ${error}`);
      setErrorAlert(true)
      setErrorAlertMessage(`Error occurred during syncing: ${error}`)
    })
    .finally(() => {
      setLoading(false)
      setStorageKeys(storageGetFormulaNames())
    })
  }

  function onUploadFileChange(evt) {
    if (evt.target.files && evt.target.files.length > 0) {
      const uploadFile = evt.target.files[0]
      setUploadFile(uploadFile)
      // reset input value to permit multiple uploads of the same file
      evt.target.value = null
    } else {
      console.info(`upload file selection not detected!`)
    }
  }

  function importFormulas() {
    if (!uploadFile) {
      console.info(`No upload file selected!`)
      return
    }
    const fileReader = new FileReader()
    fileReader.readAsText(uploadFile, 'UTF-8')
    fileReader.onload = e => {
      const fileContents = e.target.result.trim()
      const expectedFieldsRegex = /"formName"/
      if (!fileContents.startsWith('[') || !fileContents.match(expectedFieldsRegex) || !fileContents.endsWith(']')) {
        setInvalidUploadFile(true)
        return
      }
      importFormulasToLocalStorage(JSON.parse(fileContents))
    }
  }

  function removeUploadFile() {
    setUploadFile(null)
  }

  function importFormulasToLocalStorage(formsArray) {
    putStorageCollectionJson(formsArray, 'formName', auth)
    setStorageKeys(storageGetFormulaNames())
    setFileImportComplete(true)
  }

  function onFormTypeChange(evt) {
    applyTypeFilter(evt.target.value)
  }

  function deleteClick(itemKey) {
    setActionableFormName(itemKey)
    setOpenDeleteConfirm(true)
  }

  function cancelDelete() {
    setActionableFormName(undefined)
    setOpenDeleteConfirm(false)
  }

  function doDelete() {
    handleFormRemoveFailure(storageRemove(actionableFormName, auth))
    setStorageKeys(storageKeys.filter((k) => k !== actionableFormName))
    setActionableFormName(undefined)
    setOpenDeleteConfirm(false)
    clearUnknownSessionForm(actionableFormName)
  }

  function copyRenameClick(itemKey) {
    setActionableFormName(itemKey)
    setCandidateFormName(`${itemKey} New`)
    setOpenCopyRenameDialog(true)
  }

  function doCopy() {
    const candidateFormNameTrimmed = candidateFormName.trim()
    if (storageKeys.includes(candidateFormNameTrimmed)) {
      setErrorAlert(true)
      setErrorAlertMessage(`Formula ${candidateFormNameTrimmed} already exists, try another name.`)
      setActionableFormName(undefined)
      setOpenCopyRenameDialog(false)
      return
    }
    const sourceFormula = storageMap[actionableFormName]
    const destFormula = {...sourceFormula, formName: candidateFormNameTrimmed, createdAt: getLocalDate().getTime()}
    delete destFormula.modifiedAt
    handleFormPutFailure(storagePut(candidateFormNameTrimmed, destFormula, auth))
    storageKeys.unshift(candidateFormNameTrimmed)
    setStorageKeys(storageKeys)
    setActionableFormName(undefined)
    setOpenCopyRenameDialog(false)
  }

  function doRename() {
    const candidateFormNameTrimmed = candidateFormName.trim()
    if (storageKeys.includes(candidateFormNameTrimmed)) {
      setErrorAlert(true)
      setErrorAlertMessage(`Formula ${candidateFormNameTrimmed} already exists, try another name.`)
      setActionableFormName(undefined)
      setOpenCopyRenameDialog(false)
      return
    }
    const sourceFormula = storageMap[actionableFormName]
    const modifiedSourceFormula = {...sourceFormula, formName: candidateFormNameTrimmed, modifiedAt: getLocalDate().getTime()}
    handleFormPutFailure(storagePut(candidateFormNameTrimmed, modifiedSourceFormula, auth))
    handleFormRemoveFailure(storageRemove(actionableFormName, auth))
    clearUnknownSessionForm(actionableFormName)
    storageKeys.unshift(candidateFormNameTrimmed)
    setStorageKeys(storageKeys.filter((k) => k !== actionableFormName))
    setActionableFormName(undefined)
    setOpenCopyRenameDialog(false)
  }

  function downloadItemClick(itemKey) {
    const element = document.createElement("a");
    const file = new Blob([`[${JSON.stringify(storageMap[itemKey])}]`], {type: 'application/json'});
    element.href = URL.createObjectURL(file);
    element.download = `${exportFileNamePrefix}-${itemKey}-${getLocalDateStr()}.json`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    document.body.removeChild(element)
  }

  function getUrlFromFormType() {
    const navObj = formTypeUrlMap.find((ft) => ft.formType === formType)
    if (navObj === undefined) {
      console.error(`No URL found for formType ${formType}!`)
      return undefined
    }
    return navObj.url
  }

  function renderSingleItem(key, ix) {
    let fieldName = storageMap[key].hasOwnProperty('modifiedAt') ? 'modifiedAt' : 'createdAt'

    const itemDateStr = getDateTimeStr(new Date(storageMap[key][fieldName]))
    return (
      <ListItem sx={{paddingLeft: 0, paddingRight: 5}} key={ix} id={key} secondaryAction={
        <span>
        <IconButton onClick={() => deleteClick(key)} edge="end">
          <DeleteIcon />
        </IconButton>
        <IconButton sx={{marginLeft: 1.5}} onClick={() => copyRenameClick(key)} edge="end">
          <ContentCopyIcon />
        </IconButton>
        <IconButton sx={{marginLeft: 1.5, marginTop: 0.5}} onClick={() => downloadItemClick(key)} edge="end">
          <FileDownloadIcon/>
        </IconButton>
        </span>
      }>
        <Link to={`${getUrlFromFormType()}/${window.btoa(key)}`} style={{textDecoration: 'none', color: 'white'}} >
          <ListItemButton sx={{marginLeft: -2}} divider={false}>
            <ListItemText secondary={itemDateStr} primary={key}/>
          </ListItemButton>
        </Link>
      </ListItem>
    )
  }

  function dismissInvalidFile(event, reason) {
    if (reason === 'clickaway') {
      return
    }
    setInvalidUploadFile(false)
  }

  function dismissImportComplete(event, reason) {
    if (reason === 'clickaway') {
      return
    }
    setFileImportComplete(false)
    setUploadFile(undefined)
  }

  async function handleFormPutFailure(promise) {
    promise.then(() => {})
    .catch((e) => {
      console.error(`storagePut API error occurred for ${actionableFormName}: ${e}`)
      setErrorAlert(true)
      setErrorAlertMessage(`storagePut API error occurred for ${actionableFormName}: ${e}`)
    })
  }

  async function handleFormRemoveFailure(promise) {
    promise.then(() => {})
    .catch((e) => {
      console.error(`storageRemove API error occurred for ${actionableFormName}: ${e}`)
      setErrorAlert(true)
      setErrorAlertMessage(`storageRemove API error occurred for ${actionableFormName}: ${e}`)
    })
  }

  function dismissErrorAlert(event, reason) {
    setErrorAlert(false)
    setErrorAlertMessage(undefined)
  }

  function dismissSuccessAlert(event, reason) {
    setSuccessAlert(false)
    setSuccessAlertMessage(undefined)
  }

  function onLabelChange(event) {
    const { target: {value} } = event;
    applyLabelFilter(typeof value === 'string' ? value.split(',') : value);
  }

  function getLabelMenuStyles(name, selectedLabels, theme) {
    const isSelected = selectedLabels.includes(name);
    return {
      fontWeight: isSelected
              ? theme.typography.fontWeightMedium
              : theme.typography.fontWeightRegular,
      backgroundColor: isSelected
              ? theme.palette.secondary.main
              : 'white'
      }
  }

  function onSortTypeToggleChange(evt, value) {
    if (value !== null) {
      applySortType(value)
      applySortDescending(value === sortTypeAccessTime)
    }
  }

  // establish visible items before rendering anything
  {storageKeys
  .filter((formName) => {
    if (storageMap[formName].formType !== formType) {
      return false;
    }
    if (formNameFilter?.length > 0 && !formName.toLowerCase().includes(formNameFilter.toLowerCase())) {
      return false
    }
    return !(formLabelFilters?.length > 0 && !arrayHasIntersectionIgnoreCase(formLabelFilters, storageMap[formName].labels));
  })
  .forEach((k, ix) => {
    visibleItems.push(k)
  })}

  function dismissSyncReport() {
    setSyncReportData([])
    setSyncReportOpen(false)
  }

  return (
          <div>
            <Grid container direction={'column'}>
              <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} autoHideDuration={5000} open={uploadFile && invalidUploadFile} onClose={dismissInvalidFile}>
                  <Alert sx={{marginTop: '1rem'}} severity={'error'} onClose={dismissInvalidFile}>{`File ${uploadFile?.name} is invalid. Must be a file previously exported from this screen on any device`}</Alert>
              </Snackbar>
              <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} autoHideDuration={3000} open={uploadFile && fileImportComplete} onClose={dismissImportComplete}>
                <Alert sx={{marginTop: '1rem'}} severity={'success'} onClose={dismissImportComplete}>{`File ${uploadFile?.name} imported successfully`}</Alert>
              </Snackbar>
              <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} autoHideDuration={4000} open={errorAlert} onClose={dismissErrorAlert}>
                <Alert sx={{marginTop: '1rem'}} severity={'error'} onClose={dismissErrorAlert}>{errorAlertMessage}</Alert>
              </Snackbar>
              <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} autoHideDuration={4000} open={successAlert} onClose={dismissSuccessAlert}>
                <Alert sx={{marginTop: '1rem'}} severity={'success'} onClose={dismissSuccessAlert}>{successAlertMessage}</Alert>
              </Snackbar>
            </Grid>
            <Grid container direction={'column'}>
                <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'left'}} open={syncReportOpen} onClose={dismissSyncReport}>
                  <List dense sx={{marginBottom: '2rem', height: 300, overflowX: 'hidden', overflowY: 'auto'}}>
                    <SnackbarContent key={'report'} message={'Sync Results...'} open={syncReportOpen} action={(<IconButton size={'small'} onClick={dismissSyncReport}><CloseIcon fontSize={'small'}/> </IconButton>)}/>
                    {syncReportData.map((rd, ix) => (<SnackbarContent open={syncReportOpen} key={ix} message={rd} sx={{maxWidth: 230}} />))}
                  </List>
                </Snackbar>
            </Grid>
            <Box component="form"
                 sx={{
                   '& .MuiTextField-root': {m: 1},
                   display: 'flex', flexDirection: 'column', alignItems: 'center'
                 }}
                 noValidate
                 autoComplete="off">
              <div>
                <Grid item container direction={'row'} marginTop={1.5} justifyContent={'center'} columns={1}>
                  <Typography variant={'h5'}>Saved Formulas</Typography>
                </Grid>
                <Grid item container direction={'row'} marginTop={0.1} justifyContent={'center'} columns={4} spacing={'0.75rem'}>
                  <Grid item xs={1}>
                    <Tooltip title={`Export ${getExportFormulaTypeDescription()} formulas`} placement={'top-start'} arrow>
                      <span>
                      <IconButton disabled={!hasStoredItems()} onClick={exportFormulas}><FileDownloadIcon fontSize={'large'}/></IconButton>
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip title={'Choose file for Import'} placement={'top-start'} arrow>
                      <FormControl sx={{width: '4ch'}}>
                      <TextField  variant={'standard'}
                              InputProps={{ sx:{height: '2rem', width: 0},
                                startAdornment: (
                                        <IconButton component="label">
                                          <PublishIcon fontSize={'large'}/>
                                          <input style={{display:"none"}}
                                                 type="file"
                                                 hidden
                                                 onChange={onUploadFileChange}
                                                 name="fileInput"
                                          />
                                        </IconButton>
                                )}}/>
                      </FormControl>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip title={'Sync with cloud account'} placement={'top-start'} arrow>
                      <Grid item marginLeft={1.5}>
                        <IconButton disabled={!auth.isAuthenticated} onClick={syncUserAccount}><CloudSyncIcon fontSize={'large'}/></IconButton>
                      </Grid>
                    </Tooltip>
                  </Grid>
                </Grid>

                {loading && (
                    <Backdrop sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })} open>
                      <CircularProgress
                              size={36} thickness={5}
                              sx={{
                                color: 'primary',
                                zIndex: 1,
                              }}
                      />
                    </Backdrop>
                )}
                {uploadFile && (
                  <Grid item container marginTop={'0.5rem'} direction={'row'} spacing={'0.75rem'} justifyContent={'flex-start'} columns={4}>
                    <Grid item>
                    <Tooltip title={'Import to Saved items'} placement={'top'} arrow>
                      <Chip label={uploadFile?.name} variant={'filled'} onClick={importFormulas} onDelete={removeUploadFile}/>
                    </Tooltip>
                    </Grid>
                  </Grid>
                 )}
              </div>
              <FormControl sx={{marginTop: '0'}}>
                <RadioGroup aria-labelledby="form-type-group-label" row
                            defaultValue={formTypeRatioDough}
                            value={formType}
                            name="formType" onChange={onFormTypeChange}>
                  <FormControlLabel value={formTypeRatioDough} control={<Radio size={'small'}/>}
                                    label={<Typography sx={{fontSize: 14}}>%Bakers</Typography>}/>
                  <FormControlLabel value={formTypeWeightDough} control={<Radio size={'small'}/>}
                                    label={<Typography sx={{fontSize: 14}}>#Weight</Typography>}/>
                  <FormControlLabel value={formTypeLevain} control={<Radio size={'small'}/>}
                                    label={<Typography sx={{fontSize: 14}}>Levain</Typography>}/>
                </RadioGroup>
              </FormControl>
              <Grid item container direction={'row'} justifyContent={'center'}>
                <Grid item>
                  <TextField type={'text'} size={'small'}
                                 sx={{width: '20ch'}}
                                 placeholder={'Formula Name'}
                                 value={formNameFilter}
                                 onChange={(e) => applyNameFilter(e.target.value)}
                                 variant="outlined"
                                 InputProps={{
                                    style: {color: theme.palette.secondary.main},
                                    startAdornment: (
                                            <InputAdornment position="start">
                                              <SearchIcon/>
                                            </InputAdornment>
                                    ),
                                    endAdornment: formNameFilter && (
                                       <IconButton
                                           aria-label="clear search"
                                           onClick={() => applyNameFilter('')}
                                       ><CancelIcon/></IconButton>
                                 )
                                 }}
                  />
                </Grid>
                <Grid item>
                  <FormControl size={'small'} sx={{width: '12ch', marginTop: 1}}>
                    <InputLabel id={'labelselectlabel'}>
                      <Typography fontSize={15} fontWeight={300}>{allDistinctFormLabels.length === 0 ? 'No Labels' : 'Labels'}</Typography>
                    </InputLabel>
                    <Select labelId={'labelselect'} id={'labeling'}
                      multiple
                      variant={'outlined'}
                      value={formLabelFilters}
                      onChange={onLabelChange}
                      input={<OutlinedInput id='selectMultLabels' label={allDistinctFormLabels.length === 0 ? 'No Labels' : 'Labels'}/>}
                      renderValue={(selected) => (
                          <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                            {selected.map(value => (
                              <Chip color={'secondary'} size={'small'} key={value} label={value}/>
                            ))}
                          </Box>
                      )}
                    >
                      {allDistinctFormLabels.sort().map(label => (
                        <MenuItem sx={{fontSize: 14}} key={label} value={label} style={getLabelMenuStyles(label, formLabelFilters, theme)}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid marginTop={0.5} paddingBottom={1} item container direction={'row'} justifyContent={'center'}>
                <Typography fontSize={14} fontWeight={300} fontStyle={'italic'}>{`Matching ${visibleItems.length} item(s)`}</Typography>
                <FormToggleButton value={'sortToggle'} selected={sortDescending} selectedcolor={theme.palette.primary.main}
                                  sx={{marginLeft: 2, paddingLeft: 0.2, paddingRight: 0.2, paddingTop: 0.1, paddingBottom: 0.1}}
                              size={'small'} disabled={!hasStoredItems()}
                              onChange={() => applySortDescending(!sortDescending)}>
                  {sortDescending ? <SortDescendingIcon fontSize={'small'}/> : <SortAscendingIcon color={'secondary'} fontSize={'small'}/>}
                </FormToggleButton>
                <ToggleButtonGroup exclusive value={sortType} sx={{marginLeft: -1}} onChange={onSortTypeToggleChange} disabled={!hasStoredItems()}>
                  <FormToggleButton value={sortTypeAccessTime} selectedcolor={theme.palette.primary.main}
                                    sx={{marginLeft: 2, paddingLeft: 0.5, paddingRight: 0.5, paddingTop: 0.1, paddingBottom: 0.1}}
                                size={'small'}>
                    <AccessTimeIcon fontSize={'small'}/>
                  </FormToggleButton>
                  <FormToggleButton value={sortTypeAlpha} selectedcolor={theme.palette.primary.main}
                                    sx={{marginLeft: 2, paddingLeft: 0.5, paddingRight: 0.5, paddingTop: 0.1, paddingBottom: 0.1}}
                                size={'small'}>
                    <SortByAlphaIcon fontSize={'small'}/>
                  </FormToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <List dense sx={{
                marginBottom: '1rem',
                width: '32ch',
                maxWidth: 600,
                height: 400,
                overflowX: 'hidden',
                overflowY: 'auto'
              }}>
                {visibleItems
                .sort((a, b) => {
                  const nameA =  storageMap[a]['formName']
                  const nameB =  storageMap[b]['formName']
                  if (sortType === sortTypeAlpha) {
                    return sortDescending ? nameB.localeCompare(nameA) : nameA.localeCompare(nameB)
                  }
                  const accessTimeA = storageMap[a].hasOwnProperty('modifiedAt') ? storageMap[a]['modifiedAt'] : storageMap[a]['createdAt']
                  const accessTimeB = storageMap[b].hasOwnProperty('modifiedAt') ? storageMap[b]['modifiedAt'] : storageMap[b]['createdAt']
                  return sortDescending ? accessTimeB - accessTimeA : accessTimeA - accessTimeB
                })
                .map((k, ix) => {
                  return renderSingleItem(k, ix)
                })}
              </List>
            </Box>
            <ConfirmDialog title={`Deleting '${actionableFormName}'`}
                           confirmMessage={`Are you sure?`}
                           confirm={doDelete}
                           confirmButtonName={'Delete'}
                           cancel={cancelDelete}
                           cancelButtonName={'Cancel'}
                           dialogOpen={openDeleteConfirm}/>
            <PromptFormDialog message={`Unique name for copy or rename...`}
                              fieldName={'Formula Name'}
                              formValue={candidateFormName}
                              setFormValue={setCandidateFormName}
                              onAction1={doCopy}
                              onAction2={doRename}
                              onCancel={() => {}}
                              dialogOpen={openCopyRenameDialog}
                              setDialogOpen={setOpenCopyRenameDialog}
                              action1ButtonLabel={'Copy'}
                              action2ButtonLabel={'Rename'}/>

          </div>
  )
}

export {SavedItems}