import * as React from 'react';
import {
  Card, CardActions, CardContent, CardMedia, Collapse,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material";
import oneloaf from '../../images/oneloaf-mq.jpeg'
import {WheatIcon} from "../icon/WheatIcon";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useState} from "react";
import IconButton from "@mui/material/IconButton";
import {styled} from "@mui/material/styles";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function Home(props) {
  const [expanded, setExpanded] = useState(false);
  function handleExpandClick() {
    setExpanded(!expanded);
  }

  return (
    <Grid container direction={'column'} padding={2} paddingLeft={{xs: '5%', sm: '10%', md: '15%', lg: '20%'}} paddingRight={{xs: '5%', sm: '10%', md: '15%', lg: '20%'}} justifyContent={'center'} alignItems={'center'}
          rowSpacing={1}>
      <Grid item xs={4} sm={8} md={12}>
        <Typography variant={'h5'}>Bread Dough Formulas</Typography>
      </Grid>
      <Grid item xs={4} sm={8} md={12} marginBottom={1}>
        <Typography display={'block'} variant={'body1'} fontStyle={'italic'}>For sourdough geeks like me!</Typography>
      </Grid>
      <Card variant={'elevation'} elevation={5} sx={{maxWidth: 355}}>
        <CardMedia
                component="img"
                height="200"
                image={oneloaf}
        />
        <CardContent sx={{marginTop: -1, marginBottom: -3, width: '100%'}}>
          <List>
            <ListItem dense={true}>
              <ListItemIcon sx={{marginLeft: -2}}>
                <WheatIcon/>
              </ListItemIcon>
              <ListItemText sx={{marginLeft: -2}}>Create scalable % based formulas</ListItemText>
            </ListItem>
            <ListItem dense={true}>
              <ListItemIcon sx={{marginLeft: -2}}>
                <WheatIcon/>
              </ListItemIcon>
              <ListItemText sx={{marginLeft: -2}}>Convert fixed weight formula to % formula</ListItemText>
            </ListItem>
            <ListItem dense={true}>
              <ListItemIcon sx={{marginLeft: -2}}>
                <WheatIcon />
              </ListItemIcon>
              <ListItemText sx={{marginLeft: -2}}>Levain and dough maturity time projections</ListItemText>
            </ListItem>
            <ListItem dense={true}>
              <ListItemIcon sx={{marginLeft: -2}}>
                <WheatIcon/>
              </ListItemIcon>
              <ListItemText sx={{marginLeft: -2}}>Renders printable recipe with screen lock</ListItemText>
            </ListItem>
            <ListItem dense={true}>
              <ListItemIcon sx={{marginLeft: -2}}>
                <WheatIcon/>
              </ListItemIcon>
              <ListItemText sx={{marginLeft: -2}}>Save and manage your formulas</ListItemText>
            </ListItem>
          </List>
        </CardContent>
        <CardActions disableSpacing>
          <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more">
           <ExpandMoreIcon/>
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>
              This app provides a variety of formula calculators to do the ratio math.<br/><br/>
              You can also use the app as your formula catalog.
              Save and keep what you want, making temporary or permanent adjustments for each bake.<br/><br/>
              I use the app as a paperless notebook to record each formula's process details,
              observations, and reminders.<br/><br/>
              I hope you find it useful!
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
      <Grid item xs={4} sm={8} md={12}>
        <Typography variant={'h6'}>Happy Baking!</Typography>
      </Grid>

    </Grid>
  )
}

export { Home }