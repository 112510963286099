import * as React from 'react';
import PropTypes from "prop-types";
import {
  Box, Grid,
  Table, TableBody, TableContainer, TableRow, Typography
} from "@mui/material";
import {RecipeSummaryTableCell} from "./RecipeTableCell";

function RecipeSummaryTable(props) {
  const {items} = props

  const isEffHydrationDiff = (Math.abs(items.effectiveRecipeHydrationPct - items.mainDough.hydrationPct) >= 1)
  return (
     <Grid item container direction={'row'} spacing={1} columns={{xs: 4, sm: 8, md: 8}}>
       <Grid item xs={2} sm={4} md={4}>
         <TableContainer>
           <Table size={"small"}>
             <TableBody>
               <TableRow>
                 <RecipeSummaryTableCell>Hydration</RecipeSummaryTableCell>
                 <RecipeSummaryTableCell align={'right'}>
                   <Box component="span" fontWeight='fontWeightBold'>{items.mainDough.hydrationPct}%</Box>
                 </RecipeSummaryTableCell>
               </TableRow>
               {isEffHydrationDiff ? (
                 <TableRow>
                   <RecipeSummaryTableCell>Eff. Hydratn* </RecipeSummaryTableCell>
                   <RecipeSummaryTableCell align={'right'}>
                     <Box component="span">{items.effectiveRecipeHydrationPct}%</Box>
                   </RecipeSummaryTableCell>
                 </TableRow>
               ) : null}
               <TableRow>
                 <RecipeSummaryTableCell>Flour</RecipeSummaryTableCell>
                 <RecipeSummaryTableCell align={'right'}>{items.recipeFlourTotalWeight}g</RecipeSummaryTableCell>
               </TableRow>
               <TableRow>
                 <RecipeSummaryTableCell>Liquid</RecipeSummaryTableCell>
                 <RecipeSummaryTableCell align={'right'}>{items.recipeLiquidTotalWeight}g</RecipeSummaryTableCell>
               </TableRow>
               {items.recipeYeastTotalWeight === 0 ? null
                 : (
                     <TableRow>
                       <RecipeSummaryTableCell>Dry Yeast</RecipeSummaryTableCell>
                       <RecipeSummaryTableCell align={'right'}>{items.recipeYeastTotalWeight}g</RecipeSummaryTableCell>
                     </TableRow>
                 )
               }
               {items.recipeStarterTotalWeight === 0 ? null
                 : (
                     <TableRow>
                       <RecipeSummaryTableCell>{items.includePreferment ? 'Starter' : 'Levain'}</RecipeSummaryTableCell>
                       <RecipeSummaryTableCell align={'right'}>{items.recipeStarterTotalWeight}g</RecipeSummaryTableCell>
                     </TableRow>
                 )
               }
               {items.recipeMixinTotalWeight === 0 ? null
                 : (
                     <TableRow>
                       <RecipeSummaryTableCell>Inclusions</RecipeSummaryTableCell>
                       <RecipeSummaryTableCell align={'right'}>{items.recipeMixinTotalWeight}g</RecipeSummaryTableCell>
                     </TableRow>
                   )
               }
               {items.recipeSoakerTotalWeight === 0 ? null
                 : (
                     <TableRow>
                       <RecipeSummaryTableCell>Soaker</RecipeSummaryTableCell>
                       <RecipeSummaryTableCell align={'right'}>{items.recipeSoakerTotalWeight}g</RecipeSummaryTableCell>
                     </TableRow>
                   )
               }
             </TableBody>
           </Table>
         </TableContainer>
         {isEffHydrationDiff ? (
           <Typography sx={{ fontSize: 10}}>*including starter flour and water</Typography>
         ) : null}
       </Grid>
       <Grid item xs={2} sm={4} md={4}>
         <TableContainer>
           <Table size={"small"}>
             <TableBody>
               <TableRow>
                 <RecipeSummaryTableCell>Dough Units</RecipeSummaryTableCell>
                 <RecipeSummaryTableCell align={'right'}>{items.numBatches}</RecipeSummaryTableCell>
               </TableRow>
               <TableRow>
                 <RecipeSummaryTableCell>Unit Weight</RecipeSummaryTableCell>
                 <RecipeSummaryTableCell align={'right'}>{items.recipeUnitWeight}g</RecipeSummaryTableCell>
               </TableRow>
               <TableRow>
                 <RecipeSummaryTableCell>Total Weight</RecipeSummaryTableCell>
                 <RecipeSummaryTableCell align={'right'}>{items.recipeTotalWeight}g</RecipeSummaryTableCell>
               </TableRow>
             </TableBody>
           </Table>
         </TableContainer>
       </Grid>
     </Grid>
    )
}

RecipeSummaryTable.propTypes = {
  items: PropTypes.shape({
    recipeName: PropTypes.string,
    notes: PropTypes.string,
    numBatches: PropTypes.number,
    batchSize: PropTypes.number,
    includePreferment: PropTypes.bool,
    recipeFlourTotalWeight: PropTypes.number,
    recipeLiquidTotalWeight: PropTypes.number,
    recipeStarterTotalWeight: PropTypes.number,
    recipeYeastTotalWeight: PropTypes.number,
    recipeMixinTotalWeight: PropTypes.number,
    recipeSoakerTotalWeight: PropTypes.number,
    recipeTotalWeight: PropTypes.number
  }),
  visible: PropTypes.bool
}

export { RecipeSummaryTable }