import {useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import Button from "@mui/material/Button";

function PromptFormDialog(props) {
  const {message, fieldName, formValue, setFormValue, onAction1, onAction2, onCancel,
         dialogOpen, setDialogOpen, action1ButtonLabel, action2ButtonLabel} = props
  const [isError, setIsError] = useState(false)
  const [isAction1Disabled, setIsAction1Disabled] = useState(false)
  const [isAction2Disabled, setIsAction2Disabled] = useState(false)

  const handleCancel = () => {
    setDialogOpen(false)
    setIsError(false)
    setIsAction1Disabled(false)
    setIsAction2Disabled(false)
    onCancel()
  };

  const onAction1Click = () => {
    setDialogOpen(false)
    setIsError(false)
    setIsAction1Disabled(false)
    setIsAction2Disabled(false)
    onAction1()
  };

  const onAction2Click = () => {
    setDialogOpen(false)
    setIsError(false)
    setIsAction1Disabled(false)
    setIsAction2Disabled(false)
    onAction2()
  };

  function onFieldChange(evt) {
    const newValue = evt.target.value
    const isValid = newValue?.trim()
    setIsError(!isValid)
    setIsAction1Disabled(!isValid)
    setIsAction2Disabled(!isValid)
    setFormValue(newValue)
  }

  return (
    <div>
      <Dialog open={dialogOpen} onClose={handleCancel}>
        <DialogTitle>{fieldName}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
          <TextField
                  autoFocus
                  sx={{width: '40ch'}}
                  margin="dense"
                  id={`prompt-form-id`}
                  label={fieldName}
                  value={formValue}
                  onChange={onFieldChange}
                  fullWidth
                  variant="outlined"
                  error={isError}
                  helperText={`${isError ? 'Name required' : ''}`}
          />
        </DialogContent>
        <DialogActions>
          <Button variant={"outlined"} onClick={handleCancel}>Cancel</Button>
          <Button variant={"outlined"} disabled={isAction1Disabled} onClick={onAction1Click}>{action1ButtonLabel}</Button>
          {action2ButtonLabel ? (
            <Button variant={"outlined"} disabled={isAction2Disabled} onClick={onAction2Click}>{action2ButtonLabel}</Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export {PromptFormDialog}