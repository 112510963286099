import {compressJsonGz, compressJsonLz, decompressJsonGz, decompressJsonLz} from "./CompressionUtils";
import {
  apiBatchPutFormulas,
  apiDeleteFormula, apiFormAttr,
  apiFormNameAttr,
  apiGetAllFormulas, apiLastModifiedAttr,
  apiPutFormula
} from "../http/HttpClient";
import {sleep} from "./AsyncUtils";

export const PRINT_DOUGH_SESS_KEY = 'current-dough-recipe'
export const PRINT_LEVAIN_SESS_KEY = 'current-levain-recipe'
const apiFormulaPutBatchSize = 25;
const apiFormulaGetBatchSize = 25;

// read from local storage only
function storageGet(formulaName) {
  const compressedJsonStr = localStorage.getItem(formulaName)
  if (!compressedJsonStr) {
    return compressedJsonStr
  }
  return decompressJsonLz(compressedJsonStr)
}

// check local storage for formula name
function storageHasFormulaName(formulaName) {
  const item = localStorage.getItem(formulaName)
  return !!item
}

// get all formula names from local storage only
// Note: workaround for oidc usage of local storage is to filter out keys that begin with "oidc.*"
// Observed example:
// oidc.7ef0d6289ed246b9b52a1949ab93089a : {"id":"7ef0d6289ed246b9b52a1949ab93089a","created":1736730687,"request_type":"si:r","code_verifier":"774afa825610465e96ee7b5ae3fc3610fae7c5dba07d4c2cb855332a3aefd17710d5e646a70d404eb0bbda9a7982d8f9","authority":"https://cognito-idp.us-east-1.amazonaws.com/us-east-1_Xkm2jTZvK","client_id":"360hbe4405esoq4g40jeka8009","redirect_uri":"https://d16u3wluyn6i0q.cloudfront.net/signin","scope":"openid email","extraTokenParams":{}}
function storageGetFormulaNames() {
  return Object.keys(localStorage).filter((key) => !key.startsWith('oidc'))
}

// write to local storage AND to server if user is authenticated
async function storagePut(formulaName, jsonObject, auth) {
  if (!jsonObject) {
    console.error(`storagePut: local storage not set for ${formulaName} - no incoming object`)
    return;
  }
  localStorage.setItem(formulaName, compressJsonLz(jsonObject))

  if (auth?.isAuthenticated) {
    const gzipSerializedFormula = compressJsonGz(jsonObject)
    const lastModified = Object.hasOwn(jsonObject, 'modifiedAt') ? jsonObject['modifiedAt'] : jsonObject['createdAt'];
    return apiPutFormula(auth, formulaName, lastModified, gzipSerializedFormula);
  } else {
    return new Promise((resolve, reject) => {resolve('API call skipped, not authenticated')});
  }
}

// delete from local storage AND from server if user is authenticated
async function storageRemove(formulaName, auth) {
  localStorage.removeItem(formulaName)
  if (auth.isAuthenticated) {
    return apiDeleteFormula(auth, formulaName)
  } else {
    return new Promise((resolve, reject) => {resolve('API call skipped, not authenticated')});
  }
}

/**
 * Returns an array of stringified JSON objects from local storage according to the filter, if provided, and sort params.
 * Each object is keyed by the local storage key, with the storage object as its value
 * @param filterKeyName
 * @param filterKeyValue
 * @param sortKeyName
 * @param sortAsc
 * @returns {*[]}
 */
function getStorageCollectionJson(filterKeyName, filterKeyValue, sortKeyName, sortAsc) {
  const storageKeys = storageGetFormulaNames()
  const storageMap = {}
  storageKeys.forEach(k => {
    storageMap[k] = storageGet(k)
  })

  const jsonResult = []
  storageKeys
  .filter((k) => {
    if (filterKeyName && filterKeyValue) {
      return storageMap[k][filterKeyName] === filterKeyValue
    }
    return true
  })
  .sort((a, b) => {
    if (sortAsc && sortKeyName) {
      return storageMap[a][sortKeyName] - storageMap[b][sortKeyName]
    } else if (sortKeyName) {
      return storageMap[b][sortKeyName] - storageMap[a][sortKeyName]
    } else {
      return 0
    }
  })
  .forEach((k, ix) => {
    jsonResult.push(JSON.stringify(storageMap[k]))
  })
  return jsonResult
}

/**
 * Accepts an object array and key field name expected to be found in each object in objArray.
 * Uses the value found for keyFieldName, if any, and sets the stringified object into local storage.
 * Returns the count of objects saved into local storage
 * @param objArray
 * @param keyFieldName
 * @param auth
 * @returns {number}
 */
function putStorageCollectionJson(objArray, keyFieldName, auth) {

  objArray.forEach((obj, ix) => {
    if (obj.hasOwnProperty(keyFieldName)) {
      let storageKey = obj[keyFieldName]
      // skip individual API calls (no auth) and use batch API put below
      storagePut(storageKey, obj, undefined)
    }
  })

  // NOTE: tentatively not going to automatically push imported formulas to the account via API calls
  //  This is to risk overwriting later versions and to avoid excess API/DDB access and storage.
  //  If the user is importing, let the user curate and sync the newly-imported items after a potentially large or redundant import.
  //  On the other hand, if we're going to automatically sync copies and deletes from the Saved Items screen, should
  //   either automatically sync imports... OR make it clear we are NOT via messaging

  // if (auth?.isAuthenticated && objArray?.length > 0) {
  //   batchPutAccountFormulas(objArray, auth)
  //   console.log(`putStorageCollectionJson: invoked batchPutAccountFormulas to sync with user account in the background...`)
  // }
  // console.log(`putStorageCollectionJson: completed write to local storage for ${objArray.length} formulas`)
}

async function syncStorageWithAccount(auth) {
  const accountFormulas = await batchGetAllAccountFormulas(auth)
  const accountFormulaMap = {}
  // create a map of current account storage formulas keyed by formula name
  //   object fields: "formName", "form", and "lastModified" ("form" data is GZip compressed)
  for (const obj of accountFormulas) {
    accountFormulaMap[obj[apiFormNameAttr]] = obj
  }
  // console.log(`sync: downloaded ${Object.keys(accountFormulaMap).length} formulas from the account`)

  const newAccountFormulas = []
  const localFormulaNames = storageGetFormulaNames()
  const downloadedFormNames = [];
  // compare current local storage to account storage...
  for (const formulaName of localFormulaNames) {
    const formulaObj = storageGet(formulaName)
    const localLastModified = formulaObj.hasOwnProperty('modifiedAt') ? formulaObj['modifiedAt'] : formulaObj['createdAt']
    const formExistsInAccount = accountFormulaMap.hasOwnProperty(formulaName)
    if (!formExistsInAccount) {
      // console.log(`sync, formName=${formulaName} not found in account, queueing for upload`)
      newAccountFormulas.push(formulaObj)
    } else {
      // formula exists both in account and in local...
      const accountLastModified = accountFormulaMap[formulaName][apiLastModifiedAttr]
      // console.log(`sync, formName=${formulaName} exists in both places, localLastMod=${getDateTimeStr(new Date(localLastModified))}, accountLastMod=${getDateTimeStr(new Date(accountLastModified))}`)
      if (localLastModified > accountLastModified) {
        // console.log(`sync, formName=${formulaName}, local version is newer, queueing for upload`)
        // queue it for upload
        newAccountFormulas.push(formulaObj)
      } else if (localLastModified < accountLastModified) {
        // write account version to local
        // console.log(`sync, formName=${formulaName}, account version is newer, saving to local...`)
        const decompressedGz = decompressJsonGz(accountFormulaMap[formulaName][apiFormAttr])
        if (!decompressedGz) {
          console.error(`syncStorageWithAccount: ${formulaName} form GZ decompression produced empty/undefined result, skipping.`)
          continue;
        }
        storagePut(formulaName, decompressedGz, undefined)
        downloadedFormNames.push(formulaName)
      }
      // one less account formula to look at...
      delete accountFormulaMap[formulaName]
    }
  }

  // the remaining account formulas don't exist locally - just save them locally
  for (const formulaName of Object.keys(accountFormulaMap)) {
    // console.log(`sync, formName=${formulaName}, new from account, saving to local...`)
    const decompressedGz = decompressJsonGz(accountFormulaMap[formulaName][apiFormAttr])
    if (!decompressedGz) {
      console.error(`syncStorageWithAccount: ${formulaName} form GZ decompression produced empty/undefined result, skipping.`)
      continue;
    }
    storagePut(formulaName, decompressedGz, undefined)
    downloadedFormNames.push(formulaName)
  }

  if (newAccountFormulas.length > 0) {
    const unprocessedFormulaNames = await batchPutAccountFormulasWithRetries(newAccountFormulas, auth)
    let uploadedFormNames = newAccountFormulas.map((fo, ix) => fo.formName)
    if (unprocessedFormulaNames?.length > 0) {
      uploadedFormNames = uploadedFormNames.filter(name => !unprocessedFormulaNames.includes(name))
    }
    return {
      downloadedNames: downloadedFormNames,
      uploadedNames: uploadedFormNames,
      failedUploadNames: unprocessedFormulaNames
    }
  } else {
    return {
      downloadedNames: downloadedFormNames,
      uploadedNames: [],
      failedUploadNames: []
    }
  }
}

// Returns all formulas for the authenticated account and handles paging, synchronously, as needed based on
// the apiFormulaGetBatchSize limit (25).
// Note: the returned formulas are API-structured with fields "formName", "form", and "lastModified"
// The "form" field remains compressed as GZip serialized json upon return.
// The caller is responsible for decompressing as needed
async function batchGetAllAccountFormulas(auth) {
  const resultsArray = []
  let lastFetchArray = undefined
  let lastReadFormName = undefined
  do  {
    await apiGetAllFormulas(auth, apiFormulaGetBatchSize, lastReadFormName)
    .then(response => {
      lastFetchArray = response?.formItems
      if (lastFetchArray?.length > 0) {
        resultsArray.push.apply(resultsArray, lastFetchArray)
        lastReadFormName = response['lastReadFormName']
      }
    })
  } while (lastReadFormName !== undefined && lastFetchArray.length > 0);
  return resultsArray
}

async function batchPutAccountFormulasWithRetries(formulaArray, auth) {
  let formulaMap = {}
  // build a map keyed by formula name
  for (const formula of formulaArray) {
    formulaMap[formula.formName] = formula
  }
  let formulas = formulaArray
  let unprocessedFormulaNames
  let hasUnprocessedItems = true
  let unprocessedRetries = 0
  const maxRetries = 5
  while (hasUnprocessedItems && unprocessedRetries <= maxRetries) {
    unprocessedFormulaNames = await batchPutAccountFormulas(formulas, auth)
    if (!unprocessedFormulaNames?.length) {
      hasUnprocessedItems = false
    } else {
      console.info(`batchPutAccountFormulasWithRetries: pausing before retrying these unprocessed formulas: ${JSON.stringify(unprocessedFormulaNames)}`)
      await sleep(++unprocessedRetries * 1000)
      formulas = []
      for (const formulaName of unprocessedFormulaNames) {
        formulas.push(formulaMap[formulaName])
      }
    }
  }
  return unprocessedFormulaNames
}


// Sends an array of formulas to the batch API for saving to the authenticated user account.
// Note this function expects input in the form of an array of decompressed formula JSON objects.
// The function transforms formulas to an array of API-suitable objects, i.e. with fields "formName", "form", and "lastModified".
// The function also applies GZip compression of the form JSON.
// The API may be invoked in multiple batches, depending on the size of the input array.
// Batch sizing is based on apiFormulaPutBatchSize (25) to conform to the API/db limits per batch.
// Response is an array of unprocessed formula names accumulated from the api batch calls
async function batchPutAccountFormulas(formulaArray, auth) {
  let apiObjects = [];
  let countFormulas = 0;
  let countBatches = 0;
  const unprocessedFormulaNames = []
  const numBatches = Math.trunc(formulaArray.length / apiFormulaPutBatchSize) + ((formulaArray.length % apiFormulaPutBatchSize) !== 0 ? 1 : 0);
  for (const obj of formulaArray) {
    const apiObject = {
      [apiFormNameAttr]: obj.formName,
      [apiLastModifiedAttr]: obj.hasOwnProperty('modifiedAt') ? obj['modifiedAt'] : obj['createdAt'],
      [apiFormAttr]: compressJsonGz(obj)
    }
    apiObjects.push(apiObject)
    ++countFormulas;
    if (countFormulas % apiFormulaPutBatchSize === 0) {
      ++countBatches;
      await apiBatchPutFormulas(auth, apiObjects)
      .then(rsp => {
        const unprocessedItemsBatch = rsp?.['unprocessedItems']
        if (unprocessedItemsBatch?.length > 0) {
          unprocessedFormulaNames.push.apply(unprocessedFormulaNames, unprocessedItemsBatch)
        }
        // console.log(`batchPutFormulas, batch# ${countBatches} of ${numBatches} completed.  Unprocessed item count: ${unprocessedItemsBatch.length}`)
      })
      .catch(err => {
        console.error(`batchPutFormulas, batch# ${countBatches} of ${numBatches} error: ${err}`)
        throw err;
      })
      .finally(() => {
        apiObjects = []
      })
    }
  }
  if (apiObjects.length > 0) {
    ++countBatches;
    await apiBatchPutFormulas(auth, apiObjects)
    .then(rsp => {
      const unprocessedItemsBatch = rsp?.['unprocessedItems']
      if (unprocessedItemsBatch?.length > 0) {
        unprocessedFormulaNames.push.apply(unprocessedFormulaNames, unprocessedItemsBatch)
      }
      // console.log(`batchPutFormulas, batch# ${countBatches} of ${numBatches} completed.  Unprocessed item count: ${unprocessedItemsBatch.length}`)
    })
    .catch(err => {
      console.error(`batchPutFormulas, batch# ${countBatches} of ${numBatches} error: ${err}`)
      throw err;
    })
    apiObjects = undefined
  }
  return unprocessedFormulaNames
}

//
// Session Data
//

function sessionPut(key, jsonObject) {
  const compressedJsonStr = compressJsonLz(jsonObject)
  if (compressedJsonStr) {
    sessionStorage.setItem(key, compressedJsonStr)
  }
}

function sessionGet(key) {
  const compressedJsonStr = sessionStorage.getItem(key)
  if (!compressedJsonStr) {
    return compressedJsonStr
  }
  return decompressJsonLz(compressedJsonStr)
}

function sessionPutRaw(key, data) {
  sessionStorage.setItem(key, data)
}

function sessionGetRaw(key) {
  return sessionStorage.getItem(key)
}

function sessionRemove(key) {
  sessionStorage.removeItem(key)
}

function sessionHasKey(key) {
  return Object.keys(sessionStorage).includes(key)
}

export {storageGet, storagePut, storageRemove, storageHasFormulaName, storageGetFormulaNames,
        getStorageCollectionJson, putStorageCollectionJson, syncStorageWithAccount,
        sessionGet, sessionPut, sessionRemove, sessionHasKey, sessionGetRaw, sessionPutRaw}