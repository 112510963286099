import * as React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography
} from "@mui/material";
import PercentIcon from '@mui/icons-material/Percent';
import PersonIcon from '@mui/icons-material/Person';
import NumbersIcon from '@mui/icons-material/Numbers';
import SaveIcon from '@mui/icons-material/Save';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {GuestUserIcon} from "../icon/GuestUserIcon";
import CalculateIcon from "@mui/icons-material/Calculate";

function Help(props) {
  return (
    <Grid container direction={'column'} padding={2} paddingLeft={{xs: '5%', sm: '10%', md: '15%', lg: '20%'}} paddingRight={{xs: '5%', sm: '10%', md: '15%', lg: '20%'}} justifyContent={'center'} alignItems={'center'}
          rowSpacing={1.5}>
      <Grid item xs={4} sm={8} md={12}>
        <Typography variant={'h5'}>Help</Typography>
      </Grid>

      <Grid item xs={4} sm={8} md={12} marginBottom={1}>
        <Typography display={'block'} variant={'body1'} fontStyle={'italic'}>App features and How-Tos</Typography>
      </Grid>

      <Grid item xs={4} sm={8} md={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
            <Typography variant={'h6'}>Terminology</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant={'body1'}>
              <b>Bakers Percentage</b>
            </Typography>
            <Typography marginLeft={3} variant={'body2'}>
              Commonly used in baking, an ingredient's percentage as a ratio to the sum of the weight of all flour for the recipe, including the Main flour, and Preferment flour, if specified.
            </Typography>
            <Typography variant={'body1'}>
              <b>Bulk Fermentation</b>
            </Typography>
            <Typography marginLeft={3} variant={'body2'}>
              The initial phase of the main dough development where yeast feeding and carbon dioxide release occurs.
              This phase begins when you add your leavening agent to the flour and water mix.
              This phase ends after the dough has become smooth, elastic (almost jelly-like), and the volume has increased
              by 25-30%.  After dividing and shaping, the final proofing phase begins, where more expansion can occur.
            </Typography>
            <Typography variant={'body1'}>
              <b>Final Proof</b>
            </Typography>
            <Typography marginLeft={3} variant={'body2'}>
              Second and final phase of the main dough development where additional yeast activity occurs within the final shaped dough.
              Often in sourdough baking, final proofing is done in the refrigerator for 8 or more hours to enhance flavor,
              but can also be done at room temperature in as few as 1-2 hours.
              Final proofing is complete when the dough is elastic to the touch (poke test) and still has some yeast
              activity in reserve for the bake rise.
            </Typography>
            <Typography variant={'body1'}>
              <b>Float Test</b>
            </Typography>
            <Typography marginLeft={3} variant={'body2'}>
              A test to detect the readiness of a levain for use in a main dough mix.  Take a small amount of your
              levain (1/2 tsp or so) and drop into a beaker of water.  If it floats, it is mature enough to use - the amount of carbon dioxide from
              yeast production is adequate.  If it sinks, it either needs more time, or is past its peak.
              A levain past its peak will have lots of bubbles on top and some reduction in volume.
              Levain in this state can be refreshed by adding about 20-30% more flour, then give it another 45-90 minutes.
            </Typography>
            <Typography variant={'body1'}>
              <b>Leavening or Leavening Agent</b>
            </Typography>
            <Typography marginLeft={3} variant={'body2'}>
              General term for an ingredient that contains live yeast culture without regard to its yeast productivity/maturity.
              This can be dry or cake yeast, or a sourdough starter culture (contains a yeast strain and bacteria organisms).
            </Typography>
            <Typography variant={'body1'}>
              <b>Levain</b>
            </Typography>
            <Typography marginLeft={3} variant={'body2'}>
              This French-derived word used in lots of contexts.  In this app, it refers to a specially
              prepared mix of sourdough starter seed, flour and water with the intention of using most or all of it in
              a bread dough mixture as its leavening agent. A levain is an offshoot of, and separate from a "starter" that is being maintained
              and refreshed as a baker's preserved sourdough culture.
            </Typography>
            <Typography variant={'body1'}>
              <b>Mother</b>
            </Typography>
            <Typography marginLeft={3} variant={'body2'}>
              The "parent" or preexisting starter, from which a portion is used as "starter seed" to prepare a levain, or to prepare the next
              generation of the long-living starter.
            </Typography>
            <Typography variant={'body1'}>
              <b>Poke Test</b>
            </Typography>
            <Typography marginLeft={3} variant={'body2'}>
              A test to determine whether a dough is complete with its final proof stage and ready for baking.
              The dough is properly proofed if you can gently poke a finger into the dough and see the dent slowly spring back.
              When your finger dent doesn't recover, it could be under- or over-proofed, depending on the baker's instinct.
              Give it more time if you believe it's under proofed, but an overproofed dough cannot be fixed.
            </Typography>
            <Typography variant={'body1'}>
              <b>Preferment</b>
            </Typography>
            <Typography marginLeft={3} variant={'body2'}>
              A mixture of pre-fermented flour that is ultimately used as the leavening agent in a dough mixture.  Preferment
              is a broader term to be inclusive of either a sourdough levain, or a non-sourdough, yeast-only mix such as a "Poolish".
            </Typography>
            <Typography variant={'body1'}>
              <b>PFF</b>
            </Typography>
            <Typography marginLeft={3} variant={'body2'}>
              Pre-Fermented Flour, used in the context of flour-based percentages. A percentage in terms of PFF means the
              percentage of the flour contained in a preferment in relation to the sum total of all flour in the recipe, preferment flour included.
              For example if you have a dough recipe with 600g flour, and a levain preferment made from 100g flour, you have a total of
              700g flour in the recipe. The %PFF of the preferment is then 100 / 700 = 14.28%.  In contrast, a preferment can also be specified in
              terms of percent by total weight.  In that case, if the preferment of 100g flour also had 100g water and 20g starter, the
              preferment would weigh 220g.  So the total weight percent would be 220 / 700 (bakers percentage!) = 31.4%
            </Typography>
            <Typography variant={'body1'}>
              <b>Starter or Starter Seed</b>
            </Typography>
            <Typography marginLeft={3} variant={'body2'}>
              Generic term for the baker's sourdough culture that is replenished on a regular basis to keep it viable
              over time. The baker extracts a portion of mature starter for use as "seed" to build a levain preferment.
              In some cases, if the starter isn't overly mature and there is adequate starter volume, a portion of the starter
              can be used directly as a levain to mix into a dough.  This should only be done if there is sufficient starter remaining to
              seed its next generation.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item xs={4} sm={8} md={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
            <Typography color={'black'} variant={'h6'}>
              <PercentIcon sx={{marginBottom: -0.5}} color={'dark'}/> Bakers
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant={'body1'}>
              Use this screen for creating a formula using <b>Baker's Percentages</b>.<br/>
              Pick your target dough unit weight, then define your ratios of flour, hydration, preferment (levain or yeast), and inclusions.<br/>
              Click the <b>Apply</b> button to calculate.<br/><br/>
              This screen lets you choose whether to create a detailed preferment formula (<b>Add Preferment Formula</b> toggle),
              or simply plug your leavening into the <b>Main Dough</b> panel as a total weight percentage.<br/><br/>
              When using the <b>Add Preferment Formula</b>, the <b>Preferment</b> panel shows a projected maturation time (hh:mm) based on the starter seed% and air temperature.&nbsp;
              <i>Of course this is simply a rough guideline and depends on starter health as well as type of grain being used.</i><br/><br/>

              When using the <b>Add Preferment Formula</b> and you wish to add more levain or yeast to the Main Dough, just define them as inclusions (separate ingredients).<br/><br/>
              <i>Note that your total hydration specification includes the liquid in the Add Preferment Formula panel.<br/>
              When NOT using the Add Preferment Formula panel, total hydration includes liquid and flour from the total weight percentage,
              inferred as 100% hydration.<br/><br/></i>

              You may optionally enter your formula's Process & Notes - as much or as little as you wish.<br/><br/>
              Click the <b>Apply</b> button and a print-ready recipe is generated as you scroll down.  <i>Each time you click the <b>Apply</b> button,
              the formula in its current state is remembered if you navigate to another screen and come back.  To save it permanently, be sure to use the <b>Save</b> button.</i><br/>
              Click the <b>Clear</b> button to clear any previously entered formula; a default formula takes its place.<br/>
              Click the <b>Save</b> button to store your formula with a unique formula name.<br/><br/>
              Saved <b>%Bakers</b> formulas are accessible any time from the <b>Saved</b> menu option.<br/>
              <i>Note there are caveats to be aware of when saving your formulas.</i><br/>
              More information on how that works is found in the <b>Saved</b> panel below.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item xs={4} sm={8} md={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
            <Typography color={'black'} variant={'h6'}>
              <NumbersIcon sx={{marginBottom: -0.5}} color={'dark'}/> Weight
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant={'body1'}>
              Use this screen to enter an existing recipe using its prescribed weights for each ingredient.<br/><br/>
              If the recipe includes a detailed preferment sub-recipe, use the <b>Add Preferment Formula</b> toggle
              to enter those ingredients in the <b>Preferment</b> panel.
              Alternatively, you can simply plug in the sum total weight of the preferment in the <b>Main Dough</b> panel. <br/><br/>

              When using the <b>Add Preferment Formula</b> and the recipe calls for more levain or yeast in the Main Dough, just define them as inclusions (separate ingredients).<br/><br/>

              You may optionally enter the recipe's Process & Notes - as much or as little as you wish.<br/><br/>
              Click the <b>Apply</b> button and a print-ready recipe is generated as you scroll down.  <i>Each time you click the <b>Apply</b> button,
              the formula in its current state is remembered if you navigate to another screen and come back.  To save it permanently, be sure to use the <b>Save</b> button.</i><br/>
              Click the <b>Clear</b> button to clear any previously entered formula; a default formula takes its place.<br/>
              Click the <b>Save</b> button to keep your recipe with a unique formula name.<br/>
              Click the <b>Convert to %</b> button to transform the recipe to a Baker's Percentage formula displayed in the <b>%Bakers</b> screen. This
              option can be used with or without saving the just-entered Weight recipe.<br/><br/>
              Saved <b>#Weight</b> formulas are accessible any time from the <b>Saved</b> menu option.<br/>
              <i>Note there are caveats to be aware of when saving your formulas.</i><br/>
              More information on how that works is found in the <b>Saved</b> panel below.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item xs={4} sm={8} md={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
            <Typography color={'black'} variant={'h6'}>
              <CalculateIcon sx={{marginBottom: -0.5}} color={'dark'}/> Levain
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant={'body1'}>
              Use this screen as a simple percentage-based levain/starter calculator.<br/>
              Pick your target weight (total or flour only), hydration, and starter seed percentage.<br/>
              Click the <b>Apply</b> button to calculate.<br/><br/>
              The calculator shows a projected maturation time (hh:mm) based on the starter seed% and air temperature.&nbsp;
              <i>Of course this is simply a rough guideline and depends on starter health as well as type of grain being used.</i><br/><br/>

              Click the <b>Apply</b> button and a print-ready recipe is generated as you scroll down.  <i>Each time you click the <b>Apply</b> button,
              the formula in its current state is remembered if you navigate to another screen and come back.  To save it permanently, be sure to use the <b>Save</b> button.</i><br/>
              Click the <b>Clear</b> button to clear any previously entered formula; a default formula takes its place.<br/>
              Click the <b>Save</b> button to store your formula with a unique formula name.<br/><br/>
              Saved <b>%Levain</b> formulas are accessible any time from the <b>Saved</b> menu option.<br/>
              <i>Note there are caveats to be aware of when saving your formulas.</i><br/>
              More information on how that works is found in the <b>Saved</b> panel below.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item xs={4} sm={8} md={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
            <Typography color={'black'} variant={'h6'}>
              <SaveIcon sx={{marginBottom: -0.5}} color={'dark'}/> Saved
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography display={'block'} variant={'body1'}>
              Use this screen to access previously saved Bakers, Weight or Levain formulas.<br/>
              Saved formulas are organized by <i>type</i> (%Bakers, #Weight, Levain), so be sure to choose the desired radio button.<br/>
              Formula name and label search filters are available, but are "sticky"; they remain in effect when navigating away and back to the Saved Formulas screen.<br/>
              Clear your filters if you are not finding your formulas.<br/><br/>

              Saved formulas remain in your browser's storage despite closing your tabs and shutting down your computer.<br/>
              Browser storage provides a simple, lightweight and fast storage mechanism.<br/>
              However, your Saved formulas are subject to disappearing when certain browser Settings actions are taken, accidentally or not.<br/>
              For example, if you do a <i>Clear Cookies and Site Data</i> in your browser's Settings, or perform a tab-specific privacy clear, <b>you will erase your formulas!</b><br/><br/>

              <b>If you want to depend on the Saved Formulas feature</b>, there are two options to protect your formulas:<br/>
              1. Use the Export and Import buttons (down and up arrows) on the Saved Formulas screen to backup and restore formulas using your device's file system.<br/>
              2. Sign up for a cloud account and sign in to the account (<b>user icon</b> menu option), your
              formulas are sent to your private cloud account in addition to the browser storage.<br/><br/>
              <b>When using the sign in feature...</b><br/>
              1. Any formulas created or modified after you are signed in will be silently uploaded to your cloud account.
              <br/>
              2. Formulas created while NOT signed in are <b>NOT automatically</b> uploaded to your cloud account.
              You may use the Synchronize button (cloud icon) to upload your browser storage formulas to your cloud account.
              The Synchronize button also will download any formulas in the cloud created/modified by you from another of your signed in devices.
              <br/>
              3. Any formulas copied, renamed or deleted from the <b>Saved</b> screen are silently uploaded or deleted from your cloud account.
              <br/>
              4. Formulas that are imported from a text file using the Import button (up arrow icon) are <b>NOT automatically</b> uploaded
              to your cloud account.  Use the Synchronize button to ensure newly-imported formulas are uploaded.<br/>
              5. Signing in from multiple devices (computer, phone, tablet) allows you to share formulas across devices by using the Synchronize button on each device.
              Formulas are not automatically downloaded with signing in. <br/>
              6. A sign in to the cloud account will remain in effect for several days.  A darkened/filled User icon on the menu bar indicates you are signed in.
              An outlined User icon with a question mark indicates you are NOT signed in.
              <br/><br/>
              <b>When NOT using the sign in feature...</b><br/>
              1. In your browser, clearing cookies and site data, or doing a tab privacy clear can wipe out your Saved formulas.
              <br/>
              2. Saved formulas are visible only to the device on which you clicked Save, i.e. items saved while using your
              iPad will <b>not</b> automatically appear on your Desktop computer or your smart phone.
              <br/>
              3. To protect your saved formulas or share them among devices, there are Export and Import icon buttons
              on the <b>Saved</b> screen.
              <br/>
              4. To share a backup from your iPad to your phone, for example...<br/>
              &nbsp; a. Click the Export (down arrow) icon on your iPad.  The Export button now downloads all formula types to one file.
              <br/>
              &nbsp; b. When the exported file is rendered in a new tab on your iPad, click the browser Share option and
              choose
              an option such as Airdrop, SMS, or email to your destination device.  On Apple devices, iCloud Drive also works well
              for sharing among devices.
              <br/>
              &nbsp; c. Once your export file is captured on your destination device, open this app in a browser on your
              destination device and
              click the Import button. Choose the file just shared to this device to complete the import.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item xs={4} sm={8} md={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
            <Typography color={'black'} variant={'h6'}>
              <GuestUserIcon sx={{marginBottom: -0.5}} color={'dark'}/> (User)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography display={'block'} variant={'body1'}>
              The User menu option is a place to optionally signup for a cloud account and to signin when your login token has
              expired.<br/> Clicking the <b>Sign In</b> button will present the opportunity to sign up. <br/>
              The benefit of the cloud account is to provide a more secure backup of your saved formulas and share
              formulas across your devices. <br/><br/>

              You won't lose any functionality by NOT signing up or signing
              in.  Your formulas are saved to and accessible from your browser storage regardless of whether you sign up or sign in.<br/><br/>
              More details about syncing formulas to your cloud account are found in the <b>Saved</b> section
              above.<br/>

            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item xs={4} sm={8} md={12}>
        <Typography color={'black'} variant={'h6'}>
          <PersonIcon sx={{marginBottom: -0.5}} color={'dark'}/> About Me
        </Typography>
        <Typography display={'block'} variant={'body1'}>
          I'm a sourdough baking enthusiast since 2019 and always learning something new!
        </Typography>
        <Typography sx={{ fontSize: 14}}>dan.minnehaha@gmail.com</Typography>
      </Grid>
    </Grid>
  )
}

export { Help }