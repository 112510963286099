import {sessionGetRaw, sessionPutRaw, sessionRemove} from "./StorageUtils";

export const ratioSessionKey = 'lastRatioForm'
export const weightSessionKey = 'lastWeightForm'
export const levainSessionKey = 'lastLevainForm'

export function saveSessionForm(delayMs, sessionKey, formName, formObj) {
  setTimeout(() => doSaveSessionForm(sessionKey, formName, formObj), delayMs)
}

export function doSaveSessionForm(sessionKey, formName, formObj) {
  const currentSessionFormName = sessionGetRaw(sessionKey)
  if (currentSessionFormName && (currentSessionFormName !== formName)) {
    // clear obsolete session form
    sessionRemove(currentSessionFormName)
  }
  sessionPutRaw(formName, JSON.stringify(formObj))
  sessionPutRaw(sessionKey, formName)
}

export function getSessionForm(sessionKey) {
  const currentSessionFormName = sessionGetRaw(sessionKey)
  let sessionFormObj = undefined
  if (currentSessionFormName) {
    sessionFormObj = JSON.parse(sessionGetRaw(currentSessionFormName))
  }
  return sessionFormObj
}

export function clearSessionForm(sessionKey) {
  const currentSessionFormName = sessionGetRaw(sessionKey)
  if (!currentSessionFormName) {
    return
  }
  sessionRemove(currentSessionFormName)
  sessionRemove(sessionKey)
}

export function clearUnknownSessionForm(formName) {
  sessionRemove(formName)
  if (sessionGetRaw(ratioSessionKey) === formName) {
    sessionRemove(ratioSessionKey)
  } else if (sessionGetRaw(weightSessionKey) === formName) {
    sessionRemove(weightSessionKey)
  } else if (sessionGetRaw(levainSessionKey) === formName) {
    sessionRemove(levainSessionKey)
  }
}
