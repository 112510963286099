import * as React from 'react';
import {
  Divider,
  Grid, Typography
} from "@mui/material";
import {
  FLOUR_EXCLUSION_UNITS,
  GENERAL_DRY_EXCLUSION_UNITS,
  getUnitLabel, LIQUID_EXCLUSION_UNITS,
  unitCups,
  unitGrams,
  unitLiters,
  unitMillis,
  unitPercent
} from "../shared/Units";
import {UnitSelector} from "../shared/UnitSelector";
import {PrefermentTypeSelector} from "../shared/PrefermentTypeSelector";
import {DecimalFormField} from "../shared/DecimalFormField";
import {useState} from "react";
import {noPreferment} from "../shared/Types";
import {QuantityIngredientCollectionForm} from "./QuantityIngredientCollectionForm";

function QuantityDoughFormDetail(props) {
  const {visible, isPrefermentPanel, formData, setFormData} = props
  const [formControlsDisabled, setFormControlsDisabled] = useState(isPrefermentPanel && formData.prefermentType === noPreferment)

  const excludeUnitsYeast = [unitPercent, unitCups, unitLiters, unitMillis]
  const excludeUnitsStarter = [unitPercent, unitCups, unitLiters, unitMillis]

  function onPrefermentTypeChange(value) {
    setFormData({...formData, prefermentType: value})
    setFormControlsDisabled(value === noPreferment)
  }

  function updateLiquidData(updatedLiquidData) {
    setFormData({
      ...formData,
      liquid: updatedLiquidData
    })
  }

  function updateFlourData(updatedFlourData) {
    setFormData({
      ...formData,
      flour: updatedFlourData
    })
  }

  function updateMixinData(updatedMixinData) {
    setFormData({
      ...formData,
      mixin: updatedMixinData
    })
  }

  function updateSoakerData(soakerData) {
    setFormData({
      ...formData,
      soaker: soakerData
    })
  }

  function updateToppingsData(toppingsData) {
    setFormData({
      ...formData,
      toppings: toppingsData
    })
  }

  function onDecimalChange(fieldName, floatValue) {
    setFormData({...formData, [fieldName]: floatValue})
  }

  function onUnitChange(id, value) {
    setFormData({...formData, [id]: value})
  }

  if (!visible) {
    return null
  }

  function renderPrefermentType() {
    return (
    <Grid item container direction={'row'} marginLeft={0.25} alignItems={'flex-start'} columns={{xs: 4, sm: 8, md: 12}}>
      <Grid item xs={4} sm={6} md={8}>
        <PrefermentTypeSelector id={'quantity-pf'} prefermentType={formData.prefermentType} onValueChange={onPrefermentTypeChange} excludeTypes={[noPreferment]}/>
      </Grid>
    </Grid>
    )
  }

  function renderLeavening() {
    const leaveningLabel = `${isPrefermentPanel ? 'Starter' : 'Levain'} ${getUnitLabel(formData.starterUnit)}`
    return (
      <div>
        <Divider textAlign={'right'} sx={{marginTop: 2, width: '90%'}}><Typography
                sx={{fontSize: 12, fontWeight: 350}}
                gutterBottom>Leavening</Typography></Divider>
        <Grid item container direction={'column'} rowSpacing={1} alignItems={'flex-start'} marginBottom={1}>
          <Grid item container direction={'row'} spacing={1} columns={{xs: 4, sm: 8, md: 12}}>
            <Grid item xs={2} sm={2} md={2.5}>
              <DecimalFormField disabled={formControlsDisabled} value={formData.starterAmount} size={'small'}
                                name={'starterAmount'} label={leaveningLabel}
                                onChange={onDecimalChange}/>
            </Grid>
            <Grid item xs={0.5} sm={0.75} md={1.5}>
              <UnitSelector id={'starterUnit'} disabled={formControlsDisabled} unitValue={formData.starterUnit}
                            onValueChange={onUnitChange} excludeValues={excludeUnitsStarter}/>
            </Grid>
          </Grid>
          <Grid item container direction={'row'} spacing={1} columns={{xs: 4, sm: 8, md: 12}}>
            <Grid item xs={2} sm={2} md={2.5}>
              <DecimalFormField value={formData.yeastAmount} disabled={formControlsDisabled} size={'small'}
                                name={'yeastAmount'} label={`Yeast ${getUnitLabel(formData.yeastUnit)}`}
                                onChange={onDecimalChange}/>
            </Grid>
            <Grid item xs={0.5} sm={0.75} md={1.5}>
              <UnitSelector id={'yeastUnit'} disabled={formControlsDisabled} unitValue={formData.yeastUnit}
                            onValueChange={onUnitChange} excludeValues={excludeUnitsYeast}/>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }

  function renderMainExtras() {
    return (
      <div>
        <Divider textAlign={'right'} sx={{marginTop: 2, width: '90%'}}><Typography
                sx={{fontSize: 12, fontWeight: 350}} gutterBottom>Inclusions</Typography></Divider>
        <Grid item container direction={'column'} alignItems={'flex-start'} marginTop={1}
              marginBottom={1}>
          <QuantityIngredientCollectionForm ingredientData={formData.mixin} setIngredientData={updateMixinData}
                                  disabled={formControlsDisabled} idField={'id'} nameField={'name'} unitField={'amount'}
                                  unitTypeField={'unit'} defaultUnitType={unitGrams} collectionName={'Inclusion'}
                                  excludeUnits={GENERAL_DRY_EXCLUSION_UNITS} minCollectionSize={0}/>
        </Grid>
        <Divider textAlign={'right'} sx={{marginTop: 2, width: '90%'}}><Typography
                sx={{fontSize: 12, fontWeight: 350}} gutterBottom>Soaker</Typography></Divider>
        <Grid item container direction={'column'} alignItems={'flex-start'} marginTop={1}
              marginBottom={1}>
          <QuantityIngredientCollectionForm ingredientData={formData.soaker} setIngredientData={updateSoakerData}
                                  disabled={formControlsDisabled} idField={'id'} nameField={'name'} unitField={'amount'}
                                  unitTypeField={'unit'} defaultUnitType={unitGrams} collectionName={'Soaker'}
                                  excludeUnits={GENERAL_DRY_EXCLUSION_UNITS} minCollectionSize={0}/>
        </Grid>
        <Divider textAlign={'right'} sx={{marginTop: 2, width: '90%'}}><Typography
                sx={{fontSize: 12, fontWeight: 350}} gutterBottom>Toppings</Typography></Divider>
        <Grid item container direction={'column'} alignItems={'flex-start'} marginTop={1}
              marginBottom={1}>
          <QuantityIngredientCollectionForm ingredientData={formData.toppings} setIngredientData={updateToppingsData}
                                  disabled={formControlsDisabled} idField={'id'} nameField={'name'} unitField={'amount'}
                                  unitTypeField={'unit'} defaultUnitType={unitGrams} collectionName={'Toppings'}
                                  excludeUnits={GENERAL_DRY_EXCLUSION_UNITS} minCollectionSize={0}/>
        </Grid>
      </div>
    )
  }

  return (
          <Grid item container direction={'column'}>
            {isPrefermentPanel ? renderPrefermentType() : null}
            <div>
              <Divider textAlign={'right'} sx={{marginTop: 2, width: '90%'}}><Typography
                      sx={{fontSize: 12, fontWeight: 350}}
                      gutterBottom>Flour</Typography></Divider>
              <Grid item container direction={'column'} alignItems={'flex-start'} marginTop={1} marginBottom={1}>
                <QuantityIngredientCollectionForm ingredientData={formData.flour} setIngredientData={updateFlourData}
                                        disabled={formControlsDisabled} idField={'id'} nameField={'name'} unitField={'amount'}
                                        unitTypeField={'unit'} defaultUnitType={unitGrams} collectionName={'Flour'}
                                        excludeUnits={FLOUR_EXCLUSION_UNITS} minCollectionSize={1}/>
              </Grid>
            </div>
            <div>
              <Divider textAlign={'right'} sx={{marginTop: 2, width: '90%'}}><Typography
                      sx={{fontSize: 12, fontWeight: 350}}
                      gutterBottom>Liquids</Typography></Divider>
              <Grid item container direction={'column'} alignItems={'flex-start'} marginTop={1} marginBottom={1}>
                <QuantityIngredientCollectionForm ingredientData={formData.liquid} setIngredientData={updateLiquidData}
                                         disabled={formControlsDisabled} idField={'id'} nameField={'name'} unitField={'amount'}
                                         unitTypeField={'unit'} defaultUnitType={unitGrams} collectionName={'Liquid'}
                                         excludeUnits={LIQUID_EXCLUSION_UNITS} minCollectionSize={1}/>
              </Grid>
            </div>
            {isPrefermentPanel || !formData.includePreferment ? renderLeavening() : null}
            {!isPrefermentPanel ? renderMainExtras() : null}
          </Grid>
  )
}

export {
  QuantityDoughFormDetail
}