import {toFixedDecimal} from "./NumberUtils";

export function migrateIngredientGroupToRecipePct(group, groupName, balanceGoal) {
  const currentSum = group.map((obj) => obj['percent']).reduce((pv, cv) => toFixedDecimal(pv + cv))
  const currentBalanceDiff = toFixedDecimal(balanceGoal - currentSum)
  // console.log(`migrate: ${groupName}, balanceGoal=${balanceGoal}, currentBalanceDiff=${currentBalanceDiff}`)
  if (currentBalanceDiff === 0) {
    return;
  }
  // sort largest percent first
  const sortedGroup = group.sort((a, b) => b['percent'] - a['percent'])

  sortedGroup.reduce((pv, cv) => {
    if (pv === 0 ) {
      return pv
    }
    // console.log(`migrate: reducing ${groupName} cvId=${cv['id']} value=${cv['percent']} by ${pv}...`)
    let currVal = cv['percent'] ?? 0
    let newPv = 0
    if (currVal + pv > balanceGoal) {
      newPv = toFixedDecimal((currVal + pv) - balanceGoal)
      cv['percent'] = balanceGoal
    } else if (currVal + pv < 0) {
      newPv = toFixedDecimal(currVal + pv)
      cv['percent'] = 0
    } else {
      cv['percent'] = toFixedDecimal(currVal + pv)
    }
    // maintain the String sibling field
    cv['percentStr'] = `${cv['percent']}`
    return newPv
  }, currentBalanceDiff)
}