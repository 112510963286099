import {Grid} from "@mui/material";
import {useRef} from "react";
import IconButton from "@mui/material/IconButton";
import ReactToPrint from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";
import * as React from "react";
import {PRINT_LEVAIN_SESS_KEY, sessionGet, sessionRemove} from "../utils/StorageUtils";
import {THEME_PRIMARY_COLOR} from "../../App";
import CloseIcon from "@mui/icons-material/Close";
import {LevainRecipe} from "./LevainRecipe";

// Note: this is currently intended to open in a new tab for better printing experience
// on mobile browsers.  As noted below, currently not successful in hiding the icon buttons
// so if that gets untenable, we could not render the buttons and incorporate
// a useEffect()...
//    useEffect(() => {
//     document.getElementById('print-button').click()
//       // OR just:  window.print()
//   })

function LevainRecipePrintWrapper(props) {
  const printComponentRef = useRef(null)

  const storedItems = sessionGet(PRINT_LEVAIN_SESS_KEY)
  if (!storedItems) {
    return (<div><h3>PrintDough Recipe Session Not Found</h3></div>)
  }

  setTimeout(() => {
    // delay storage removal to accommodate ReactJs dev StrictMode which renders twice
    sessionRemove(PRINT_LEVAIN_SESS_KEY)
  }, 1000)

  // Note: these callbacks can be plugged into the ReactToPrint component like:
  //  ... onBeforeGetContent={beforePrint} onAfterPrint={afterPrint}...
  // But they don't have the desired effect in the mobile browser, so currently not bothering with it
  function beforePrint() {
     document.getElementById('print-button').style.display = 'none'
     document.getElementById('close-button').style.display = 'none'
  }

  function afterPrint() {
    document.getElementById('print-button').style.display = 'block'
    document.getElementById('close-button').style.display = 'block'
  }

  function closeRequest() {
    window.close()
  }

  return (
    <div>
      <Grid item container direction={'row'} sx={{displayPrint: 'none'}} justifyContent={'flex-end'} paddingRight={'1.5rem'} paddingTop={'1rem'}>
        <ReactToPrint trigger={() => <IconButton id='print-button' sx={{color: `${THEME_PRIMARY_COLOR}`}} aria-label="Print"><PrintIcon fontSize={'large'} /></IconButton>}
                      content={() => printComponentRef.current}/>
        <IconButton id='close-button' sx={{color: `${THEME_PRIMARY_COLOR}`}} onClick={closeRequest} color="primary" aria-label="Close">
          <CloseIcon fontSize={'large'} />
        </IconButton>
      </Grid>
      <Grid item container direction={'column'}>
        <LevainRecipe items={storedItems} variant={'elevation'} elevation={0} ref={printComponentRef}/>
      </Grid>
    </div>
  )

}


export { LevainRecipePrintWrapper }