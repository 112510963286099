// DoughForm defaults

import {unitGrams} from "../shared/Units";
import {formTypeWeightDough, liquidLevain} from "../shared/Types";

const mainFormQuantityDefaults = {
  formName: `New Formula`,
  formType: formTypeWeightDough,
  numBatches: 1,
  labels: [],
  attribution: '',
  includePreferment: false,
  prefermentData: undefined,
  process: {},
  starterAmount: 100,
  starterUnit: unitGrams,
  yeastAmount: 0,
  yeastUnit: unitGrams,
  flour: [
    {
      name: 'Bread Flour',
      id: 'b.1',
      amount: 500,
      unit: unitGrams
    }
  ],
  liquid: [
    {
      name: 'Water',
      amount: 350,
      unit: unitGrams,
      id: 'b.1'
    }
  ],
  mixin: [
    {
      name: 'Salt',
      id: 'b.1',
      amount: 10,
      unit: unitGrams
    }
  ],
  soaker: [
  ],
  toppings: [
  ],
  notes: ''
}

const prefermentQuantityDefaults = {
  prefermentType: liquidLevain,
  autolyse: false,
  starterAmount: 20,
  starterUnit: unitGrams,
  yeastAmount: 0,
  yeastUnit: unitGrams,
  addBuffer: false,
  flour: [
    {
      name: 'All Purpose Flour',
      id: 'b.1',
      amount: 100,
      unit: unitGrams
    }
  ],
  liquid: [
    {
      name: 'Water',
      amount: 70,
      unit: unitGrams,
      id: 'b.1'
    }
  ]
}

export {mainFormQuantityDefaults, prefermentQuantityDefaults}