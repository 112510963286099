import * as React from 'react';
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  Typography
} from "@mui/material";
import {forwardRef} from "react";
import {getDateTimeStr, getLocalDateTimeStr} from "../utils/DateUtils";

const LevainRecipe = forwardRef(function LevainRecipe(props, printRef) {
  const {items, variant, elevation} = props

  function renderFlour(flourItem) {
    if (flourItem.weight > 0) {
      return (
        <Typography key={flourItem.id} sx={{fontSize: 14}} color="text.secondary" gutterBottom>
          &nbsp; &nbsp; {flourItem.percent}% {flourItem.name} : {flourItem.weight}g
        </Typography>
      )
    }
    else {
      return null
    }
  }
  function renderLevainFermentTiming(levain) {
    if (levain.levainFermTimeStr.length === 0) {
      return null
    }
    return (
            <div>
              <Typography sx={{fontSize: 14, marginTop: 2, marginBottom: 0}} color="text.secondary" gutterBottom>
                Timing
              </Typography>
              <Typography sx={{fontSize: 12, marginLeft: 2}} gutterBottom>
                {`Estimated ${levain.levainFermPct}% rise time @ ${levain.levainFermTempF}°F: `}
                <Box component="span" fontWeight='800'>{levain.levainFermTimeStr}</Box>
              </Typography>
            </div>
    )
  }

  return (
        <Card elevation={elevation} variant={variant}>
          <CardContent id={'print-levain-card'} ref={printRef}>
          <Typography sx={{fontWeight: 600}} variant="h6" component={'div'}>
            {items.formName}
          </Typography>
          <Typography sx={{ fontSize: 10}}>
            Generated: {getLocalDateTimeStr()}
          </Typography>
          {items.createdAt ? (
              <Typography sx={{ fontSize: 10}}>{`Created: ${getDateTimeStr(new Date(items.createdAt))}`}</Typography>
          ) : null}
          {items.modifiedAt ? (
              <Typography sx={{ fontSize: 10}}>{`Last modified: ${getDateTimeStr(new Date(items.modifiedAt))}`}</Typography>
          ) : null}
          <Typography sx={{fontSize: 18, marginTop: 2}} component={'div'}>
            Levain Build
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {items.levain.starterWeight}g
            : {items.levain.flourWeight}g
            : {items.levain.water.weight}g
            &nbsp; (starter : flour : water)
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Starter Seed: <span style={{fontWeight: 'bold'}}>{items.levain.starterWeight}g</span> ({items.levain.starterPct}%)
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Flour Total: {items.levain.flourWeight}g
          </Typography>
          {
            items.levain.flour.map( flourItem => (
                    renderFlour(flourItem)
            ))
          }
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Water: <span style={{fontWeight: 'bold'}}>{items.levain.water.weight}g</span> ({items.levain.water.percent}%)
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Levain Total Weight: {items.levain.totalWeight}g
          </Typography>
          {renderLevainFermentTiming(items.levain)}
        </CardContent>
    </Card>
    )
})

LevainRecipe.propTypes = {
  items: PropTypes.shape({
    formName: PropTypes.string,
    createdAt: PropTypes.number,
    modifiedAt: PropTypes.number,
    levain: PropTypes.shape( {
      starterPct: PropTypes.number,
      hydrationPct: PropTypes.number,
      starterWeight: PropTypes.number,
      flourWeight: PropTypes.number,
      flour: PropTypes.arrayOf(PropTypes.shape({
        flourName: PropTypes.string,
        weight: PropTypes.number,
        percent: PropTypes.number
      })),
      water: PropTypes.shape({
        weight: PropTypes.number,
        percent: PropTypes.number
      }),
      totalWeight: PropTypes.number,
      pctOfDoughAsTotal: PropTypes.number,
      pctOfDoughAsPff: PropTypes.number,
      levainFermPct: PropTypes.number,
      levainFermTempF: PropTypes.string,
      levainFermTimeStr: PropTypes.string,
      mother: PropTypes.shape({
        starterPct: PropTypes.number,
        hydrationPct: PropTypes.number,
        flourWeight: PropTypes.number,
        starterWeight: PropTypes.number,
        starterHalfWeight: PropTypes.number,
        waterWeight: PropTypes.number,
      })
    })
  }),
  visible: PropTypes.bool
}

export { LevainRecipe }