import {SvgIcon} from "@mui/material";

export function WheatIcon() {
  return (
          <SvgIcon>
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                 width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                 preserveAspectRatio="xMidYMid meet">

              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                 fill="#000000" stroke="none">
                <path d="M3606 4259 c-92 -78 -191 -169 -219 -204 -45 -54 -120 -169 -130
-198 -1 -5 -18 -2 -36 7 -18 10 -43 15 -56 12 -21 -5 -257 -197 -343 -279 -58
-56 -122 -139 -162 -212 -19 -33 -34 -61 -35 -63 -1 -1 -16 5 -32 14 -19 11
-38 15 -56 10 -25 -6 -243 -182 -340 -275 -63 -60 -126 -141 -167 -216 -19
-33 -35 -62 -37 -64 -1 -2 -11 3 -21 12 -41 38 -73 24 -214 -94 -238 -199
-320 -293 -388 -443 -57 -125 -73 -206 -75 -374 l-2 -153 -69 -41 c-81 -49
-165 -109 -194 -140 -35 -38 -26 -77 30 -140 l50 -57 -305 -256 c-168 -141
-310 -265 -315 -275 -15 -29 -12 -55 11 -84 15 -20 29 -26 57 -26 34 0 65 23
361 271 l323 271 88 -41 c254 -118 541 -116 785 6 92 46 141 82 318 231 137
116 147 127 147 159 0 19 -4 42 -9 52 -8 15 -2 20 47 35 143 44 229 100 470
303 117 100 122 106 122 143 0 21 -4 42 -10 45 -19 12 -9 21 38 33 26 7 88 33
137 57 89 43 169 103 368 273 96 82 99 86 95 121 -3 20 -7 42 -9 49 -2 8 20
21 61 36 143 50 209 93 408 259 156 131 172 148 172 187 0 39 -31 65 -130 112
-49 24 -89 45 -89 48 0 3 22 20 50 39 85 58 191 180 242 279 68 130 91 222 91
364 1 105 -1 119 -19 138 -34 34 -151 53 -290 47 -130 -5 -214 -26 -318 -78
-32 -16 -59 -29 -61 -29 -2 0 -6 18 -10 40 -10 65 -53 190 -72 211 -11 12 -30
19 -54 19 -32 0 -55 -16 -204 -141z m186 -174 c16 -87 15 -299 -1 -385 -6 -36
-24 -104 -38 -153 -29 -98 -22 -92 -139 -101 -39 -3 -115 -17 -170 -31 -54
-14 -100 -25 -103 -25 -2 0 -1 55 2 123 10 244 93 393 326 589 68 58 96 76
102 66 4 -7 14 -44 21 -83z m677 -14 c34 -22 -19 -233 -86 -344 -70 -116 -195
-219 -322 -267 -49 -18 -71 -21 -115 -16 l-54 7 19 62 c30 97 47 204 55 329
l7 115 61 36 c65 37 126 61 191 76 53 12 226 13 244 2z m-1311 -487 c17 -78
19 -341 3 -424 -10 -50 -58 -219 -65 -227 0 0 -46 -6 -100 -13 -84 -10 -197
-34 -268 -56 -16 -5 -18 4 -18 89 0 175 67 355 176 476 70 77 235 221 249 217
6 -1 16 -29 23 -62z m852 -316 c101 -22 230 -65 230 -78 0 -18 -239 -202 -305
-235 -120 -59 -217 -78 -359 -72 -67 4 -164 14 -217 24 -96 18 -96 18 -87 43
10 28 43 165 53 223 7 43 27 54 158 88 163 41 356 44 527 7z m-1479 -221 c17
-90 17 -344 1 -417 -7 -30 -24 -94 -38 -141 l-26 -85 -101 -12 c-91 -10 -220
-38 -271 -58 -18 -7 -19 -2 -13 112 8 175 52 301 151 429 47 60 265 256 278
249 3 -2 12 -37 19 -77z m770 -292 c90 -13 266 -63 299 -85 17 -11 23 -5 -84
-95 -106 -89 -153 -121 -239 -160 -105 -48 -207 -68 -324 -63 -79 4 -297 35
-311 44 -2 2 8 49 22 106 14 57 29 121 33 143 8 40 9 40 92 68 160 54 338 69
512 42z m-1400 -232 c9 -48 13 -138 13 -233 -2 -130 -6 -170 -27 -250 -13 -52
-31 -111 -40 -131 -15 -36 -15 -36 -91 -42 -41 -4 -117 -16 -168 -28 -51 -11
-104 -23 -117 -26 l-24 -6 6 109 c6 125 24 199 71 299 50 106 103 170 233 282
65 56 121 102 124 103 3 0 12 -35 20 -77z m793 -298 c82 -14 256 -65 284 -83
8 -6 -24 -38 -105 -106 -188 -156 -320 -209 -518 -210 -98 0 -325 31 -341 47
-3 3 4 36 15 74 11 37 25 100 32 140 l12 72 84 26 c169 54 370 69 537 40z
m-529 -548 c106 -30 187 -60 186 -71 0 -13 -194 -170 -256 -208 -250 -150
-555 -138 -805 32 -90 61 -91 63 -24 106 68 44 174 93 267 124 123 40 200 50
377 46 138 -3 180 -8 255 -29z"/>
              </g>
            </svg>
          </SvgIcon>
  )
}
