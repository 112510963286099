
export const helpFermVolTemp = 'This time range is just a rough guideline for planning purposes.\n' +
        'As always, use your judgement by look and feel for signs of desired fermentation.\n\n' +
        'Variations in grain type, milling, starter health, and other factors influence the maturation time. ' +
        'For example, starters fed with fresh milled flour ferment more quickly and with different visible signs of maturity.\n\n' +
        'Projections were derived from volume gains measured with various seed ratios, temps and hydration levels. ' +
        'The test starter was maintained with a blend of all purpose and rye flours.\n\n' +
        'Levain can be ready to use at 85% to 100%.\n' +
        'The main dough can be ready from 20% to 30+%, depending on your formula.'

export const helpPrefermentType = 'Choose a Preferment Type to produce different combinations of ratios, then adjust to your needs.\n' +
        'A Preferment, with its unique flour ingredients, can produce a more complex flavor profile by taking your ' +
        'dough through multiple stages of fermentation.\n\n' +
        'Alternatively, if you have a go-to levain mix that you prefer using as a standalone ingredient, simply ' +
        'disable the "Add Preferment Formula" switch and use the Main Dough panel.\n\n'

export const helpWeightButtons = 'The Apply, Reset and Save buttons behave the same as for the %Bakers menu buttons.\n' +
        'The Apply and Save buttons render and save the formula in its original, weight-based form.\n\n' +
        'The "Convert to %" button converts a copy of the Weight formula to a scalable Bakers formula and renders it in the %Bakers screen.\n' +
        'You can save either or both the Weight and Bakers formulas as long as you use separate names.'
