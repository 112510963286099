import {MenuItem} from "@mui/material";
import * as Units from "./Units";
import * as React from "react";
import {FormTextField} from "./FormTextField";

function UnitSelector(props) {
  const {id, disabled, unitValue, onValueChange, excludeValues} = props

  const excludeValuesSafe = excludeValues === undefined ? [] : excludeValues
  const units = Units.unitMap.filter(um => ! excludeValuesSafe.includes(um.unitType))

  function onChangeLocal(evt) {
    onValueChange(id, evt.target.value)
  }

  return (
      <FormTextField select size={'small'} sx={{marginTop: 1}}
              id={`unit-select-${id}`}
              disabled={disabled}
              label={'Unit'}
              value={unitValue}
              onChange={onChangeLocal}>
        {
          units.map((um) => {
            return <MenuItem key={um.unitType} value={um.unitType}>{um.unitLabel}</MenuItem>
          })
        }
      </FormTextField>
  )
}

export {UnitSelector}